import { Component, Input } from '@angular/core';

import { Height } from '../../skeleton/placeholder-skeleton.types';
import { Size } from './placeholder-header.types';

@Component({
  selector: 'mg-placeholder-header',
  template: `
    <mg-placeholder-skeleton
      [height]="height"
      borderRadius="xlarge">
    </mg-placeholder-skeleton>
  `,
})
export class PlaceholderHeaderComponent {
  @Input() size: Size = 'small';

  get height() {
    const heightLookup: Record<Size, Height> = {
      xsmall: '16',
      small: '20',
      medium: '40',
      large: '56',
    };
    return heightLookup[this.size];
  }
}

<mg-report-table
  matSort
  [selectable]="true"
  [dataSourceService]="ds"
  [actionService]="actionService"
  (matSortChange)="ds.sort($event)">
  <mg-role-column [datasource]="ds"></mg-role-column>
  <mg-history-column
    [datasource]="ds"
    [dropdown]="historyDropDown"></mg-history-column>
  <mg-summary-column [datasource]="ds"></mg-summary-column>
  <ng-template #actionBar>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      icon="mg-checkmark"
      iconSet="minga"
      [id]="'report-report-view-click-reset'"
      (click)="resetAutomationGroupCounter()">
      {{ MESSAGES.RESET_LABEL }}
    </mg-btn>
  </ng-template>
</mg-report-table>

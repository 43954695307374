import { NgModule } from '@angular/core';

import { AnimatedWaveComponent } from './animated-wave.component';

@NgModule({
  imports: [],
  declarations: [AnimatedWaveComponent],
  exports: [AnimatedWaveComponent],
})
export class AnimatedWaveModule {}

<!-- Actions -->
<div class="actions">
  <mg-btn
    variant="text"
    size="small"
    (pressed)="clear()">
    {{ MESSAGE.BUTTON_RESET_FILTERS }}
  </mg-btn>
  <mg-btn
    variant="filled"
    size="small"
    (pressed)="submit()">
    {{ MESSAGE.BUTTON_APPLY_FILTERS }}
  </mg-btn>
</div>

<!-- Main content -->
<div
  *ngIf="data"
  class="main-content">
  <!-- Control container -->
  <div
    *ngFor="let field of fields"
    class="control-container">
    <!-- Switch between different input types -->
    <ng-container [ngSwitch]="data[field]?.type">
      <!-- Button group input -->
      <mat-button-toggle-group
        *ngSwitchCase="'toggle-group'"
        class="filter-toggle-group always-full-width"
        [name]="field"
        [multiple]="false"
        [value]="formGroup.get(field).value"
        (change)="setToggleGroupValue(field, $event)">
        <mat-button-toggle
          *ngFor="let option of $any(data[field]).options"
          [value]="option.value">
          <mg-text variant="body-sm-secondary">
            {{ option.label }}
          </mg-text>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- Text input -->
      <mg-form-text-input
        *ngSwitchCase="'text'"
        widthSize="large"
        [isDisabled]="data[field].disabled"
        [label]="data[field].label">
      </mg-form-text-input>
      <!-- Single select input -->
      <mg-form-select
        #formSelectComponent
        *ngSwitchCase="'single-select'"
        [placeholder]="data[field].label"
        [closeOnSelect]="true"
        [isClearable]="true"
        [multiple]="false"
        [id]="data[field]?.id || field"
        [searchable]="true"
        [isFullWidth]="true"
        [disabled]="data[field].disabled"
        [value]="formGroup.get(field).value"
        [options]="$any(data[field]).options"
        (selectionChanged)="setSelect(field, $any($event))">
      </mg-form-select>
      <!-- Multi select input -->
      <mg-form-select
        #formSelectComponent
        *ngSwitchCase="'multi-select'"
        [placeholder]="data[field].label"
        [closeOnSelect]="true"
        [multiple]="true"
        [id]="data[field]?.id || field"
        [searchable]="true"
        [isFullWidth]="true"
        [disabled]="data[field].disabled"
        [value]="formGroup.get(field).value"
        [options]="$any(data[field]).options"
        (selectionChanged)="setSelect(field, $any($event))">
      </mg-form-select>
      <!-- User list -->
      <mg-user-list-filter
        *ngSwitchCase="'user-list'"
        [id]="data[field]?.id || field"
        [placeholder]="data[field].label"
        [value]="formGroup.get(field).value"
        (selectChanged)="setUserList(field, $event)">
      </mg-user-list-filter>
      <!-- People search -->
      <mg-multi-person-search
        *ngSwitchCase="'people-search'"
        [id]="data[field]?.id || field"
        [defaultValue]="formGroup.get(field).value"
        (selectionChanged)="setPerson(field, $event)">
      </mg-multi-person-search>
    </ng-container>
  </div>
</div>

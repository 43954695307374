<!-- Bottom sheet presentional container -->
<mg-bottom-sheet
  [title]="title$ | async"
  [showBackBtn]="(activeField$ | async) !== null"
  [fullHeight]="(activeField$ | async) !== null"
  (onEsc)="onEsc()"
  (onClose)="onClose()"
  (onBack)="clearActiveField()">
  <!-- Main content -->
  <div class="main-content">
    <ng-container
      *ngIf="(activeField$ | async) === null; else fieldControlTemplate"
      [ngTemplateOutlet]="fieldSummaryTemplate">
    </ng-container>
  </div>
  <!-- Footer -->
  <ng-template #actions>
    <ng-container
      *ngIf="(activeField$ | async) === null; else fieldActionTemplate"
      [ngTemplateOutlet]="fieldSummaryActionTemplate">
    </ng-container>
  </ng-template>
</mg-bottom-sheet>

<!-- Field summary action template -->
<ng-template #fieldSummaryActionTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      (pressed)="clear()">
      {{ MESSAGE.BUTTON_RESET_FILTERS }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="submit()">
      {{ MESSAGE.BUTTON_APPLY_FILTERS }}
    </mg-btn>
  </div>
</ng-template>

<!-- Field action template -->
<ng-template #fieldActionTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="end center">
    <mg-btn
      variant="filled"
      (pressed)="submitFieldChanges()">
      {{ MESSAGE.BUTTON_DONE }}
    </mg-btn>
  </div>
</ng-template>

<!-- Field summary template -->
<ng-template #fieldSummaryTemplate>
  <ng-container *ngFor="let field of data.fields">
    <!-- Switch between different input types -->
    <ng-container [ngSwitch]="data.data[field].type">
      <!-- Button group input -->
      <mat-button-toggle-group
        *ngSwitchCase="'toggle-group'"
        class="filter-toggle-group"
        [name]="field"
        [multiple]="false"
        [value]="data.formGroup.get(field).value"
        (change)="setToggleGroupValue(field, $event)">
        <mat-button-toggle
          *ngFor="let option of $any(data.data[field]).options"
          [value]="option.value">
          <mg-text variant="body-sm-secondary">
            {{ option.label }}
          </mg-text>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- Field button -->
      <button
        *ngSwitchDefault
        class="field-button"
        matRipple
        matRippleColor="rgba(0, 0, 0, 0.02)"
        (click)="setActiveFilter(field)">
        <!-- Label & value -->
        <div class="field-button-content">
          <mg-text variant="body-md-secondary">
            {{ data.data[field].label }}
          </mg-text>
          <!-- Tag values -->
          <ng-container *ngIf="(draftFormData$ | async)[field] as values">
            <div class="field-button-content-values">
              <mg-tag *ngFor="let value of values">{{ value?.label }}</mg-tag>
            </div>
          </ng-container>
        </div>
        <!-- Icon -->
        <mg-icon
          class="field-button-icon"
          iconName="mg-chevron-right">
        </mg-icon>
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Field control template -->
<ng-template #fieldControlTemplate>
  <ng-container *ngIf="activeField$ | async as activeField">
    <ng-container [ngSwitch]="data.data[activeField]?.type">
      <!-- Person search -->
      <ng-container *ngSwitchCase="'people-search'">
        <mg-filters-form-person-search
          [label]="data.data[activeField]?.label"
          [multiple]="$any(data.data[activeField])?.multiple"
          [done]="submitFieldChangesEvent$"
          [id]="data.data[activeField]?.id"
          [selected]="(draftFormData$ | async)[activeField] || []"
          (selectionChange)="setSelectValue(activeField, $event)">
        </mg-filters-form-person-search>
      </ng-container>

      <!-- Default case -->
      <mg-select-element
        *ngSwitchDefault
        [options]="$any(data.data[activeField])?.options || []"
        [enableSearch]="true"
        [multiple]="true"
        [done]="submitFieldChangesEvent$"
        [selected]="(draftFormData$ | async)[activeField] || []"
        (selectionChange)="setSelectValue(activeField, $event)">
      </mg-select-element>
    </ng-container>
  </ng-container>
</ng-template>

<mg-card
  *ngIf="(loading$ | async) === false; else loadingTemplate"
  paddingX="4"
  paddingY="4">
  <div class="card-container">
    <div class="card-container-header">
      <mg-text variant="header-md-secondary">
        My Stickers ({{ (stickers$ | async)?.length }})
      </mg-text>
      <mg-btn
        *ngIf="(stickers$ | async)?.length > 0"
        variant="outlined"
        size="small"
        (pressed)="openViewModal()">
        {{ MESSAGES.SEE_ALL_LABEL }}
      </mg-btn>
    </div>
    <div>
      <ng-container
        *ngIf="(stickers$ | async)?.length > 0; else emptyStateTemplate">
        <div class="card-container-body">
          <ng-container *ngFor="let item of stickers$ | async">
            <div class="image-container">
              <mg-image
                *ngIf="getStickerPath(item)"
                class="image"
                background-size="contain"
                [srcs]="
                  getStickerPath(item)
                    | mgImageUrls
                      : ['blurloading1', ['bannerlibpreview', 'cardbanner']]
                ">
              </mg-image>
              <mg-text
                textAlign="center"
                [variant]="
                  (mediaService.isMobileView$ | async) ? 'body-xs' : 'body-sm'
                "
                [numberOfLines]="2">
                {{ item.name }}
              </mg-text>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</mg-card>

<!-- Loading template -->
<ng-template #loadingTemplate>
  <mg-placeholder-card>
    <div class="tw-grid tw-grid-cols-2 tw-items-center tw-mb-8">
      <mg-placeholder-header class="tw-w-2/3"></mg-placeholder-header>
    </div>
    <div class="tw-grid tw-grid-cols-4 tw-gap-6">
      <div
        class="tw-flex tw-flex-column tw-items-center"
        *ngFor="let index of [].constructor(4); let i = index">
        <mg-placeholder-avatar class="tw-mb-2" size="medium"></mg-placeholder-avatar>
        <mg-placeholder-text class="tw-w-3/4"></mg-placeholder-text>
      </div>
    </div>
  </mg-placeholder-card>
</ng-template>

<!-- Empty state template -->
<ng-template #emptyStateTemplate>
  <mg-empty-state
    [title]="MESSAGES.EMPTY_STATE_TITLE"
    [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE">
  </mg-empty-state>
</ng-template>

import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

export const ALIAS_BREAKPOINT_MAP = {
  xs: 'xsmall',
  sm: 'small',
  md: 'medium',
  ml: 'medium-large',
  lg: 'large',
  xl: 'xlarge',
} as const;

export type MediaBreakpoints =
  typeof ALIAS_BREAKPOINT_MAP[keyof typeof ALIAS_BREAKPOINT_MAP];

@Injectable({ providedIn: 'root' })
export class MediaService {
  public readonly breakpoint$: Observable<MediaBreakpoints> =
    this._mediaObserver.asObservable().pipe(
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
      map(alias => ALIAS_BREAKPOINT_MAP[alias] || alias),
    );

  private _screenWidthSubject = new BehaviorSubject<number>(window.innerWidth);
  public readonly screenWidth$ = this._screenWidthSubject.asObservable();

  private _isMobileView: boolean;

  public readonly isMobileView$ = this.breakpoint$.pipe(
    map(breakpoint => ['xsmall', 'small'].includes(breakpoint)),
    tap(isMobile => (this._isMobileView = isMobile)),
  );

  public readonly isTabletOrMobile$ = this.breakpoint$.pipe(
    map(breakpoint =>
      ['xsmall', 'small', 'medium', 'medium-large'].includes(breakpoint),
    ),
  );

  public readonly isDesktopView$ = this.breakpoint$.pipe(
    map(breakpoint => ['large', 'xlarge'].includes(breakpoint)),
  );

  // Computed getters

  public get isMobileView() {
    return this._isMobileView;
  }

  /** Service Constructor */
  constructor(private _mediaObserver: MediaObserver) {
    window.addEventListener('resize', () => {
      this._screenWidthSubject.next(window.innerWidth);
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserStorage } from 'src/app/services/UserStorage';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

import {
  FORM_FIELDS,
  SavedPreferences,
} from '../constants/tt-my-class.constants';
import { ActionItem } from '../types/tt-my-class.types';

@Injectable()
export class MyClassPreferencesService implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _mingaHash: string = '';

  constructor(
    private _localStorage: UserStorage,
    private _mingaStore: MingaStoreFacadeService,
  ) {
    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(minga => {
        if (minga) {
          this._mingaHash = minga.hash || '';
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async get(): Promise<SavedPreferences> {
    const preferences = await this._localStorage.getItem<SavedPreferences>(
      this._getLocalStorageKey(),
    );

    return preferences;
  }

  public async save(values): Promise<void> {
    if (!values) return;

    const selected = values[FORM_FIELDS.SELECTED_ACTION]?.value
      ? {
          value: values[FORM_FIELDS.SELECTED_ACTION].value,
          assignmentType: values[FORM_FIELDS.SELECTED_ACTION].assignmentType,
        }
      : null;

    const preferences: SavedPreferences = {
      [FORM_FIELDS.LIST_FILTER]: values[FORM_FIELDS.LIST_FILTER] || null,
      [FORM_FIELDS.CATEGORY]: values[FORM_FIELDS.CATEGORY] || null,
      [FORM_FIELDS.SELECTED_ACTION]: selected,
      [FORM_FIELDS.HIDE_PICTURE_FILTER]:
        values[FORM_FIELDS.HIDE_PICTURE_FILTER] || false,
      [FORM_FIELDS.SORT_FIRST_NAME_FILTER]:
        values[FORM_FIELDS.SORT_FIRST_NAME_FILTER] || false,
    };

    await this._localStorage.setItem(this._getLocalStorageKey(), preferences);
  }

  public apply(opts: {
    actions: ActionItem[];
    preferences: SavedPreferences;
    form: FormGroup;
  }) {
    const { actions, preferences, form } = opts;
    if (!preferences) return;

    if (preferences[FORM_FIELDS.LIST_FILTER]) {
      form
        .get(FORM_FIELDS.LIST_FILTER)
        .setValue(preferences[FORM_FIELDS.LIST_FILTER]);
    }

    if (preferences[FORM_FIELDS.CATEGORY]) {
      form
        .get(FORM_FIELDS.CATEGORY)
        .setValue(preferences[FORM_FIELDS.CATEGORY]);
    }

    if (preferences[FORM_FIELDS.SORT_FIRST_NAME_FILTER] !== undefined) {
      form
        .get(FORM_FIELDS.SORT_FIRST_NAME_FILTER)
        .setValue(preferences[FORM_FIELDS.SORT_FIRST_NAME_FILTER]);
    }
    if (preferences[FORM_FIELDS.HIDE_PICTURE_FILTER] !== undefined) {
      form
        .get(FORM_FIELDS.HIDE_PICTURE_FILTER)
        .setValue(preferences[FORM_FIELDS.HIDE_PICTURE_FILTER]);
    }

    const selectedAction = preferences[FORM_FIELDS.SELECTED_ACTION];
    if (selectedAction && actions.length > 0) {
      const selected = actions.find(
        a =>
          a.value === selectedAction.value &&
          a.assignmentType === selectedAction.assignmentType,
      );

      if (selected) {
        form.get(FORM_FIELDS.SELECTED_ACTION).setValue(selected);
      }
    }

    form.updateValueAndValidity();
  }

  private _getLocalStorageKey(): string {
    return `${this._mingaHash}:myclass:preferences`;
  }
}

<div [ngClass]="{ 'inline-version': isInlineVersion }">
  <div
    class="header"
    [class.profile-view]="isProfileView">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayout="start center"
      fxLayoutGap="12px">
      <div class="select">
        <mg-form-select
          labelBackground="white"
          [placeholder]="MESSAGES.PERIOD_FILTER_LABEL"
          [value]="leaderboardTimeFilter"
          [closeOnSelect]="true"
          [options]="periodSelectOptions"
          (selectionChanged)="changeTimeFilter($event)">
        </mg-form-select>
      </div>
      <div
        class="select"
        *ngIf="(typeSelectOptions$ | async)?.length > 0">
        <mg-form-select
          labelBackground="white"
          [placeholder]="MESSAGES.TYPE_FILTER_LABEL"
          [value]="leaderboardTab"
          [closeOnSelect]="true"
          [options]="typeSelectOptions$ | async"
          (selectionChanged)="changeTypeFilter($event)">
        </mg-form-select>
      </div>
    </div>

    <ng-template #schoolTotalPointsTmpl>
      <div
        class="school-points"
        *ngIf="totalPoints">
        <span class="points mg-headline2">{{
          totalPoints | number: '1.0':'en-US'
        }}</span>
        <span class="points-text">{{ MESSAGES.SCHOOLS_POINT_TOTAL }}</span>
      </div>
    </ng-template>

    <div
      *ngIf="canDownloadExport"
      fxLayoutAlign="flex-end center"
      class="leaderboard-type">
      <mg-btn
        *ngIf="('POINTS_MANAGE' | hasPermission) && !isProfileView"
        variant="text"
        size="medium"
        iconSet="minga"
        icon="mg-export"
        (pressed)="launchExport()">
        {{ MESSAGES.EXPORT }}
      </mg-btn>
    </div>

    <ng-template #loadingTmpl>
      <mg-spinner></mg-spinner>
    </ng-template>

    <ng-template #emptyStateUsersTmpl>
      <mg-empty-state
        *ngIf="usersInitialized; else loadingTmpl"
        [title]="
          'emptyState.pointsUserLeaderboard' | translate
        "></mg-empty-state>
    </ng-template>
    <ng-template #emptyStateTeamsTmpl>
      <mg-empty-state
        *ngIf="teamsInitialized; else loadingTmpl"
        [title]="
          'emptyState.pointsTeamLeaderboard' | translate
        "></mg-empty-state>
    </ng-template>

    <div
      class="rows-container"
      *ngIf="
        (canViewTeamPoints$ | async) || (canViewIndividualPoints$ | async);
        else enableSettings
      ">
      <ng-container *ngIf="leaderboardTab == 'USERS'">
        <ng-container *ngIf="userItems.length; else emptyStateUsersTmpl">
          <div
            class="row"
            *ngFor="let userItem of userItems; let index = index"
            [class.self-row]="
              userItem.person.personHash ==
              (auth.authInfo$ | async)?.person.personHash
            ">
            <div class="left-col">
              <mg-avatar
                [src]="userItem.person.profileImageUrl"
                [color]="userItem.person.badgeIconColor"
                [personHash]="userItem.person.personHash"></mg-avatar>
              <div class="person-detail-column">
                <mg-person-minimal
                  [person]="userItem.person"></mg-person-minimal>
                <span class="sub-detail">{{
                  userItem.teamNamesList?.join(', ')
                }}</span>
              </div>
            </div>
            <div class="right-col">
              <mg-icon class="star-icon">star-o</mg-icon>
              <mg-text
                class="pts-label"
                variant="header-sm"
                >{{ userItem.points }}</mg-text
              >
              <mg-icon
                class="leaderboard-icon"
                [ngClass]="{ hidden: index > 2 }"
                >leaderboard</mg-icon
              >
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="leaderboardTab == 'TEAMS'">
        <ng-container *ngIf="teamItems.length; else emptyStateTeamsTmpl">
          <div
            class="row team"
            *ngFor="
              let teamItem of teamItems;
              let index = index;
              trackBy: trackById
            "
            [style.background-color]="teamItem.color || getRandomColor(index)">
            <div class="left-col">
              <mg-avatar
                *ngIf="teamItem.image"
                [src]="teamItem.image | mgImageUrls: ['bannerlibpreview']">
              </mg-avatar>
              <div
                *ngIf="!teamItem.image"
                class="default-icon-wrapper"
                fxLayout="row"
                fxLayoutAlign="center center">
                <mg-icon
                  [style.color]="teamItem.color || getRandomColor(index)">
                  leaderboard
                </mg-icon>
              </div>
              <mg-text
                variant="header-sm"
                color="white"
                >{{ teamItem.name }}</mg-text
              >
            </div>
            <div class="right-col">
              <mg-icon class="star-icon">star-o</mg-icon>
              <mg-text
                variant="header-sm"
                color="white"
                >{{ teamItem.points }}</mg-text
              >
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #enableSettings>
      <mg-empty-state
        [title]="'Settings disabled'"
        [subtitle]="MESSAGES.SETTING_DISABLED">
      </mg-empty-state>
    </ng-template>
  </div>
</div>

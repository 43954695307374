<section
  class="card"
  [ngClass]="{ open: panelOpenState }">
  <mat-expansion-panel
    hideToggle
    [disabled]="alwaysOpen"
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
    <mat-expansion-panel-header
      matRipple
      [matRippleDisabled]="alwaysOpen"
      [matRippleColor]="'rgba(0, 0, 0, 0.02)'">
      <mg-image
        *ngIf="imagePath"
        class="image"
        [srcs]="
          imagePath
            | mgImageUrls
              : ['blurloading1', ['studio', 'bannerlibpreview', 'cardbanner']]
        ">
      </mg-image>
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="4px">
        <mg-text
          class="header-title"
          variant="header-md-secondary"
          [numberOfLines]="2">
          {{ title }}
        </mg-text>
        <mg-text
          *ngIf="subtitle"
          class="header-subtitle"
          variant="body-sm"
          [color]="'surface-alt'"
          [numberOfLines]="1">
          {{ subtitle }}
        </mg-text>
      </div>
      <mg-icon *ngIf="!alwaysOpen"> mg-drop-down-arrow </mg-icon>
    </mat-expansion-panel-header>
    <div class="card-body">
      <ng-content></ng-content>
      <ng-container *ngIf="actions">
        <div class="card-actions">
          <ng-container *ngTemplateOutlet="actions"></ng-container>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
</section>

import {
  BEHAVIOR_CONFIG,
  CHECKIN_CONFIG,
  HALLPASS_CONFIG,
} from '@modules/minga-manager/components/mm-subscription';

import { MetricData, MetricType } from './tools-metrics-card.types';

export enum ToolsMetricsCardMessage {
  LEARN_MORE_LABEL = 'Learn more',
}

enum MetricCardType {
  ACTIVE_USERS = 'active-users',
  CHECK_IN = 'check-in',
  HALL_PASS = 'hall-pass',
  POINTS = 'points',
  BEHAVIOR = 'behavior',
  REDEEM_POINTS = 'redeem-points',
  OVERDUE_CONSEQUENCE = 'overdue-consequence',
  ACTIVE_CONSEQUENCE = 'active-consequence',
  UNREGISTERED_PERIODS = 'unregistered-periods',
}

/** @todo update links to actual minga pages */
export const METRIC_CARD_CONFIG: Record<MetricType, MetricData> = {
  // ADMIN METRICS
  [MetricCardType.ACTIVE_USERS]: {
    color: 'primary',
    iconName: 'mg-people-menu',
    labelName: 'Active Users',
  },
  [MetricCardType.CHECK_IN]: {
    color: 'check-in',
    iconName: 'mg-checkin-menu',
    labelName: 'Check ins',
    moduleConfig: CHECKIN_CONFIG,
  },
  [MetricCardType.HALL_PASS]: {
    color: 'hall-pass',
    iconName: 'mg-hallpass-menu',
    labelName: 'Hall Passes',
    moduleConfig: HALLPASS_CONFIG,
  },
  [MetricCardType.POINTS]: {
    color: 'community',
    iconName: 'mg-points-menu',
    labelName: 'Points',
    moduleConfig: BEHAVIOR_CONFIG,
  },
  [MetricCardType.BEHAVIOR]: {
    color: 'flextime',
    iconName: 'mg-behavior-praise',
    labelName: 'Behaviors',
    moduleConfig: BEHAVIOR_CONFIG,
  },

  // STUDENT METRICS
  [MetricCardType.REDEEM_POINTS]: {
    color: 'community',
    iconName: 'mg-points-menu',
    labelName: 'Redeemable points',
  },
  [MetricCardType.OVERDUE_CONSEQUENCE]: {
    color: 'behavior-consequence',
    iconName: 'mg-behavior-menu',
    labelName: 'Overdue consequences',
  },
  [MetricCardType.ACTIVE_CONSEQUENCE]: {
    color: 'behavior',
    iconName: 'mg-behavior-menu',
    labelName: 'Active consequences',
  },
  [MetricCardType.UNREGISTERED_PERIODS]: {
    color: 'flextime',
    iconName: 'mg-flextime-menu',
    labelName: 'Unregistered periods',
  },
};

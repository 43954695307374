import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import * as day from 'dayjs';

import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import {
  DateMessages,
  DatePickerDefaultPresetKey,
} from '../../form-date-range.constants';
import { initializeRange } from '../../form-date-range.utils';
import {
  FormRangeAbstract,
  RangeResponseData,
} from '../../services/form-range-abstract';
import { FormRangeService } from '../../services/form-range.service';

@Component({
  selector: 'mg-form-date-range-desktop',
  templateUrl: './form-date-range-desktop.component.html',
  styleUrls: ['./form-date-range-desktop.component.scss'],
})
export class FormDateRangeDesktopComponent extends FormRangeAbstract {
  @Input() public minDate: day.Dayjs;
  @Input() public maxDate: day.Dayjs;
  @Input() public range = initializeRange();
  @Input() public hidePresets = false;
  @Input() public customPresets?: DatePickerDefaultPresetKey[];
  @Output() public onClose = new EventEmitter<RangeResponseData>();
  public MESSAGES = DateMessages;

  constructor(
    public cdr: ChangeDetectorRef,
    public rangeService: FormRangeService,
    public presetService: DatePresetsService,
  ) {
    super(cdr, rangeService, presetService);
  }

  public onInit() {
    this.setData(this.range, this.minDate, this.maxDate);
  }

  public onClear(data) {
    const response: RangeResponseData = {
      type: 'cancel',
      data: {},
    };
    this.onClose.emit(response);
  }

  public onDone(data) {
    const response: RangeResponseData = {
      data: {
        range: this.range,
      },
      type: 'submit',
    };
    this.onClose.emit(response);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { StudentSection } from 'minga/domain/studentSchedule';

import { MediaService } from '@shared/services/media';
import { StudentScheduleService } from '@shared/services/student-schedule/student-schedule.service';

import { FormSelectOption } from '../form';
import { PaginatorComponent } from '../paginator';
import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import {
  STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS,
  STUDENT_SCHEDULE_FORM_GROUP,
  StudentScheduleFormFields,
  StudentScheduleMessage,
} from './student-schedule.constants';

@Component({
  selector: 'mg-student-schedule',
  templateUrl: './student-schedule.component.html',
  styleUrls: ['./student-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentScheduleComponent implements OnInit, AfterViewInit {
  /** Children */
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;

  /** Inputs */
  @Input() hash: string;

  /** Constants */
  public readonly MESSAGES = StudentScheduleMessage;
  public readonly STUDENT_SCHEDULE_FORM_FIELDS = StudentScheduleFormFields;

  /** Form */
  public readonly form = this._fb.group(STUDENT_SCHEDULE_FORM_GROUP);

  /** Observables */
  private _destroyedSubject = new ReplaySubject<void>(1);

  private _loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this._loadingSubject.asObservable();

  private _periodsFilterSubject = new BehaviorSubject<
    FormSelectOption<string>[]
  >([]);
  public periodsFilter$ = this._periodsFilterSubject.asObservable();

  private _activeSortColumnSubject = new BehaviorSubject<string>(null);
  public readonly activeSortColumn$ =
    this._activeSortColumnSubject.asObservable();

  /** Table */
  public readonly dataSource = new MatTableDataSource<StudentSection>([]);

  public readonly displayColumns$ = this.media.breakpoint$.pipe(
    takeUntil(this._destroyedSubject),
    map(bp => {
      if (['xsmall', 'small'].includes(bp)) return ['mobile'];
      else return STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS;
    }),
  );

  private readonly _searchValue = this.form
    .get(StudentScheduleFormFields.SEARCH)
    .valueChanges.pipe(
      takeUntil(this._destroyedSubject),
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
    );

  constructor(
    public media: MediaService,
    private _fb: FormBuilder,
    private _studentSchedule: StudentScheduleService,
    private _snackBarService: SystemAlertSnackBarService,
  ) {}

  ngOnInit(): void {
    this._getSchedule();

    this._searchValue
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(search => {
        this.dataSource.filter = JSON.stringify({
          searchFilter: search.toLowerCase().trim(),
        });
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator.matPaginator;
    this.dataSource.filterPredicate = this._tableFilter;
  }

  public selectActiveColumn(columnKey: string) {
    this._activeSortColumnSubject.next(columnKey);
  }

  private async _getSchedule() {
    try {
      this._loadingSubject.next(true);

      // backend doesn't currently support date range so lets just send today
      const startDate = day();
      const endDate = day();
      const schedule = await this._studentSchedule.getStudentSchedule(
        this.hash,
        startDate,
        endDate,
      );

      this._setSchedule(schedule);
    } catch (error) {
      this._snackBarService.error('There was a problem fetching schedule');
    } finally {
      this._loadingSubject.next(false);
    }
  }

  private _setSchedule(schedule: StudentSection[]) {
    this.dataSource.data = schedule;

    let periods = schedule
      .filter(s => s.period)
      .map(s => s.period)
      .sort((a, b) => {
        return a.localeCompare(b);
      });

    periods = [...new Set(periods)];

    this._periodsFilterSubject.next(
      periods.map(p => ({
        value: p,
        label: p,
      })),
    );
  }

  private _tableFilter(item: StudentSection, f) {
    const { searchFilter } = JSON.parse(f) as {
      searchFilter: string;
    };

    const { name = '', period = '', teachers = [], location = '' } = item;

    const searchMatch =
      name.toLowerCase().includes(searchFilter) ||
      period.toLowerCase().includes(searchFilter) ||
      location.toLowerCase().includes(searchFilter) ||
      teachers.join(', ').toLowerCase().includes(searchFilter);

    return searchMatch;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgImageModule } from 'src/app/components/MgImage';
import { MgAvatarModule } from 'src/app/elements/MgAvatar';
import { MgIconModule } from 'src/app/icon';
import { MgPipesModule } from 'src/app/pipes';
import { NotificationsModule } from 'src/app/routes/home/Notifications';
import { MgSpinnerModule } from 'src/app/spinner';

import { HpmDashboardPipesModule } from '@modules/hallpass-manager/components/hpm-dashboard/pipes/hpm-dashboard-pipes.module';

import { CarouselModule } from '@shared/components/carousel/carousel.module';
import { PlaceholderModule } from '@shared/components/placeholder/placeholder.module';
import { TextModule } from '@shared/components/text/text.module';
import { CommonUIModules } from '@shared/modules';

import { ToolsNotificationsComponent } from '../components/tools-notifications/tools-notifications.component';
import { ToolsActionsHeaderComponent } from './components/tools-actions-header/tools-actions-header.component';
import { ToolsGroupsViewModalComponent } from './components/tools-groups-view-modal/tools-groups-view-modal.component';
import { ToolsGroupsComponent } from './components/tools-groups/tools-groups.component';
import { ToolsLinkComponent } from './components/tools-link/tools-link.component';
import { ToolsMetricsCardComponent } from './components/tools-metrics-card/tools-metrics-card.component';
import { ToolsNavItemComponent } from './components/tools-nav-item/tools-nav-item.component';
import { ToolsNavComponent } from './components/tools-nav/tools-nav.component';
import { ToolsStatsPlaceholderComponent } from './components/tools-stats-placeholder/tools-stats-placeholder.component';
import { ToolsStickersViewModalComponent } from './components/tools-stickers-view-modal/tools-stickers-view-modal.component';
import { ToolsStickersComponent } from './components/tools-stickers/tools-stickers.component';
import { ToolsTwoColLayoutComponent } from './components/tools-two-col-layout/tools-two-col-layout.component';
import { FilterHpOptionsPipe, FilterPbisOptionsPipe } from './pipes';

@NgModule({
  declarations: [
    ToolsLinkComponent,
    ToolsNavItemComponent,
    ToolsNavComponent,
    ToolsGroupsComponent,
    ToolsGroupsViewModalComponent,
    ToolsNotificationsComponent,
    FilterPbisOptionsPipe,
    FilterHpOptionsPipe,
    ToolsTwoColLayoutComponent,
    ToolsStickersComponent,
    ToolsStickersViewModalComponent,
    ToolsActionsHeaderComponent,
    ToolsMetricsCardComponent,
    ToolsStatsPlaceholderComponent,
  ],
  imports: [
    MgIconModule,
    FlexLayoutModule,
    CommonModule,
    CommonUIModules,
    TextModule,
    NotificationsModule,
    CarouselModule,
    RouterModule,
    MgEmptyStateModule,
    MgSpinnerModule,
    MgImageModule,
    MgPipesModule,
    PlaceholderModule,
    MgAvatarModule,
    MatTooltipModule,
    CommonUIModules,
    HpmDashboardPipesModule,
  ],
  exports: [
    ToolsLinkComponent,
    ToolsNavItemComponent,
    ToolsNavComponent,
    ToolsGroupsComponent,
    ToolsNotificationsComponent,
    FilterPbisOptionsPipe,
    FilterHpOptionsPipe,
    ToolsTwoColLayoutComponent,
    ToolsStickersComponent,
    ToolsActionsHeaderComponent,
    ToolsMetricsCardComponent,
    ToolsStatsPlaceholderComponent,
  ],
})
export class SharedToolsModule {}

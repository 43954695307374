import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgPipesModule } from 'src/app/pipes';

import { CommonUIModules } from '@shared/modules';

import { StudentScheduleWidgetComponent } from './student-schedule-widget.component';

@NgModule({
  declarations: [StudentScheduleWidgetComponent],
  exports: [StudentScheduleWidgetComponent],
  imports: [
    // Common dependencies
    CommonModule,
    CommonUIModules,
    MgEmptyStateModule,
    MgPipesModule,

    // Minga depdenecies

    // External dependencies
  ],
})
export class ScheduleWidgetModule {}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BottomSheetService } from '@shared/components/bottom-sheet';

import { FormSheetSelectComponent } from '../form-sheet-select.component';
import { FormSheetSelectData, FormSheetSelectResponseData } from '../types';

@Injectable({
  providedIn: 'root',
})
export class FormSheetSelectService {
  /** Service constructor */
  constructor(
    private _bottomSheet: BottomSheetService<
      FormSheetSelectData,
      FormSheetSelectResponseData
    >,
  ) {}

  public open(
    data: FormSheetSelectData,
  ): Observable<FormSheetSelectResponseData> {
    const bottomSheetRef = this._bottomSheet.open(FormSheetSelectComponent, {
      data,
    });
    return bottomSheetRef.afterDismissed();
  }
}

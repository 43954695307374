import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { FirebaseMessaging } from 'minga/app/src/app/firebase/messaging';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { BadgeNotificationHandlerService } from 'minga/app/src/app/notifications/badge';
import { NotificationClickHandler } from 'minga/app/src/app/notifications/clickHandler';
import { PointsNotificationHandlerService } from 'minga/app/src/app/notifications/points';
import { NotificationBadgeSyncService } from 'minga/app/src/app/services/NotificationBadgeSync';
import { NotificationActions } from 'minga/app/src/app/store/Notifications/actions';
import { NotificationStoreModel } from 'minga/app/src/app/store/Notifications/model';
import { NotificationManager } from 'minga/proto/gateway/notification_ng_grpc_pb';
import { Notification } from 'minga/proto/gateway/notification_pb';

import { NotificationDataSource } from './NotificationDataSource';

@Component({
  selector: 'mg-notifications',
  templateUrl: './Notifications.component.html',
  styleUrls: ['./Notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationDataSource],
})
export class NotificationsComponent implements OnInit {
  constructor(
    public ds: NotificationDataSource,
    public notificationManager: NotificationManager,
    private _notificationBadgeSync: NotificationBadgeSyncService,
    private _store: Store<any>,
    private _firebaseMessaging: FirebaseMessaging,
    private _notificationClickHandler: NotificationClickHandler,
    private _analyticsService: AnalyticsService,
    private _pointsNotificationHandler: PointsNotificationHandlerService,
    private _badgeNotificationHandler: BadgeNotificationHandlerService,
  ) {}

  ngOnInit() {
    this._firebaseMessaging.requestPermission();
    this._notificationBadgeSync.clearBadgeCount();
    this._store.dispatch(new NotificationActions.ViewedNotificationsAction());
  }

  notificationItemClick(notification: Notification) {
    const item = notification.toObject();

    if (!notification.getRead()) {
      // Fire, forget!
      notification.setRead(true);
      notification.setViewed(true);
      const storeModel: NotificationStoreModel = {
        id: item.notificationId,
      };
      const notificationType = item.notificationType;

      if (item.actionGalleryPhoto) {
        const groupHash = item.actionGroupContent?.groupHash;
        storeModel.groupHash = groupHash;
      } else if (
        this._pointsNotificationHandler.isPointsNotification(notificationType)
      ) {
        this._pointsNotificationHandler.handleDisplayPointNotification(item);
      } else if (
        this._badgeNotificationHandler.isBadgeNotification(notificationType)
      ) {
        this._badgeNotificationHandler.handleDisplayBadgeNotification(item);
      }

      this._store.dispatch(
        new NotificationActions.MarkNotificationAsReadAction(storeModel),
      );
    }

    this._notificationClickHandler.clickNotification(item);
    this._analyticsService.sendNotificationOpened(notification.getActionCase());
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { SelectionAssignerService } from '@modules/selection-assigner/services';
import { combineDateAndTime } from '@modules/selection-assigner/utils';

import {
  SaHallPassFormFields,
  SaHallPassSelectorType,
  hpFormGroup,
} from '../../constants/sa-hallpass.constants';
import {
  HallpassAssignmentLabels,
  MAX_NOTE_LENGTH,
  allowedAssignedByRoles,
  DEFAULT_STAFF_PASS_DURATION,
} from '../../constants/sa-hallpass.constants';
import { fadeInOut } from './sa-hallpass.animations';

@Component({
  selector: 'mg-sa-hallpass',
  templateUrl: './sa-hallpass.component.html',
  styleUrls: ['./sa-hallpass.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class SaHallpassComponent implements OnDestroy {
  @ViewChild('formContent', { static: false })
  private _formContentTemplate: ElementRef;

  /** Constants */
  public readonly ASSIGNMENT_LABELS = HallpassAssignmentLabels;
  public readonly allowedAssignedByRoles = allowedAssignedByRoles;
  public readonly MAX_NOTE_LENGTH = MAX_NOTE_LENGTH;
  public readonly HP_FORM_FIELDS = SaHallPassFormFields;
  public DEFAULT_SELF_GRANT_PASS_DURATION: number;
  public today = day();

  /** Form Controls */
  public readonly hpFG = this._fb.group(hpFormGroup);
  public readonly hpTypeFC = this._saService.typeFormControl;

  /** Observables and Behavior Subjects */
  public readonly canAddNote$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.PASS_ALLOW_NOTE,
  );
  public readonly mustAssignStaff$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.PASS_ASSIGN_STAFF,
  );
  private _defaultHPTypeDuration = new BehaviorSubject<number>(undefined);
  public hpTypeDuration$ = this._defaultHPTypeDuration.asObservable();
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _assignerName = '';

  /** Other */
  public scheduledDateState: Date;

  /** Component Constructor */
  constructor(
    private _saService: SelectionAssignerService,
    private _fb: FormBuilder,
    private _settingsService: MingaSettingsService,
    private _permissions: PermissionsService,
  ) {
    if (!this._permissions.hasPermission(MingaPermission.HALL_PASS_MANAGE)) {
      this.hpFG.get(SaHallPassFormFields.SELF_GRANT).setValue(true);
    }
    this._initDefaultHPDurationHandler();

    this.mustAssignStaff$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(mustAssign => {
        if (
          !mustAssign ||
          !this.hpFG.get(SaHallPassFormFields.SELF_GRANT).value
        ) {
          return;
        }

        const assignedByFC = this.hpFG.get(SaHallPassFormFields.ASSIGNED_BY);
        assignedByFC.setValidators(Validators.required);
        assignedByFC.updateValueAndValidity();
      });

    // If schedule for later is enabled then add required validators, otherwise clear them
    this.hpFG
      .get(SaHallPassFormFields.IS_SCHEDULED)
      .valueChanges.pipe(takeUntil(this._destroyedSubject))
      .subscribe(isScheduled => {
        const dateFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_DATE);
        const timeFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_TIME);
        const assignedByFC = this.hpFG.get(SaHallPassFormFields.ASSIGNED_BY);
        if (isScheduled) {
          dateFC.setValidators([
            Validators.required,
            this._scheduledDateValidation(),
          ]);
          timeFC.setValidators([
            Validators.required,
            this._scheduledTimeGreaterThanCurrentValidation(),
            this._scheduledTimeValidation(),
          ]);
          dateFC.updateValueAndValidity();
          timeFC.updateValueAndValidity();

          assignedByFC.setValue(null);
          assignedByFC.disable();
          assignedByFC.updateValueAndValidity();
        } else {
          assignedByFC.enable();
          dateFC.clearValidators();
          timeFC.clearValidators();
        }
      });

    // Both fields are required for a scheduled pass, so run validation on both
    this.hpFG
      .get(SaHallPassFormFields.SCHEDULED_DATE)
      .valueChanges.pipe(takeUntil(this._destroyedSubject))
      .subscribe(dateValue => {
        if (!dateValue) return;
        const timeFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_TIME);
        timeFC.markAsTouched();
        if (!timeFC.value) timeFC.updateValueAndValidity();
      });
    this.hpFG
      .get(SaHallPassFormFields.SCHEDULED_TIME)
      .valueChanges.pipe(takeUntil(this._destroyedSubject))
      .subscribe(timeValue => {
        if (!timeValue) return;
        const dateFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_DATE);
        dateFC.markAsDirty();
        if (!dateFC.value || !!dateFC.errors) {
          dateFC.updateValueAndValidity();
        }
      });

    combineLatest([this.hpFG.valueChanges, this.hpTypeFC.valueChanges])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([hpFG, hpFC]) => {
        this._saService.setFormValidity(this.hpFG.valid && this.hpTypeFC.valid);
        if (this.hpFG.valid && this.hpTypeFC.valid) {
          const scheduledStartDate = this._getScheduledDate();

          this._saService.setPayload(SaHallPassSelectorType, {
            typeName: hpFC.name,
            typeId: hpFC.id,
            duration: hpFG.hpDuration,
            startDate: scheduledStartDate?.toDate() ?? null,
            teacherAssignedHash: hpFG.hpAssignedBy,
            teacherAssignedName: this._assignerName,
            note: hpFG.hpNote,
            isSelfGranted: hpFG.hpSelfGrant,
            requireTeacherApproval: hpFC.requireTeacherApproval,
          });
        }
      });

    this.hpTypeFC.valueChanges
      .pipe(
        takeUntil(this._destroyedSubject),
        withLatestFrom(this.mustAssignStaff$),
      )
      .subscribe(([hpFC, mustAssign]) => {
        const assignedByFC = this.hpFG.get(SaHallPassFormFields.ASSIGNED_BY);
        assignedByFC.reset();
        if (
          this.hpFG.get(SaHallPassFormFields.SELF_GRANT).value &&
          (hpFC?.requireTeacherApproval || mustAssign)
        ) {
          assignedByFC.setValidators(Validators.required);
        } else {
          if (!mustAssign) {
            assignedByFC.clearValidators();
          }
        }
        assignedByFC.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public setDefaultScheduledTimeValue() {
    const timeFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_TIME);
    if (!timeFC.value) {
      timeFC.setValue(day().add(1, 'h').format('HH:mm'));
      timeFC.updateValueAndValidity();
    }
  }

  public setIsScheduledState() {
    const isScheduledFC = this.hpFG.get(SaHallPassFormFields.IS_SCHEDULED);
    isScheduledFC.setValue(!isScheduledFC.value);
    if (isScheduledFC.value) {
      this._scrollToSchedulePassFields();
    } else {
      const dateFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_DATE);
      const timeFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_TIME);
      dateFC.reset();
      timeFC.reset();
      timeFC.updateValueAndValidity();
      dateFC.updateValueAndValidity();
    }
  }

  private _scrollToSchedulePassFields() {
    if (!this._formContentTemplate) return;
    setTimeout(() => {
      // wait for animation to finish
      this._formContentTemplate.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 300);
  }

  private _initDefaultHPDurationHandler() {
    combineLatest([
      this._settingsService.getSettingValueObs(
        mingaSettingTypes.PASS_DURATION_STUDENT_PASSES,
      ),
      this.hpTypeFC.valueChanges.pipe(
        filter(type => !!type),
        map(type => {
          return 'duration' in type ? type.duration : undefined;
        }),
      ),
    ])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([defaultStudentDuration, defaultHallPassDuration]) => {
        let timeValue = this.hpFG.get(SaHallPassFormFields.SELF_GRANT).value
          ? defaultStudentDuration
          : DEFAULT_STAFF_PASS_DURATION;

        const typeDuration: number | undefined = defaultHallPassDuration;
        if (typeDuration) {
          timeValue = typeDuration;
        }

        this._defaultHPTypeDuration.next(timeValue);
        if (this.hpFG.get(SaHallPassFormFields.SELF_GRANT).value) {
          this.hpFG.get(SaHallPassFormFields.DURATION).setValue(timeValue);
        }
      });
  }

  private _scheduledTimeGreaterThanCurrentValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const timeFC = control;
      const dateFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_DATE);

      if (!dateFC.value || !timeFC.value) {
        return;
      }

      const timeSegments = timeFC.value.split(':');
      const scheduledDate = dateFC.value
        .hour(parseInt(timeSegments[0], 10))
        .minute(parseInt(timeSegments[1], 10));

      if (scheduledDate.isBefore(day())) {
        return { error: 'Date must be in the future' };
      }

      return;
    };
  }

  private _scheduledDateValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const timeFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_TIME);
      if (!control.value && timeFC.value) {
        return { dateError: this.ASSIGNMENT_LABELS.SCHEDULE_DATE_ERROR };
      }
    };
  }

  private _scheduledTimeValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateFC = this.hpFG.get(SaHallPassFormFields.SCHEDULED_DATE);
      if (!control.value && dateFC.value) {
        return { timeError: this.ASSIGNMENT_LABELS.SCHEDULE_TIME_ERROR };
      }
    };
  }

  private _getScheduledDate(): day.Dayjs | undefined {
    const isScheduledFC = this.hpFG.get(SaHallPassFormFields.IS_SCHEDULED);
    if (!isScheduledFC.value) return;
    const startDate = this.hpFG.get(SaHallPassFormFields.SCHEDULED_DATE)
      .value as day.Dayjs;
    const startTime = this.hpFG.get(SaHallPassFormFields.SCHEDULED_TIME).value;
    if (!startDate || !startTime) return;

    const combinedStartDate = combineDateAndTime(startDate, startTime);

    return combinedStartDate;
  }

  public assignerChange(event) {
    this._assignerName = event?.label || '';
    // trigger a form change to update the payload with the new assigner name
    this.hpFG.updateValueAndValidity();
  }
}

<button
  class="button-wrapper"
  [class.disabled]="disabled"
  [class.module-disabled]="!moduleEnabled"
  [attr.data-analytics]="id"
  [attr.data-test]="id"
  (click)="navigate()">
  <mg-card
    paddingX="2-half"
    paddingY="2-half">
    <div
      fxLayout="column"
      fxLayoutAlign="space-between start">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="2px">
        <mg-icon
          class="metric-icon"
          [ngClass]="metricData.color"
          [iconName]="metricData.iconName">
        </mg-icon>
        <mg-text
          variant="label-md"
          [numberOfLines]="2"
          [color]="moduleEnabled ? 'surface-alt' : 'surface'">
          {{ metricData.labelName }}
        </mg-text>
      </div>
      <div
        *ngIf="moduleEnabled"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="2px">
        <mg-text variant="header-xl-secondary">
          {{ value | number }}
        </mg-text>
        <mg-icon
          *ngIf="!disabled"
          class="chevron-icon"
          iconName="mg-chevron-right">
        </mg-icon>
      </div>
      <mg-btn
        *ngIf="!moduleEnabled"
        variant="text"
        size="small"
        (pressed)="openSubscriptionModal(metricData.moduleConfig)">
        {{ MESSAGES.LEARN_MORE_LABEL }}
      </mg-btn>
    </div>
  </mg-card>
</button>

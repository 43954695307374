import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';

import { MgGroupListModule } from 'minga/app/src/app/groups/containers/MgGroupList';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { PermissionsModule } from 'src/app/permissions';
import { MgPipesModule } from 'src/app/pipes';
import { MgSpinnerModule } from 'src/app/spinner';

import { SharedToolsModule } from '@modules/tools/shared-tools/shared-tools.module';

import { GenericButtonModule } from '@shared/components/button';
import { GenericModule } from '@shared/components/generic';
import {
  OverlayModule,
  ModalOverlayService,
} from '@shared/components/modal-overlay';
import { RedeemPointsModalModule } from '@shared/components/redeem-points-modal';
import { TextModule } from '@shared/components/text/text.module';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';
import { UserProfileHeaderModule } from '@shared/components/user-profile-header';

import { TtMyClassModule } from './components/tt-my-class/tt-my-class.module';
import { TtMyToolsComponent } from './components/tt-my-tools/tt-my-tools.component';
import { TtRootComponent } from './components/tt-root/tt-root.component';
import { TeacherToolsService } from './services';
import { TeacherToolsFlexTimeService } from './services/tt-flex-time.service';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgOverlayToolsModule,
    MgSpinnerModule,
    MgGroupListModule,
    PermissionsModule,
    OverlayModule,
    GenericButtonModule,
    TilesCarouselModule,
    MgPipesModule,
    SharedToolsModule,
    TextModule,
    MgEmptyStateModule,
    RouterModule,
    RedeemPointsModalModule,
    NgSelectModule,
    TtMyClassModule,
    GenericModule,
    UserProfileHeaderModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatRippleModule,
  ],
  declarations: [TtRootComponent, TtMyToolsComponent],
  exports: [TtRootComponent, TtMyToolsComponent],
  providers: [
    TeacherToolsService,
    ModalOverlayService,
    TeacherToolsFlexTimeService,
  ],
})
export class TeacherToolsModule {}

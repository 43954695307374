<mg-tools-two-col-layout class="profile-view">
  <ng-template #leftCol>
    <mat-button-toggle-group class="filter-toggle-group">
      <mat-button-toggle
        *ngIf="isBehaviorEnabled$ | async"
        routerLinkActive="mat-button-toggle-checked"
        [routerLink]="routerLinks.points">
        {{ MESSAGES.POINTS_NAV_POINTS }}
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="isLeaderboardEnabled$ | async"
        routerLinkActive="mat-button-toggle-checked"
        [routerLink]="routerLinks.leaderboard">
        {{ MESSAGES.POINTS_NAV_LEADERBOARD }}
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="isRewardsStoreEnabled$ | async"
        routerLinkActive="mat-button-toggle-checked"
        [routerLink]="routerLinks.rewards">
        {{ MESSAGES.POINTS_NAV_REWARDS }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <section class="child-routes">
      <router-outlet></router-outlet>
    </section>
  </ng-template>
  <ng-template #rightCol>
    <section class="trophy-wrap">
      <div
        class="trophy"
        fxLayout="row"
        fxAlign="center center"
        fxLayout.gt-sm="column">
        <div
          fxFlex="210px"
          fxFlex.gt-sm="100%"
          fxFlexOrder.gt-sm="2">
          <div class="trophy-content">
            <mg-text
              variant="header-md"
              spacing="2-half">
              {{ MESSAGES.POINTS_BANNER_MESSAGE }}
            </mg-text>

            <div
              *ngIf="isRewardsStoreEnabled$ | async"
              class="points-label redeemable">
              <mg-text
                class="label"
                variant="body-md-secondary"
                element="span">
                {{ MESSAGES.REDEEMABLE_POINTS }}
              </mg-text>
              <mg-icon class="surface-icon">mg-points-menu</mg-icon>
              <mg-text
                variant="body-md-secondary"
                element="span">
                {{ redeemablePoints$ | async | number: '1.0':'en-US' }}
                {{ (redeemablePoints$ | async) === 1 ? ' pt' : ' pts' }}
              </mg-text>
            </div>
            <div class="points-label">
              <mg-text
                class="label"
                variant="body-md-secondary"
                element="span">
                {{ MESSAGES.LIFETIME_POINTS }}
              </mg-text>
              <mg-icon class="surface-icon">mg-points-menu</mg-icon>
              <mg-text
                variant="body-md-secondary"
                element="span">
                {{ totalPoints$ | async | number: '1.0':'en-US'
                }}{{ (totalPoints$ | async) === 1 ? ' pt' : ' pts' }}</mg-text
              >
            </div>
          </div>
        </div>
        <div
          fxFlex="100px"
          fxFlex.gt-sm="100%"
          fxFlexOrder.gt-sm="1">
          <div class="trophy-icon"></div>
        </div>
      </div>
    </section>
  </ng-template>
</mg-tools-two-col-layout>

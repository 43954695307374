<mg-manager-overlay-subpage
  *ngIf="layout.useLegacyLayout$ | async; else newLayoutTemplate"
  [loading]="isLoading$ | async">
  <ng-template #headerLeft>
    <h2 class="header-title">{{ PM_MESSAGES.PAGE_TITLE_TEAMS }}</h2>
  </ng-template>
  <ng-template #headerRight>
    <div class="header-right">
      <div
        fxFlex="noshrink"
        fxLayoutAlign="end end"
        fxLayoutAlign.xs="center center">
        <mg-btn
          variant="filled"
          [id]="'points-team-click-create'"
          (pressed)="teamsService.openModal()">
          {{ MESSAGES.BUTTON_LABEL_NEW_TEAM }}
        </mg-btn>
      </div>
    </div>
  </ng-template>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</mg-manager-overlay-subpage>

<!-- New layout template -->
<ng-template #newLayoutTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign.gt-sm="end end"
    [style.marginBottom]="'16px'">
    <mg-btn
      fxGrow="nogrow"
      variant="filled"
      [responsive]="true"
      (pressed)="teamsService.openModal()">
      {{ MESSAGES.BUTTON_LABEL_NEW_TEAM }}
    </mg-btn>
  </div>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</ng-template>

<!-- Main template content -->
<ng-template #mainContentTemplate>
  <mg-membership-list-table
    placeholderIcon="leaderboard"
    [types]="lists"
    [canChangeName]="true"
    [canChangeImage]="true"
    [canChangeStatus]="true"
    [canChangeColor]="true"
    [refreshList]="teamsService.refreshLists.asObservable()">
  </mg-membership-list-table>
</ng-template>

import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { FiltersFormData } from '../types';

@Injectable({
  providedIn: 'root',
})
export class FiltersFormService {
  /** Service constructor */
  constructor(private _fb: FormBuilder) {}

  public create(data: FiltersFormData): FiltersFormData {
    return data;
  }
}

import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import {
  PointsManagerService,
  LeaderboardTimeFilterEnum,
  LeaderboardTypeEnum,
} from 'minga/app/src/app/services/PointsManager/PointsManager.service';
import {
  GetPersonLeaderboardResponse,
  GetTeamLeaderboardResponse,
  PersonLeaderboardItem,
  TeamLeaderboardItem,
} from 'minga/proto/points/points_pb';
import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { FormSelectOption } from '@shared/components/form/types';
import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';

import {
  LeaderboardMessages,
  periodSelectOptions,
} from './PointsLeaderboard.constants';

@Component({
  selector: 'mg-points-leaderboard',
  templateUrl: './PointsLeaderboard.component.html',
  styleUrls: ['./PointsLeaderboard.component.scss'],
})
export class PointsLeaderboardComponent implements OnDestroy {
  totalPoints = 0;
  teamItems: TeamLeaderboardItem.AsObject[] = [];
  userItems: PersonLeaderboardItem.AsObject[] = [];

  teamsInitialized = false;
  usersInitialized = false;
  canViewTeamPoints$: Observable<boolean>;
  canViewIndividualPoints$: Observable<boolean>;

  leaderboardTab: LeaderboardTypeEnum;
  leaderboardTimeFilter: LeaderboardTimeFilterEnum =
    LeaderboardTimeFilterEnum.MONTHLY;

  private _pointsUpdatedSub: Subscription;
  private _destroyed$ = new ReplaySubject<void>(1);

  // remove header and title for consuming in teacher/student tools
  @Input() isInlineVersion: boolean;
  public periodSelectOptions: FormSelectOption<string>[] = periodSelectOptions;

  private _typeSelectOptions = new BehaviorSubject<FormSelectOption<string>[]>(
    [],
  );
  public typeSelectOptions$ = this._typeSelectOptions.asObservable();
  public MESSAGES = LeaderboardMessages;
  public isProfileView = false;

  constructor(
    private _route: ActivatedRoute,
    private _pointsManager: PointsManagerService,
    public auth: AuthInfoService,
    private _settingService: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {
    // update feeds when points are updated
    this._pointsUpdatedSub = this._pointsManager.onPointsUpdated.subscribe(
      () => {
        this._resetLeaderboards();
        this.onChangeTypeFilter();
      },
    );

    this.canViewTeamPoints$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.PBIS_TEAM_POINTS,
    );
    this.canViewIndividualPoints$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.PBIS_INDIVIDUAL_POINTS,
    );

    this.isProfileView = this._route.snapshot.paramMap.has('hash');

    combineLatest([this.canViewTeamPoints$, this.canViewIndividualPoints$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([canViewTeamPoints, canViewIndividualPoints]) => {
        let initialFetchType: LeaderboardTypeEnum;

        if (canViewTeamPoints) {
          this._updateTypeOptions({
            value: LeaderboardTypeEnum.TEAMS,
            label: 'Teams',
          });

          initialFetchType = LeaderboardTypeEnum.TEAMS;
        }

        if (canViewIndividualPoints) {
          this._updateTypeOptions({
            value: LeaderboardTypeEnum.USERS,
            label: 'Users',
          });

          initialFetchType = LeaderboardTypeEnum.USERS;
        }

        // check if we've made an initial fetch
        if (!this.leaderboardTab && initialFetchType) {
          this.leaderboardTab = initialFetchType;
          this.onChangeTypeFilter();
        }
      });
  }

  ngOnDestroy() {
    this._pointsUpdatedSub.unsubscribe();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _updateTypeOptions(option: FormSelectOption<string>) {
    const currentOptions = this._typeSelectOptions.getValue();

    if (currentOptions.find(o => o.value === option.value)) return;

    const newOptions = [...currentOptions, option];

    this._typeSelectOptions.next(newOptions);
  }

  private _resetLeaderboards() {
    // reset
    this.userItems = [];
    this.teamItems = [];
    this.teamsInitialized = false;
    this.usersInitialized = false;
  }

  changeTimeFilter(newTimeFilter: LeaderboardTimeFilterEnum) {
    if (newTimeFilter !== this.leaderboardTimeFilter) {
      this.leaderboardTimeFilter = newTimeFilter;
      this._resetLeaderboards();
      // update current tab data
      this.onChangeTypeFilter();
    }
  }

  changeTypeFilter(type: LeaderboardTypeEnum) {
    this.leaderboardTab = type;
    this.onChangeTypeFilter();
  }

  onChangeTypeFilter() {
    // get both, so know if there's user and team results
    this.getTeamLeaderboard();
    this.getUserLeaderboard();
  }

  async getUserLeaderboard() {
    if (this.userItems.length === 0) {
      let results: GetPersonLeaderboardResponse.AsObject;
      if (this.leaderboardTimeFilter === LeaderboardTimeFilterEnum.MONTHLY) {
        results = await this._pointsManager.getUserMonthlyLeaderboard();
      } else {
        results = await this._pointsManager.getUserYearlyLeaderboard();
      }
      this.totalPoints = results.totalPoints;
      this.userItems = results.itemsList;
      this.usersInitialized = true;
      this._cdr.markForCheck();
    }
  }

  public get canDownloadExport(): boolean {
    return !this._runtime.isCordovaApp();
  }

  async getTeamLeaderboard() {
    if (this.teamItems.length === 0) {
      let results: GetTeamLeaderboardResponse.AsObject;
      if (this.leaderboardTimeFilter === LeaderboardTimeFilterEnum.MONTHLY) {
        results = await this._pointsManager.getTeamMonthlyLeaderboard();
      } else {
        results = await this._pointsManager.getTeamYearlyLeaderboard();
      }
      this.totalPoints = results.totalPoints;
      this.teamItems = results.itemsList;
      this.teamsInitialized = true;
      this._cdr.markForCheck();
    }
  }

  launchExport() {
    this._pointsManager.exportLeaderboard(
      this.leaderboardTab,
      this.leaderboardTimeFilter,
    );
  }

  getRandomColor(index: number) {
    const colorOptions = [
      '#85649e',
      '#78efd9',
      '#e67aa0',
      '#d6e261',
      '#e2ab56',
      '#1d47b9',
      '#91bcad',
      '#db6578',
      '#1d9fb9',
      '#daac64',
      '#4ba0cb',
      '#003366',
      '#2b55c3',
      '#6FEF99',
    ];
    return colorOptions[index % colorOptions.length];
  }

  public trackById(index: number, item: TeamLeaderboardItem.AsObject) {
    return item ? item.id : index;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import * as day from 'dayjs';
import { cloneDeep, isEmpty } from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { FlexTimeActivity } from 'minga/domain/flexTime';
import { MembershipListType } from 'minga/domain/membershipList';
import { Banner } from 'minga/proto/gateway/banner_pb';
import { MingaRoleType } from 'minga/util';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';
import { Person } from 'src/app/people';
import { scrollIntoView } from 'src/app/util/scroll-into-view';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormSelectOption } from '@shared/components/form';
import {
  fromRestrictionInput,
  toRestrictionInput,
} from '@shared/components/form-restriction-input/form-restriction-input.constants';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import {
  FTM_AT_FORM,
  FtmActivityTemplatesEditFormFields,
  FtmActivityTemplatesMessages,
} from '../../ftm-activity-templates.constants';
import {
  FlexEditModalData,
  FlexEditModalResponse,
} from '../../ftm-activity-templates.types';
import { FtmActivityTemplatesService } from '../../services';

const SUBMITTABLE_STATES: FormStateMachine[] = ['idle', 'invalid'];

type FormStateMachine =
  | 'idle'
  | 'invalid'
  | 'submitting'
  | 'creating'
  | 'updating'
  | 'deleting'
  | 'uploading';
@Component({
  selector: 'mg-ftm-at-edit',
  templateUrl: './ftm-at-edit.component.html',
  styleUrls: ['./ftm-at-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmAtEditComponent implements OnDestroy, OnInit {
  private _formState = new BehaviorSubject<FormStateMachine>('idle');
  public formState$ = this._formState.asObservable();
  private _destroyed = new ReplaySubject<void>(1);
  private _isNew = new BehaviorSubject(false);
  public isNew$ = this._isNew.asObservable();
  private _canDelete = new BehaviorSubject(false);
  public canDelete$ = this._canDelete.asObservable();

  @ViewChild('formElement', { static: false })
  formElement?: ElementRef<HTMLElement>;

  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.ALT_TEAL,
  };
  public readonly MESSAGES = FtmActivityTemplatesMessages;
  public readonly FORM_FIELDS = FtmActivityTemplatesEditFormFields;

  /** Form Controls */
  public readonly form = this._fb.group(cloneDeep(FTM_AT_FORM));

  private _page = 1;
  private _itemsPerPage = 20;
  // Keep track of 2 seperate lists of images for pseudo lazy loading
  // We've fetched all the data but at least this way we lazy load images
  private _bannersData: Banner.AsObject[] = [];
  private _imageList = new BehaviorSubject<Banner.AsObject[]>(null);
  public imageList$ = this._imageList.asObservable();
  public roles: string[] = [
    MingaRoleType.TEACHER,
    MingaRoleType.STAFF,
    MingaRoleType.MANAGER,
    MingaRoleType.OWNER,
  ];
  private _activityTypeOptionsSubj = new BehaviorSubject<
    FormSelectOption<number>[]
  >(undefined);
  public activityTypeOptions$ = this._activityTypeOptionsSubj.asObservable();

  /** Membership Details */
  public readonly membershipType = MembershipListType.FLEX_RESTRICTION_LIST;

  constructor(
    @Inject(MODAL_OVERLAY_DATA) private _data: FlexEditModalData,
    private _modalOverlay: ModalOverlayRef<
      FlexEditModalResponse,
      FlexEditModalData
    >,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _ftmAtService: FtmActivityTemplatesService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _sentry: SentryService,
    private _flexPermissions: FlexTimePermissionsService,
    private _flexInstancesService: FlexTimeActivityInstanceService,
    private _cdr: ChangeDetectorRef,
  ) {
    this._getBanners();
    this._getActivityTypes();

    if (this._flexPermissions.isFlexTimeAdmin()) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.TEACHER)
        .setValidators(Validators.required);
    }
  }

  async ngOnInit() {
    this._setInitialForm();
  }

  public get canSubmit() {
    return SUBMITTABLE_STATES.includes(this._formState.value);
  }

  public get submitLabel() {
    const state = this._formState.getValue();
    const isNew = this._isNew.getValue();

    switch (state) {
      case 'creating':
        return FtmActivityTemplatesMessages.MODAL_CREATING_BTN;
      case 'updating':
        return FtmActivityTemplatesMessages.MODAL_UPDATING_BTN;
      case 'uploading':
        return FtmActivityTemplatesMessages.MODAL_UPLOADING_BTN;
      default:
        return isNew
          ? FtmActivityTemplatesMessages.MODAL_CREATE_BTN
          : FtmActivityTemplatesMessages.MODAL_UPDATE_BTN;
    }
  }

  public async submit() {
    if (!this.canSubmit) return;
    this._formState.next('submitting');

    markNestedFormGroupAsDirty(this.form);

    if (!this.form.valid) {
      this._formState.next('invalid');
      scrollIntoView(this.formElement.nativeElement, {
        align: { top: 0 },
      });
      return;
    }

    const {
      banner,
      allowStudentToRegister,
      restriction,
      typeId,
      ...restFormValues
    } = this.form.value;

    const activity: FlexTimeActivity = {
      ...this._data,
      ...restFormValues,
      bannerHash: banner.bannerHash || null,
      imagePath: banner.assetPath || null,
      allowStudentToRegister: !!allowStudentToRegister,
      restriction: fromRestrictionInput(
        restriction,
        (this._data as any)?.restriction,
      ),
      type: {
        id: typeId,
      },
    };

    if (this._isNew.getValue()) {
      await this._create(activity);
    } else {
      await this._update(activity);
    }

    this._formState.next('idle');
  }

  public async delete() {
    const isNew = this._isNew.getValue();
    if (isNew) {
      return this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
    }

    await this._canActivityBeDeleted();
    const canDelete = this._canDelete.getValue();

    if (!canDelete) {
      return this._dialog.open(ConfirmationDialogComponent, {
        data: {
          text: {
            description: this.MESSAGES.DIALOG_CONFIRM_UNABLE_TO_DELETE,
            deleteBtn: this.MESSAGES.DIALOG_CONFIRM_UNABLE_TO_DELETE_BTN,
          },
        },
      });
    }
    // if it's a new activity and the user hasn't touched the form we
    // dont need a confirm exit dialog
    if (this.form.pristine && isNew) {
      return this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
    }

    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this.MESSAGES.DIALOG_CONFIRM_DELETE,
          deleteBtn: this.MESSAGES.DIALOG_CONFIRM_DELETE_BTN,
        },
      },
    });

    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      if (isNew) {
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      } else {
        await this._delete();
      }
    });
  }

  public endReached(event: number) {
    const current = this._page * this._itemsPerPage;

    if (current >= this._bannersData.length) return;

    this._page = this._page + 1;
    const next = this._page * this._itemsPerPage;
    const nextPage = this._bannersData.slice(current, next);

    this._imageList.next([...this._imageList.getValue(), ...nextPage]);
  }

  public statusChange(event: 'PENDING' | 'VALID') {
    if (event === 'PENDING') {
      this._formState.next('uploading');
    } else {
      this._formState.next('idle');
    }
  }

  public handleOnPersonSelected(person: Person) {
    const control = this.form.get(FtmActivityTemplatesEditFormFields.TEACHER);

    const value = person
      ? {
          name: person.displayName,
          hash: person.hash,
        }
      : null;

    control.setValue(value);
  }

  public markTeacherInputFieldAsTouched() {
    const control = this.form.get(FtmActivityTemplatesEditFormFields.TEACHER);
    control.markAsTouched();
  }

  public onSelectionChange(data: any) {
    const control = this.form.get(FtmActivityTemplatesEditFormFields.TEACHER);
    control.setValue({ name: '', hash: data });
  }

  private async _getBanners() {
    const banners = await this._ftmAtService.getBannerList();

    this._bannersData = banners;

    this._imageList.next(banners.slice(0, this._page * this._itemsPerPage));
  }

  private async _setInitialForm() {
    const existingId = (this._data as any)?.id;
    this._data = !existingId ? {} : await this._ftmAtService.fetch(existingId);

    this._isNew.next(!existingId);

    const type = this._data as FlexTimeActivity;

    if (type.name) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.NAME)
        .setValue(type.name);
    }

    if (type.description) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.DESCRIPTION)
        .setValue(type.description);
    }

    if (type.createdByPerson) {
      this.form.get(FtmActivityTemplatesEditFormFields.TEACHER).setValue({
        name: type.createdByPerson.name,
        hash: type.createdByPerson.hash,
      });
    }

    if (type.registrationLimit) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.REGISTRATION_LIMIT)
        .setValue(type.registrationLimit);
    }

    if (type.bannerHash || type.imagePath) {
      this.form.get(FtmActivityTemplatesEditFormFields.BANNER).setValue({
        assetPath: type.imagePath || null,
        bannerHash: type.bannerHash || null,
      });
    }

    if (typeof type.allowStudentToRegister === 'boolean') {
      this.form
        .get(FtmActivityTemplatesEditFormFields.STUDENTS_TO_REGISTER)
        .setValue(type.allowStudentToRegister);
    }

    if (type.restriction) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.RESTRICTION_MEMBERSHIP_LIST)
        .patchValue(toRestrictionInput(type.restriction));
    }

    if (type.location) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.LOCATION)
        .setValue(type.location);
    }

    if (type.type?.id) {
      this.form
        .get(FtmActivityTemplatesEditFormFields.ACTIVITY_TYPE)
        .setValue(type.type.id);
    }

    this._cdr.markForCheck();
  }

  private async _getActivityTypes() {
    const activityTypes = await this._ftmAtService.getFlexTimeActivityTypes();
    const activityTypeOptions = activityTypes.map(activityType => {
      return {
        label: activityType.name,
        value: activityType.id,
      } as FormSelectOption<number>;
    });
    this._activityTypeOptionsSubj.next(activityTypeOptions);
  }

  private async _create(activity) {
    try {
      await this._actionWithLoader(
        this._ftmAtService.upsertActivity(activity),
        'creating',
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.CREATE);
    } catch (e) {
      this._sentry.captureMessageAsError(
        FtmActivityTemplatesMessages.SNACKBAR_CREATE_ERROR,
        e,
      );
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmActivityTemplatesMessages.SNACKBAR_CREATE_ERROR,
      });
    }
  }

  private async _update(activity) {
    try {
      await this._actionWithLoader(
        this._ftmAtService.upsertActivity(activity),
        'updating',
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT);
    } catch (e) {
      this._sentry.captureMessageAsError(
        FtmActivityTemplatesMessages.SNACKBAR_UPDATE_ERROR,
        e,
      );
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmActivityTemplatesMessages.SNACKBAR_UPDATE_ERROR,
      });
    }
  }

  private async _delete() {
    try {
      await this._actionWithLoader(
        // lets just archive this so we dont create orphans on flex instances
        this._ftmAtService.archive((this._data as FlexTimeActivity)?.id),
        'deleting',
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.DELETE);
    } catch (e) {
      this._sentry.captureMessageAsError(
        FtmActivityTemplatesMessages.SNACKBAR_DELETE_ERROR,
        e,
      );
      this._systemAlertSnackBar.open({
        type: 'error',
        message:
          e?.message || FtmActivityTemplatesMessages.SNACKBAR_DELETE_ERROR,
      });
    }
  }

  private async _actionWithLoader<T>(
    promise: Promise<T>,
    state: FormStateMachine,
  ) {
    try {
      this.form.disable();
      this._formState.next(state);
      await Promise.all([promise]);
    } catch (e) {
      throw e;
    } finally {
      this.form.enable();
      this._formState.next('idle');
    }
  }

  /**
   * We need to check and see if there's any future instances
   * associated with this template
   */
  private async _canActivityBeDeleted() {
    const id = (this._data as any)?.id;

    if (!id) return;

    const instances = await this._flexInstancesService.fetchAll({
      startDate: day(),
      endDate: day().add(1, 'year'),
      activityTemplateId: id,
    });

    const inUse = !isEmpty(instances);

    this._canDelete.next(!inUse);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isNew.complete();
  }
}

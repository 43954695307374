import { Injectable } from '@angular/core';

import * as day from 'dayjs';

import { dateTimeObjectToDateTimeMessage } from 'minga/app/src/app/util/date';
import { StudentSection } from 'minga/domain/studentSchedule';
import { StudentScheduleManager } from 'minga/proto/student_schedule/student_schedule_ng_grpc_pb';
import { GetStudentScheduleForRecipientRequest } from 'minga/proto/student_schedule/student_schedule_pb';
import { StudentScheduleMapper } from 'minga/shared-grpc/student_schedule';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import { createCacheCollection } from '../cache/cache.utils';
import { ErrorHandlerService } from '../error-handler';

@Injectable({ providedIn: 'root' })
export class StudentScheduleService {
  private _cacheItems = createCacheCollection(key =>
    this._cacheService.create<StudentSection[]>(
      `${CacheKey.STUDENT_SCHEDULE}:${key}`,
      async data => {
        return await this._getStudentSchedule(
          data.personHash,
          data.startDate,
          data.endDate,
        );
      },
      {
        ttl: 60,
      },
    ),
  );
  constructor(
    private _cacheService: CacheService,
    private _studentScheduleManager: StudentScheduleManager,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async getStudentSchedule(
    personHash: string,
    startDate: day.Dayjs,
    endDate: day.Dayjs,
    opts?: {
      revalidate?: boolean;
    },
  ): Promise<StudentSection[]> {
    const key = this._getCacheKey(personHash, startDate, endDate);
    const cacheItem = this._cacheItems.get(key);

    return await cacheItem
      .get(
        {
          personHash,
          startDate,
          endDate,
        },
        opts,
      )
      .toPromise();
  }

  private async _getStudentSchedule(
    personHash: string,
    startDate: day.Dayjs,
    endDate: day.Dayjs,
  ): Promise<StudentSection[]> {
    try {
      const request = new GetStudentScheduleForRecipientRequest();
      request.setRecipientPersonHash(personHash);

      const start = startDate ? startDate.toDate() : new Date();
      request.setStartDate(dateTimeObjectToDateTimeMessage(start));

      const end = endDate ? endDate.toDate() : new Date();
      request.setEndDate(dateTimeObjectToDateTimeMessage(end));

      const result =
        await this._studentScheduleManager.getStudentScheduleForRecipient(
          request,
        );
      const studentScheduleList = result.getStudentScheduleList();
      const mappedStudentScheduleList: StudentSection[] =
        studentScheduleList.map(StudentScheduleMapper.fromProto);

      return mappedStudentScheduleList;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch student schedule',
        error,
        false,
      );
    }
  }

  private _getCacheKey(hash: string, start: day.Dayjs, end: day.Dayjs): string {
    return `${hash}:${start.format('YYYY-MM-DD')}:${end.format('YYYY-MM-DD')}`;
  }
}

<!-- Mobile bottomsheet -->
<button
  *ngIf="showBottomSheetTrigger$ | async"
  matRipple
  matRippleColor="rgba(0, 0, 0, 0.02)"
  class="mobile-bottomsheet-trigger"
  (click)="openMobileBottomSheet()"
  type="button"></button>
<!-- Component Container -->
<div
  class="container"
  [class.secondary-style]="appearance === 'secondary'"
  [class.tertiary-style]="appearance === 'tertiary'"
  [class.condensed-style]="condensed"
  [class.is-multi]="multiple"
  [class.full-width]="isFullWidth"
  [attr.data-analytics]="'select-wrap-' + (id || '')"
  [attr.data-test]="'select-wrap-' + (id || '')">
  <!-- Clear Button -->
  <div
    *ngIf="isClearable && (showClear$ | async)"
    class="custom-clear-icon"
    (click)="resetFormSelect()">
    <mat-icon>close</mat-icon>
  </div>
  <!-- Arrow Icon -->
  <div
    class="custom-dropdown-arrow"
    [class.disabled]="isDisabled$ | async">
    <mg-icon [iconName]="(isOpen$ | async) ? 'up-toggle' : 'down-toggle'">
    </mg-icon>
  </div>

  <label
    *ngIf="showLabel"
    class="input-label focused"
    [ngClass]="labelBackground"
    [class.has-error]="control.invalid && control.touched"
    [class.has-input]="!!control.value">
    {{ placeholder }}
  </label>
  <!-- formControlName="selected" -->
  <ng-select
    class="input-alignment-fix"
    bindLabel="label"
    bindValue="value"
    labelForId="state"
    [placeholder]="displayPlaceholder"
    [notFoundText]="notFoundText"
    [formControl]="$any(control)"
    [class.error]="control.invalid && control.touched"
    [class.valid]="control.valid && control.dirty"
    [class.cursor]="true"
    [class.input-spacing]="!!control.value && control.value.length"
    [ngModel]="currentValue"
    [addTag]="addTag"
    [addTagText]="addTagText"
    [clearable]="isClearable"
    [typeahead]="input$"
    [searchable]="searchable"
    [closeOnSelect]="closeOnSelect"
    [clearSearchOnAdd]="true"
    [loading]="isLoadingObs$ | async"
    [multiple]="multiple"
    [appendTo]="appendTo || null"
    [items]="options"
    [hideSelected]="hideSelected"
    [inputAttrs]="inputAttrs"
    [disabled]="isDisabled$ | async"
    (change)="handleModelChange($event)"
    (add)="trackAddedItem()"
    (remove)="trackRemovedItem($event)"
    (open)="setOpenState(true)"
    (close)="setOpenState(false)"
    (blur)="blurEvent.emit()">
    <!-- Multi Select Item template -->
    <ng-template
      *ngIf="multiple && !selectContent"
      ng-label-tmp
      let-option="item"
      let-clear="clear">
      <div class="selected-option">
        <div
          class="label"
          [title]="option.label">
          <span>{{ option.label }}</span>
        </div>
        <span
          class="close"
          (click)="clear(option)">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </ng-template>

    <ng-container *ngIf="selectContent">
      <ng-template
        ng-option-tmp
        ng-label-tmp
        let-option="item">
        <ng-template
          [ngTemplateOutlet]="selectContent"
          [ngTemplateOutletContext]="{ item: option }"></ng-template>
      </ng-template>
    </ng-container>

    <!-- Loading Template -->
    <ng-template ng-loadingspinner-tmp>
      <mg-spinner
        variant="rainbow"
        [diameter]="20"
        [thickness]="2">
      </mg-spinner>
    </ng-template>
  </ng-select>
</div>

<mg-form-error *ngIf="control.invalid && control.touched">
  {{ control?.errors | makeInputErrorMessage }}
</mg-form-error>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'mg-animated-wave',
  styleUrls: ['./animated-wave.component.scss'],
  template: `
    <div class="wave tw-absolute tw-inset-0 tw-h-full tw-z-10 tw-opacity-30">
      <div class="gradient"></div>
    </div>
  `,
})

//
export class AnimatedWaveComponent {
  @Input() speed: 'slow' | 'fast' = 'slow';
}

// TODO: change speed to match the student ID

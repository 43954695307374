<div [ngClass]="styleClasses">
  <mg-placeholder-card
    *ngFor="let index of [].constructor(quantity); let i = index">
    <div class="tw-grid tw-gap-2.5">
      <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <mg-placeholder-avatar size="small"></mg-placeholder-avatar>
        <mg-placeholder-header
          class="tw-w-2/3"
          size="small"></mg-placeholder-header>
      </div>
      <mg-placeholder-button class="tw-w-1/2"></mg-placeholder-button>
    </div>
  </mg-placeholder-card>
</div>

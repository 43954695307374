import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { PBIS_CHECKIN_TYPES } from '@modules/teacher-tools/constants/tt-view.constants';
import { TeacherToolsService } from '@modules/teacher-tools/services';

import { RedeemPointsModalService } from '@shared/components/redeem-points-modal';
import { FlexTimePermissionsService } from '@shared/services/flex-time';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import { ActionMesssages } from '../constants/tools.constants';
import {
  ACTION_CATEGORY,
  ACTION_KEY,
  ToolAction,
} from '../shared-tools/types/shared-tools.types';
import { StudentToolsCheckinService } from '../student-tools/services';

@Injectable()
export class ToolsActionsService {
  /**
   * Centralized list of all tools actions shared amons student and admin tools
   * (teacher tools will also use this once we migate to new design)
   */
  public actions: ToolAction[] = [
    {
      key: ACTION_KEY.CREATE_HALL_PASS,
      id: 'tools-dashboard-click-assign-hallpass',
      label: ActionMesssages.HALL_PASS_BTN,
      icon: '/assets/teacher-tools/hall_pass_img.svg',
      onPress: () => {
        this._teacherToolsService.openHallpassAssignmentForm();
      },
      hasPermission$: combineLatest([
        this._mingaSettings.isHallPassModuleEnabled(),
        this._permissionService.observePermission(
          MingaPermission.HALL_PASS_CREATE,
        ),
      ]).pipe(
        map(([settingEnabled, canCreate]) => settingEnabled && canCreate),
      ),
      category: ACTION_CATEGORY.HALL_PASS,
      usedIn: ['student', 'admin'],
    },
    {
      key: ACTION_KEY.SELF_CHECK_IN,
      id: 'tools-dashboard-click-assign-checkin',
      label: ActionMesssages.SELF_CHECK_IN_BTN,
      icon: '/assets/teacher-tools/check_in_img.svg',
      onPress: () => {
        this._studentToolsCheckinService.openScanner(false, true);
      },
      hasPermission$: this._mingaSettings.isCheckinModuleEnabled(),
      category: ACTION_CATEGORY.CHECK_IN,
      usedIn: ['student'],
    },
    {
      key: ACTION_KEY.CHECK_IN,
      id: 'tools-dashboard-click-self-checkin',
      label: ActionMesssages.CHECK_IN_BTN,
      icon: '/assets/teacher-tools/check_in_img.svg',
      onPress: () => {
        this._teacherToolsService.openCheckinForm();
      },
      hasPermission$: combineLatest([
        this._mingaSettings.isCheckinModuleEnabled(),
        this._permissionService.observePermission(
          MingaPermission.CHECKIN_MANAGE,
        ),
      ]).pipe(
        map(
          ([settingEnabled, manageCheckin]) => settingEnabled && manageCheckin,
        ),
      ),
      category: ACTION_CATEGORY.CHECK_IN,
      usedIn: ['admin'],
    },
    {
      key: ACTION_KEY.ASSIGN_ACTIVITY,
      id: 'tools-dashboard-click-assign-activity',
      label: ActionMesssages.ACTIVITY_BTN,
      icon: '/assets/teacher-tools/flex_img.svg',
      onPress: () => {
        this._teacherToolsService.openAssignActivityForm();
      },
      hasPermission$: this._mingaSettings
        .isFlexTimeModuleEnabled()
        .pipe(
          map(
            settingEnabled =>
              settingEnabled &&
              this._flexPermissionService.isFlexTimeSelfManagerOrHigher(),
          ),
        ),
      category: ACTION_CATEGORY.ACTIVITY,
      usedIn: ['admin'],
    },
    {
      key: ACTION_KEY.ASSIGN_PRAISE,
      id: 'tools-dashboard-click-assign-praise',
      label: ActionMesssages.PRAISE_BTN,
      icon: '/assets/teacher-tools/praise_img.svg',
      onPress: () => {
        this._teacherToolsService.openPbisTrackingForm(
          PBIS_CHECKIN_TYPES.PRAISE,
        );
      },
      hasPermission$: combineLatest([
        this._mingaSettings.isPbisModuleEnabled(),
        this._permissionService.observePermission(MingaPermission.PBIS_CREATE),
      ]).pipe(
        map(([settingEnabled, canCreate]) => settingEnabled && canCreate),
      ),
      category: ACTION_CATEGORY.PBIS,
      usedIn: ['student', 'admin'],
    },
    {
      key: ACTION_KEY.ASSIGN_GUIDANCE,
      id: 'tools-dashboard-click-assign-guidance',
      label: ActionMesssages.GUIDANCE_BTN,
      icon: '/assets/teacher-tools/guidance_img.svg',
      onPress: () => {
        this._teacherToolsService.openPbisTrackingForm(
          PBIS_CHECKIN_TYPES.GUIDANCE,
        );
      },
      hasPermission$: combineLatest([
        this._mingaSettings.isPbisModuleEnabled(),
        this._permissionService.observePermission(MingaPermission.PBIS_CREATE),
      ]).pipe(
        map(([settingEnabled, canCreate]) => settingEnabled && canCreate),
      ),
      category: ACTION_CATEGORY.PBIS,
      usedIn: ['student', 'admin'],
    },
    {
      key: ACTION_KEY.ASSIGN_CONSEQUENCE,
      id: 'tools-dashboard-click-assign-consequence',
      label: ActionMesssages.CONSEQUENCE_BTN,
      icon: '/assets/teacher-tools/consequence_img.svg',
      onPress: () => {
        this._teacherToolsService.openPbisTrackingForm(
          PBIS_CHECKIN_TYPES.CONSEQUENCE,
        );
      },
      hasPermission$: combineLatest([
        this._mingaSettings.isPbisModuleEnabled(),
        this._mingaSettings.getSettingValueObs(
          mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
        ),
        this._permissionService.observePermission(
          MingaPermission.CONSEQUENCE_CREATE,
        ),
        this._permissionService.observePermission(
          MingaPermission.PBIS_TYPE_MANAGE,
        ),
      ]).pipe(
        map(
          ([settingEnabled, consequencesEnabled, canCreate, canManage]) =>
            settingEnabled && consequencesEnabled && (canCreate || canManage),
        ),
      ),
      category: ACTION_CATEGORY.PBIS,
      usedIn: ['student', 'admin'],
    },
    {
      key: ACTION_KEY.REDEEM_POINTS,
      id: 'tools-dashboard-click-redeem-points',
      label: ActionMesssages.REDEEM_POINTS_BTN,
      icon: '/assets/teacher-tools/points_img.svg',
      onPress: () => {
        this._redeemPointsModalService.openPeopleSelector();
      },
      hasPermission$: combineLatest([
        this._mingaSettings.getSettingValueObs(
          mingaSettingTypes.PM_REWARDS_STORE,
        ),
        this._permissionService.observePermission(
          MingaPermission.MANAGE_REWARDS,
        ),
        this._mingaSettings.isPbisModuleEnabled(),
      ]).pipe(
        map(
          ([rewardStoreEnabled, manageRewards, pbisEnabled]) =>
            rewardStoreEnabled && manageRewards && pbisEnabled,
        ),
      ),
      category: ACTION_CATEGORY.POINTS,
      usedIn: ['student', 'admin'],
    },
    {
      key: ACTION_KEY.MANAGE_POINTS,
      id: 'tools-dashboard-click-manage-points',
      label: ActionMesssages.MANAGE_POINTS_BTN,
      icon: '/assets/teacher-tools/manage_pts_img.svg',
      onPress: () => {
        this._teacherToolsService.openManagePointsForm();
      },
      hasPermission$: combineLatest([
        this._permissionService.observePermission(
          MingaPermission.POINTS_ALLOCATE_ALLOWED,
        ),
        this._permissionService.observePermission(
          MingaPermission.POINTS_MANAGE,
        ),
        this._mingaSettings.isPbisModuleEnabled(),
      ]).pipe(
        map(
          ([canAllocate, pointsManage, pbisEnabled]) =>
            canAllocate && pointsManage && pbisEnabled,
        ),
      ),
      category: ACTION_CATEGORY.POINTS,
      usedIn: ['student', 'admin'],
    },

    {
      key: ACTION_KEY.KIOSK_MODE,
      id: 'tools-dashboard-click-kiosk',
      label: ActionMesssages.KIOSK_MODE_BTN,
      icon: '/assets/teacher-tools/kiosk_mode_img.svg',
      onPress: () => {
        this._teacherToolsService.showKioskModal();
      },
      hasPermission$: this._kioskPermissions.isKioskEnabled(),
      category: ACTION_CATEGORY.KIOSK,
      usedIn: ['admin'],
    },
  ];

  constructor(
    private _mingaSettings: MingaSettingsService,
    private _permissionService: PermissionsService,
    private _flexPermissionService: FlexTimePermissionsService,
    private _redeemPointsModalService: RedeemPointsModalService,
    private _teacherToolsService: TeacherToolsService,
    private _studentToolsCheckinService: StudentToolsCheckinService,
    private _kioskPermissions: KioskPermissionsService,
  ) {}
}

<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="
    (isNew$ | async) ? MESSAGES.MODAL_ADD_TITLE : MESSAGES.MODAL_EDIT_TITLE
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #formElement>
    <form
      id="activity-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        class="form"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="16px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.INVALID_FORM_MESSAGE">
          </mg-system-alert>
        </fieldset>

        <mg-form-text-input
          [id]="'flextime-activity-create-input-name'"
          [label]="MESSAGES.INPUT_NAME"
          [control]="form.get(FORM_FIELDS.NAME)">
        </mg-form-text-input>

        <mg-form-text-input
          inputType="textarea"
          [id]="'flextime-activity-create-input-description'"
          [label]="MESSAGES.INPUT_DESCRIPTION"
          [control]="form.get(FORM_FIELDS.DESCRIPTION)">
        </mg-form-text-input>

        <div
          class="teacher-input"
          fxFlex="50%"
          *ngIf="'FLEX_TIME_MANAGE' | hasPermission">
          <mg-form-select
            mgTeacherSearch
            appendTo="body"
            placeholder="Teacher"
            [id]="'flextime-activity-create-input-teacher'"
            [useCachedValues]="false"
            [ngClass]="{
              invalid:
                form.get(FORM_FIELDS.TEACHER).invalid &&
                form.get(FORM_FIELDS.TEACHER).touched
            }"
            [value]="form.get(FORM_FIELDS.TEACHER).value?.hash"
            [closeOnSelect]="true"
            [multiple]="false"
            [searchable]="true"
            [hideSelected]="false"
            [floatingLabel]="true"
            (blurEvent)="form.get(FORM_FIELDS.TEACHER).markAsTouched()"
            (selectionChanged)="onSelectionChange($event)">
          </mg-form-select>
          <mg-form-error
            *ngIf="
              form.get(FORM_FIELDS.TEACHER).invalid &&
              form.get(FORM_FIELDS.TEACHER).touched
            ">
            {{ MESSAGES.INPUT_TEACHER_ERROR }}
          </mg-form-error>
        </div>

        <mg-form-select
          *ngIf="activityTypeOptions$ | async as activityTypes"
          [id]="'flextime-activity-create-input-activity-type'"
          [placeholder]="MESSAGES.INPUT_ACTIVITY_TYPE"
          [multiple]="false"
          [searchable]="false"
          [options]="activityTypes"
          [isClearable]="true"
          [control]="form.get(FORM_FIELDS.ACTIVITY_TYPE)"
          [value]="form.get(FORM_FIELDS.ACTIVITY_TYPE).value">
        </mg-form-select>

        <mg-form-text-input
          [id]="'flextime-activity-create-input-location'"
          [label]="MESSAGES.INPUT_LOCATION"
          [control]="form.get(FORM_FIELDS.LOCATION)">
        </mg-form-text-input>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="4px">
            <mg-text variant="body-md">{{
              MESSAGES.INPUT_REGISTRATION_LIMIT
            }}</mg-text>
            <mg-tooltip [label]="MESSAGES.INPUT_REGISTRATION_HINT"></mg-tooltip>
          </div>
          <div>
            <mg-form-text-input
              inputType="number"
              [id]="'flextime-activity-create-click-seats-available'"
              [control]="form.get(FORM_FIELDS.REGISTRATION_LIMIT)"
              [min]="'0'">
            </mg-form-text-input>
          </div>
        </div>

        <mg-form-image-select
          class="image-select"
          [id]="'flextime-activity-create-click-banner'"
          [label]="MESSAGES.INPUT_BANNER"
          [imageList]="imageList$ | async"
          [control]="form.get(FORM_FIELDS.BANNER)"
          (endReached)="endReached($event)"
          (statusChange)="statusChange($event)">
        </mg-form-image-select>

        <div class="divider"></div>

        <mg-form-toggle
          [id]="'flextime-activity-create-click-allow-students'"
          [label]="MESSAGES.INPUT_STUDENTS_TO_REGISTER"
          [control]="form.get(FORM_FIELDS.STUDENTS_TO_REGISTER)">
        </mg-form-toggle>

        <mg-form-restriction-input
          class="restriction-input"
          [id]="'flextime-activity-create-click-restrict'"
          [label]="MESSAGES.INPUT_RESTRICTION_MEMBERSHIP_LIST"
          [membershipListType]="membershipType"
          [value]="form.get(FORM_FIELDS.RESTRICTION_MEMBERSHIP_LIST).value"
          [control]="form.get(FORM_FIELDS.RESTRICTION_MEMBERSHIP_LIST)">
        </mg-form-restriction-input>
      </div>
    </form>
  </main>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    [fxLayoutAlign]="'space-between center'">
    <div>
      <mg-btn
        [id]="'flextime-activity-create-click-cancel'"
        [variant]="(isNew$ | async) ? 'text' : 'destructive'"
        [disabled]="(formState$ | async) !== 'idle'"
        (pressed)="delete()">
        {{
          (isNew$ | async)
            ? MESSAGES.MODAL_CANCEL_BTN
            : (formState$ | async) === 'deleting'
            ? MESSAGES.MODAL_DELETING_BTN
            : MESSAGES.MODAL_DELETE_BTN
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="activity-form"
        variant="filled"
        [id]="'flextime-activity-create-click-create'"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </div>
</ng-template>

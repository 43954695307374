export enum StudentScheduleMessage {
  COLUMN_LABEL_PERIOD = 'Period',
  COLUMN_LABEL_COURSE = 'Course Title',
  COLUMN_LABEL_ROOM = 'Room',
  COLUMN_LABEL_TEACHER = 'Teacher',
  COLUMN_LABEL_DAYS = 'Days',

  DAY_LABEL_MONDAY = 'Mon',
  DAY_LABEL_TUESDAY = 'Tue',
  DAY_LABEL_WEDNESDAY = 'Wed',
  DAY_LABEL_THURSDAY = 'Thu',
  DAY_LABEL_FRIDAY = 'Fri',
  DAY_LABEL_SATURDAY = 'Sat',
  DAY_LABEL_SUNDAY = 'Sun',

  EMPTY_SCHEDULE = "You don't currently have any classes scheduled",
}

export const STUDENT_SCHEDULE_DESKTOP_DISPLAY_COLUMNS = [
  'period',
  'name',
  'location',
  'teachers',
];

export enum StudentScheduleFormFields {
  SEARCH = 'search',
  PERIOD = 'period',
}

/** @todo update default values to null and set to current day of week */
export const STUDENT_SCHEDULE_FORM_GROUP = {
  [StudentScheduleFormFields.SEARCH]: [''],
  [StudentScheduleFormFields.PERIOD]: [null],
};

<ng-container *ngIf="href; else buttonTemplate">
  <a
    class="link"
    [href]="href"
    [target]="target"
    [tabIndex]="tabIndex">
    <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
  </a>
</ng-container>

<ng-template #buttonTemplate>
  <button
    #button
    class="generic-button"
    matTooltipClass="mg-tooltip"
    matRipple
    matRippleColor="rgba(255, 255, 255, .10)"
    [matRippleDisabled]="disabled || loading"
    [ngClass]="classes"
    [disabled]="disabled || loading"
    [attr.form]="attrFormId"
    [attr.type]="attrType"
    [matTooltip]="tooltip"
    [tabIndex]="tabIndex"
    [attr.data-analytics]="id"
    [attr.data-test]="id">
    <!-- File Input -->
    <ng-content select="[fileInput]"></ng-content>
    <!-- Left Icon Container -->
    <div
      class="icon-container"
      *ngIf="icon || loading">
      <!-- Loading Spinner -->
      <mat-spinner
        *ngIf="loading"
        [diameter]="20"
        [color]="variant === 'outlined' ? 'accent' : 'primary'"
        [strokeWidth]="2">
      </mat-spinner>
      <ng-container
        *ngIf="!loading"
        [ngTemplateOutlet]="iconTemplate"
        [ngTemplateOutletContext]="{ $implicit: icon }">
      </ng-container>
    </div>

    <div
      *ngIf="iconSvg"
      class="icon-svg left">
      <img
        [src]="iconSvg"
        alt="icon" />
    </div>

    <!-- Text -->
    <span
      *ngIf="
        this.variant !== 'icon' &&
        this.variant !== 'icon-secondary' &&
        this.variant !== 'icon-destructive'
      "
      fontWeight="700">
      <ng-content></ng-content>
    </span>

    <!-- Right Icon Container -->
    <div
      class="icon-container"
      *ngIf="iconRight">
      <ng-container
        [ngTemplateOutlet]="iconTemplate"
        [ngTemplateOutletContext]="{ $implicit: iconRight }">
      </ng-container>
    </div>
  </button>
</ng-template>

<!-- Icon Template -->
<ng-template
  #iconTemplate
  let-iconVal>
  <ng-container [ngSwitch]="iconSet">
    <mat-icon
      *ngSwitchCase="'material'"
      aria-hidden="false">
      {{ iconVal }}
    </mat-icon>
    <mg-icon
      *ngSwitchDefault
      [iconName]="iconVal">
    </mg-icon>
  </ng-container>
</ng-template>

import { Validators } from '@angular/forms';

import * as day from 'dayjs';

import {
  HallPassErrorCauseExplanation,
  HallPassStatusEnum,
  HallPassStudentCreatedFilter,
  IHallPass,
  IHallPassErrorType,
} from 'minga/domain/hallPass';
import {
  HpmReportsFilter,
  HpmReportsFilters,
  HPM_REPORT_FILTER_DENIED_BY_USER,
} from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { filterPersonEmitterTriggers } from 'src/app/components/manager-report/services/report-filter.service';

import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';
import { SelectFieldsOption } from '@shared/components/select-fields';

import { EditHallPassType } from './hpm-reports.types';

export enum HpmReportsMessages {
  PAGE_TITLE = 'Some title',
  SELECT_LABEL_TYPE = 'Hall pass type',
  SELECT_LABEL_USER_LIST = 'User list',
  SELECT_LABEL_STATUS = 'Status',
  SELECT_LABEL_DENIED_BY = 'Denied by',
  SELECT_LABEL_DENIAL_REASON = 'Denial reason',
  SELECT_OPTION_ALL = 'All',

  BUTTON_LABEL_EXPORT = 'Export list',
  BUTTON_APPLY_FILTER = 'Apply filters',
  BUTTON_CLEAR_FILTER = 'Clear filters',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',

  REPORT_SUMMARY = 'Hall Pass Summary Report',
  REPORT_TYPES = 'Hall Pass Types Report',
  REPORT_CREATORS = 'Staff Report',
  REPORT_USERS = 'Students Report',
  REPORT_HISTORY = 'Hall Pass History Report',
  REPORT_LATE = 'Overdue Hall Pass Report',

  EMPTY_STATE_TITLE = 'No data found',
  EMPTY_STATE_SUBTITLE = 'Not sure what to put here yet.',

  CREATED_BY_LABEL_STUDENT = 'Student created',
  CREATED_BY_LABEL_STAFF = 'Staff created',
  SCHEDULED_REPORT = 'Scheduled Report',

  SEARCH_BY_LABEL = 'Search by',
  SEARCH_BY_LABEL_USER = 'User',
  SEARCH_BY_LABEL_CREATOR = 'Creator',

  BACKEND_ERROR = 'There was an error retrieving the data. Please try again.',

  RECORD_EDIT_TITLE = 'Edit Record',
  RECORD_EDIT_TYPE = 'Pass type',
  RECORD_EDIT_NOTE = 'Note (optional)',
  RECORD_EDIT_START_DATE = 'Start date',
  RECORD_EDIT_END_DATE = 'End date',
  RECORD_EDIT_START_TIME = 'Start time',
  RECORD_EDIT_END_TIME = 'End time',
  RECORD_EDIT_FORM_ERROR = 'Please fix the errors in the form to continue',

  CANCEL_LABEL = 'Cancel',
}

export enum HpmReportsType {
  SUMMARY = 'SUMMARY',
  TYPES = 'TYPES',
  CREATOR = 'CREATOR',
  USER = 'USER',
  HISTORY = 'HISTORY',
  LATE = 'LATE',
}

export enum HpmReportsSortBy {
  TIME = 'totalTime',
  PASSES = 'totalPasses',
}

export const HPM_CREATED_BY_OPTIONS = [
  {
    label: HpmReportsMessages.SELECT_OPTION_ALL,
    value: HallPassStudentCreatedFilter.ALL,
    id: 'report-report-view-click-filter-all',
  },
  {
    label: HpmReportsMessages.CREATED_BY_LABEL_STUDENT,
    value: HallPassStudentCreatedFilter.STUDENT_CREATED,
    id: 'report-report-view-click-filter-student',
  },
  {
    label: HpmReportsMessages.CREATED_BY_LABEL_STAFF,
    value: HallPassStudentCreatedFilter.NON_STUDENT_CREATED,
    id: 'report-report-view-click-filter-staff',
  },
];

export const HPM_REPORT_SELECT_OPTIONS: SelectFieldsOption<ReportTypes>[] = [
  {
    label: HpmReportsMessages.REPORT_SUMMARY,
    value: ReportTypes.HALL_PASS_SUMMARY,
  },
  {
    label: HpmReportsMessages.REPORT_TYPES,
    value: ReportTypes.HALL_PASS_TYPES,
  },
  {
    label: HpmReportsMessages.REPORT_USERS,
    value: ReportTypes.HALL_PASS_STUDENT,
  },
  {
    label: HpmReportsMessages.REPORT_CREATORS,
    value: ReportTypes.HALL_PASS_STAFF,
  },
  {
    label: HpmReportsMessages.REPORT_HISTORY,
    value: ReportTypes.HALL_PASS_HISTORY,
  },
  {
    label: HpmReportsMessages.REPORT_LATE,
    value: ReportTypes.HALL_PASS_OVERDUE,
  },
];

export const HPM_REPORT_STATUS_OPTIONS: SelectFieldsOption<HallPassStatusEnum>[] =
  [
    {
      label: HallPassStatusEnum.APPROVED,
      value: HallPassStatusEnum.APPROVED,
    },
    {
      label: HallPassStatusEnum.DENIED,
      value: HallPassStatusEnum.DENIED,
    },
    {
      label: HallPassStatusEnum.EXPIRED,
      value: HallPassStatusEnum.EXPIRED,
    },
    {
      label: HallPassStatusEnum.OVERRIDDEN,
      value: HallPassStatusEnum.OVERRIDDEN,
    },
    {
      label: HallPassStatusEnum.RESTRICTED,
      value: HallPassStatusEnum.RESTRICTED,
    },
  ];

export const HPM_REPORT_DENIED_BY_OPTIONS = [
  {
    label: HallPassErrorCauseExplanation.USER,
    value: HPM_REPORT_FILTER_DENIED_BY_USER,
  },
  {
    label: HallPassErrorCauseExplanation.BLACK_OUT_WINDOW,
    value: IHallPassErrorType.BLACK_OUT_WINDOW,
  },
  {
    label: HallPassErrorCauseExplanation.NO_PARTY,
    value: IHallPassErrorType.NO_PARTY,
  },
  {
    label: HallPassErrorCauseExplanation.NO_PASS,
    value: IHallPassErrorType.NO_PASS,
  },
  {
    label: HallPassErrorCauseExplanation.MAX_ACTIVE_PASSES,
    value: IHallPassErrorType.MAX_ACTIVE_PASSES,
  },
  {
    label: HallPassErrorCauseExplanation.MAX_HALL_PASS_TYPE_PASSES,
    value: IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES,
  },
  {
    label: HallPassErrorCauseExplanation.NOT_IN_HALL_PASS_TYPE_LIST,
    value: IHallPassErrorType.NOT_IN_HALL_PASS_TYPE_LIST,
  },
  {
    label: HallPassErrorCauseExplanation.NOT_PAST_MIN_WAIT_PERIOD,
    value: IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD,
  },
  {
    label: HallPassErrorCauseExplanation.HALL_PASS_ACTIVE,
    value: IHallPassErrorType.HALL_PASS_ACTIVE,
  },
  {
    label: HallPassErrorCauseExplanation.MAX_PASSES,
    value: IHallPassErrorType.MAX_PASSES,
  },
];

export const HPM_REPORTS_FILTER_INITIAL_STATE: HpmReportsFilters = {
  issuedBy: null,
  issuedTo: null,
  hallpassType: null,
  createdBy: HallPassStudentCreatedFilter.ALL,
  startDate: day().subtract(7, 'd').startOf('day'),
  endDate: day().endOf('day'),
  userList: null,
  status: [],
  deniedBy: [],
  deniedByBlackout: [],
  deniedByNoParty: [],
};

export const HPM_REPORTS_FILTER_NON_ARRAY_TYPES = [
  HpmReportsFilter.START_DATE,
  HpmReportsFilter.END_DATE,
  HpmReportsFilter.CREATED_BY,
  HpmReportsFilter.GROUP_BY,
];

export const HPM_REPORTS_FILTER_PERSON_EMITTER_ACCESSORS: Record<
  string,
  filterPersonEmitterTriggers
> = {
  [HpmReportsFilter.ISSUED_TO]: {
    accessor: 'issuedTo',
    fieldsToReset: [HpmReportsFilter.ISSUED_BY],
  },
  [HpmReportsFilter.ISSUED_BY]: {
    accessor: 'issuedBy',
    fieldsToReset: [HpmReportsFilter.ISSUED_TO],
  },
};

export enum HPM_HALL_PASS_EDIT_FORM_FIELDS {
  TYPE = 'hallPassTypeId',
  NOTE = 'note',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

export const HPM_HALL_PASS_EDIT_FORMGROUP: FormConfig<
  HPM_HALL_PASS_EDIT_FORM_FIELDS,
  EditHallPassType
> = {
  [HPM_HALL_PASS_EDIT_FORM_FIELDS.TYPE]: [null, [Validators.required]],
  [HPM_HALL_PASS_EDIT_FORM_FIELDS.NOTE]: [null],
  [HPM_HALL_PASS_EDIT_FORM_FIELDS.START_DATE]: {
    formControl: [null, [Validators.required]],
    key: 'startDate',
    set: (data: IHallPass) => day(data.startDate),
    get: (formData: any) => {
      const times = formData.startTime.split(':');
      formData.startDate = formData.startDate.set('hour', times[0]);
      formData.startDate = formData.startDate.set('minute', times[1]);
      return formData.startDate.toDate();
    },
  },
  [HPM_HALL_PASS_EDIT_FORM_FIELDS.END_DATE]: {
    formControl: [null, [Validators.required]],
    key: 'endDate',
    set: (data: IHallPass) => day(data.endDate),
    get: (formData: any) => {
      const times = formData.endTime.split(':');
      formData.endDate = formData.endDate.set('hour', times[0]);
      formData.endDate = formData.endDate.set('minute', times[1]);
      return formData.endDate.toDate();
    },
  },
  [HPM_HALL_PASS_EDIT_FORM_FIELDS.START_TIME]: {
    formControl: [null, [Validators.required]],
    key: HPM_HALL_PASS_EDIT_FORM_FIELDS.START_TIME,
    set: (data: IHallPass) => day(data.startDate).format('HH:mm'),
  },
  [HPM_HALL_PASS_EDIT_FORM_FIELDS.END_TIME]: {
    formControl: [null, [Validators.required]],
    key: HPM_HALL_PASS_EDIT_FORM_FIELDS.END_TIME,
    set: (data: IHallPass) => day(data.endDate).format('HH:mm'),
  },
};

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';
import { uniqBy } from 'lodash';
import flatten from 'lodash/flatten';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormLabelBackground } from '../../types';
import {
  CategoryItem,
  OptionItem,
  SelectGroupCategory,
} from './form-grouped-select.types';
import { areEqual } from './form-grouped-select.utils';

enum SelectType {
  CATEGORY = 'category',
  OPTION = 'list',
}

@Component({
  selector: 'mg-form-grouped-select',
  templateUrl: './form-grouped-select.component.html',
  styleUrls: ['./form-grouped-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupedSelectComponent implements OnDestroy, OnInit {
  @ViewChild('select') selectComponent: NgSelectComponent;

  @Input() set options(options: Array<SelectGroupCategory>) {
    if (options) {
      this._setOptions(options);
    }
  }
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input() set value(value: any) {
    this._valueSubj.next(value);
  }
  @Input() disabled: boolean;
  @Input() clearOnSelect = false;
  @Input() placeholder = 'Select an option';
  @Input() loading: boolean;
  @Input() clearable: boolean;
  @Input() multiple: boolean;
  @Input() floatingLabel = true;
  @Input() labelBackground: FormLabelBackground = 'white';
  @Input() lazyLoad = false;
  @Output() selectChange: EventEmitter<OptionItem | OptionItem[] | null> =
    new EventEmitter();
  @Output() categoryChange: EventEmitter<CategoryItem | null> =
    new EventEmitter();

  private _categoryOpenByDefault: SelectGroupCategory;

  private _destroyedSubj = new ReplaySubject<void>(1);
  private _dataTreeSubj = new BehaviorSubject<Record<any, SelectGroupCategory>>(
    {},
  );

  private _valueSubj: BehaviorSubject<any> = new BehaviorSubject(null);
  private _categories$: Observable<CategoryItem[]> = this._dataTreeSubj.pipe(
    takeUntil(this._destroyedSubj),
    map(dataTree => {
      return Object.values(dataTree).map(category => {
        const { items, ...categoryItem } = category;
        return categoryItem;
      });
    }),
  );

  private _options$: Observable<OptionItem[]> = this._dataTreeSubj.pipe(
    takeUntil(this._destroyedSubj),
    map(dataTree => {
      return flatten(
        Object.values(dataTree).map(category => {
          return (category.items || []).map(option => {
            return {
              ...option,
              category: {
                value: category.value,
                label: category.label,
              },
            };
          });
        }),
      );
    }),
  );

  private _activeFilterSubj = new BehaviorSubject<CategoryItem>(null);
  public activeFilter$ = this._activeFilterSubj.asObservable();

  private _selectTypeSubj: BehaviorSubject<SelectType> = new BehaviorSubject(
    SelectType.CATEGORY,
  );
  public selectType$: Observable<SelectType> =
    this._selectTypeSubj.asObservable();

  private readonly _isOpenSubj = new BehaviorSubject<boolean>(false);
  public readonly isOpen$ = this._isOpenSubj.asObservable();

  private _lazyLoadingSubj = new BehaviorSubject<boolean>(false);
  public lazyLoading$ = this._lazyLoadingSubj.asObservable();

  private _userSearchDataLoaded = false;

  private _filteredOptions$: Observable<OptionItem[]> = combineLatest([
    this._options$,
    this.activeFilter$,
  ]).pipe(
    takeUntil(this._destroyedSubj),
    map(([options, filter]) => {
      if (!filter) return options;
      return options.filter(option => option.category.value === filter.value);
    }),
  );

  public options$: Observable<OptionItem[] | CategoryItem[]> = combineLatest([
    this._categories$,
    this._filteredOptions$,
    this.selectType$,
  ]).pipe(
    map(([catgories, options, selectType]) => {
      if (selectType === SelectType.OPTION) {
        return options;
      } else {
        return catgories;
      }
    }),
  );

  public SELECT_TYPE = SelectType;
  public selectedValue: OptionItem;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _snackBar: SystemAlertSnackBarService,
  ) {}

  ngOnInit(): void {
    this._handleValueChange();
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  public groupByFn(item) {
    return item?.category?.value;
  }

  public groupValueFn(groupKey: string, children: OptionItem[]) {
    return {
      label: children?.[0].category.label,
    };
  }

  public trackByFn(item: OptionItem) {
    return item?.value;
  }

  public onSelect(event: OptionItem | OptionItem[] | null, emitChange = true) {
    const value = Array.isArray(event) ? event[0] : event;
    this._setFilter(value ? value : null);
    if (emitChange) {
      this.selectChange.emit(event);
    }
    this.reset();
  }

  public async onSearch(event) {
    if (this.lazyLoad) {
      await this._handleUserSearchDataLoading();
    }

    this._setFilter(null);
    this._selectTypeSubj.next(SelectType.OPTION);
  }

  public onBlur(event) {
    this.reset();
  }

  public async setCategory(category: CategoryItem, emitChange = true) {
    this._setFilter(category);
    this._selectTypeSubj.next(SelectType.OPTION);
    if (emitChange) {
      this.categoryChange.emit(category);
    }

    if (category?.lazyLoad) {
      await this._lazyLoadData(category);
    }
  }

  public reset(forceReset = false) {
    if (this.clearOnSelect || forceReset || !this.selectedValue) {
      this._activeFilterSubj.next(null);
      this.selectedValue = null;
    }

    this._selectTypeSubj.next(SelectType.CATEGORY);
    this._cdr.detectChanges();
  }

  public clear() {
    this.reset(true);
    this.selectChange.emit(null);
  }

  public back() {
    this._setFilter(null);
    this._selectTypeSubj.next(SelectType.CATEGORY);
  }

  public async setOpenState(val: boolean) {
    this._isOpenSubj.next(val);

    if (this._categoryOpenByDefault) {
      this.setCategory(this._categoryOpenByDefault, false);
    }
  }

  private _setFilter(filter) {
    this._activeFilterSubj.next(filter);
  }

  get showLabel() {
    if (!this.floatingLabel) return false;
    if (this._isOpenSubj.value) return true;

    return this._hasValue(this.selectedValue);
  }

  private _setOptions(categories: Array<SelectGroupCategory>) {
    const dataTree = categories.reduce((acc, category) => {
      acc[category.value] = category;
      return acc;
    }, {});
    this._dataTreeSubj.next(dataTree);

    this._categoryOpenByDefault = categories.find(c => c.openByDefault);
  }

  private _handleValueChange() {
    this._valueSubj
      .pipe(
        distinctUntilChanged((prev, next) => {
          return areEqual(this.selectedValue, next);
        }),
        takeUntil(this._destroyedSubj),
        map(changedValue => ({
          changedValue,
          data: this._dataTreeSubj.value,
        })),
      )
      .subscribe(({ changedValue, data }) => {
        const options = flatten(
          Object.values(data).map(category => category.items),
        );

        let value = null;
        if (Array.isArray(changedValue)) {
          value = options.filter(option =>
            changedValue.includes(option.value as any),
          );
          // need to dudupe since entries can live in multiple categories
          value = uniqBy(value, 'value');
        } else {
          value = options.find(
            option => (option.value as any) === changedValue,
          );
        }

        if (!value || value?.length === 0) {
          return this.reset(true);
        }

        this.selectedValue = value;
        const filter = Array.isArray(value)
          ? value[0].category
          : value.category;
        this.setCategory(filter, false);
        this.onSelect(value, false);
      });
  }

  private _hasValue(value: any | any[]): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  }

  private async _lazyLoadData(category: CategoryItem) {
    try {
      this._lazyLoadingSubj.next(true);
      const tree = this._dataTreeSubj.value;
      const categoryData = tree[category.value];

      if (!categoryData?.fetch) {
        throw new Error('Lazy load category does not have fetch method');
      }

      const items = await categoryData.fetch();
      if (items) {
        categoryData.items = [...items];
        this._dataTreeSubj.next({
          ...tree,
          [category.value]: categoryData,
        });
      }
    } catch (error) {
      console.error(error);
      this._snackBar.error(
        `There was an error loading data for ${category.label}`,
      );
    } finally {
      this._lazyLoadingSubj.next(false);
    }
  }

  /**
   * If we're lazy loading data but a user starts typing prior to any data being loaded
   * we need to load the data for the user search
   */
  private async _handleUserSearchDataLoading() {
    if (this._userSearchDataLoaded) return;

    this._userSearchDataLoaded = true;

    const categories = Object.values(this._dataTreeSubj.value);
    const category = categories.find(c => c.usedForUserSearch) || categories[0];

    if (category?.lazyLoad) {
      await this._lazyLoadData(category);
    }

    this._userSearchDataLoaded = true;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnimatedWaveModule } from '@shared/components/animated-wave/animated-wave.module';

import { PlaceholderCardComponent } from './placeholder-card.component';

@NgModule({
  imports: [CommonModule, AnimatedWaveModule],
  declarations: [PlaceholderCardComponent],
  exports: [PlaceholderCardComponent],
})
export class PlaceholderCardModule {}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, from } from 'rxjs';
import { concatMap, filter, map, toArray } from 'rxjs/operators';

import {
  Group,
  MingaGroupMemberRank,
} from 'minga/app/src/app/groups/models/Group';
import { GroupsFacadeService, GroupsService } from 'src/app/groups/services';
import { mgResolveImageUrl } from 'src/app/util/asset';

import { ToolsGroupsMessage } from '@modules/tools/tools.constants';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';

import { ToolsGroupsViewModalComponent } from '../tools-groups-view-modal/tools-groups-view-modal.component';

@Component({
  selector: 'mg-tools-groups',
  templateUrl: './tools-groups.component.html',
  styleUrls: ['./tools-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsGroupsComponent implements OnInit {
  /** Constants */
  public readonly MESSAGES = ToolsGroupsMessage;

  /** Inputs */
  @Input() personHash: string;

  /** Observables */
  public groups$: Observable<Group[]>;
  private _loadingSubject = new BehaviorSubject(false);
  public readonly loading$ = this._loadingSubject.asObservable();

  /** Constructor */
  constructor(
    public mediaService: MediaService,
    private _route: ActivatedRoute,
    private _groupsFacadeService: GroupsFacadeService,
    private _groupsService: GroupsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService,
  ) {}

  ngOnInit(): void {
    this._fetchGroups();
  }

  public openViewModal() {
    this._modalOverlay.open(ToolsGroupsViewModalComponent, {
      data: {
        personHash: this.personHash,
        groups$: this.groups$,
      },
    });
  }

  public getGroupBanner(group: Group) {
    if (!group || (!group.banner && !group.bannerObject)) return '';

    const image = group.bannerObject
      ? group.bannerObject.getImage()
      : group.banner;

    return mgResolveImageUrl(image);
  }

  private async _fetchGroups(): Promise<void> {
    try {
      this._loadingSubject.next(true);
      this.groups$ = this.personHash
        ? this._groupsService.getPersonGroups(this.personHash).pipe(
            concatMap(groups => from(groups)),
            filter(group =>
              [
                MingaGroupMemberRank.MEMBER,
                MingaGroupMemberRank.OWNER,
              ].includes(group.currentMemberRank),
            ),
            toArray(),
            map(groups => groups.sort((a, b) => a.name.localeCompare(b.name))),
          )
        : this._groupsFacadeService.getMyGroupsSortedByActivity();
    } catch (e) {
      this._systemAlertSnackBar.error(ToolsGroupsMessage.ERROR_LOADING_GROUPS);
    } finally {
      this._loadingSubject.next(false);
    }
  }
}

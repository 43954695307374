<mg-overlay-primary
  size="auto"
  overlayTitle="My Stickers">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
</mg-overlay-primary>

<!-- Main content -->
<ng-template #mainTemplate>
  <div class="card-container-body">
    <ng-container *ngFor="let item of stickers$ | async">
      <div class="image-container">
        <mg-image
          *ngIf="getStickerPath(item)"
          class="image"
          background-size="contain"
          [srcs]="
            getStickerPath(item)
              | mgImageUrls
                : ['blurloading1', ['bannerlibpreview', 'cardbanner']]
          ">
        </mg-image>
        <mg-text
          textAlign="center"
          [variant]="(media.isMobileView$ | async) ? 'body-xs' : 'body-sm'"
          [numberOfLines]="2">
          {{ item.name }}
        </mg-text>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #checkboxTmpl>
  <div *ngIf="status != 'CHECKED_IN' || requiresTicket">
    <mat-checkbox
      *ngIf="personUpdatable"
      class="mg-align-center"
      [(ngModel)]="personChecked">
    </mat-checkbox>
  </div>
</ng-template>
<ng-template
  #checkedInTmpl
  let-item="item">
  <div
    [ngClass]="'status--' + item.status"
    class="status"></div>
</ng-template>

<label
  class="close"
  fxLayout="row"
  fxLayoutAlign="start center">
  <mg-button-icon
    icon="close"
    (click)="close()"></mg-button-icon>
  <p>Cancel</p>
</label>

<div
  [ngSwitch]="state"
  class="mg-centered contents"
  fxLayout="column">
  <ng-container *ngSwitchCase="'loading'">
    <div fxFlex></div>
    <mg-spinner></mg-spinner>
    <div fxFlex></div>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <p class="mg-headline notice-msg">An error has occured</p>
    <div fxFlex></div>
    <button
      mgBubbleBtn
      class="small-button"
      (click)="close(true)">
      {{ data.scanAgainText || 'Scan Again' }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'unknown-student'">
    <p class="mg-headline notice-msg">
      Unable to find person with ID: <strong>{{ unknownStudentId }}</strong>
    </p>
    <div fxFlex></div>
    <button
      mgBubbleBtn
      class="small-button"
      (click)="close(true)">
      {{ data.scanAgainText || 'Scan Again' }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'known-student'">
    <ng-container *ngIf="!requiresTicket">
      <ng-container *ngTemplateOutlet="noTicketEvent"></ng-container>
    </ng-container>
    <ng-container *ngIf="requiresTicket && confirmingPersonHashes.length > 1">
      <ng-container
        *ngTemplateOutlet="multiplePeopleTplTicketEvent"></ng-container>
    </ng-container>
    <ng-container *ngIf="requiresTicket && confirmingPersonHashes.length == 1">
      <ng-container
        *ngTemplateOutlet="singlePersonTplTicketEvent"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #noTicketEvent>
  <p
    class="confirm-pretext mg-bodycopy"
    *ngIf="data.confirmPretext">
    {{ data.confirmPretext }}
  </p>
  <div class="divider"></div>
  <h1 class="dialog-title mg-cta">{{ displayData!.displayName }}</h1>
  <div fxFlex></div>
  <button
    mgBubbleBtn
    (click)="confirm()"
    class="small-button"
    *ngIf="canStillUpdate">
    {{ data.confirmText || 'Confirm' }}
  </button>
  <button
    class="cancel"
    mgBubbleBtn
    (click)="close()"
    *ngIf="!canStillUpdate">
    Cancel
  </button>
</ng-template>

<!--
  Select multiple people in the selector for either checkin or checkout
  to get this template to show.
-->
<ng-template #multiplePeopleTplTicketEvent>
  <div
    class="header"
    [class.no-ticket]="headerText === 'MISSING TICKETS'">
    {{ headerText }}
  </div>
  <p class="confirm-pretext mg-bodycopy">
    {{ data.confirmText }}
  </p>
  <div>{{ totalPeopleCount }} people,</div>
  <div>{{ totalGuestCount }} guests,</div>
  <div>{{ totalTicketCount }} tickets</div>
  <div
    class="spacer"
    fxFlex></div>
  <div class="column">
    <mg-generic-button
      *ngIf="canStillUpdate"
      variant="primary"
      colorTheme="blue"
      [isWide]="true"
      (onClick)="confirm()">
      <ng-container
        [ngTemplateOutlet]="buttonTextTmpl"
        [ngTemplateOutletContext]="{ $implicit: totalPeopleCount }">
      </ng-container>
    </mg-generic-button>
    <mg-generic-button
      class="mt-small"
      variant="primary"
      colorTheme="red"
      [isWide]="true"
      (click)="close()">
      Cancel
    </mg-generic-button>
  </div>
</ng-template>

<ng-template #singlePersonTplTicketEvent>
  <div
    class="header"
    [class.no-ticket]="!displayData!.ticket">
    {{ headerText }}
  </div>
  <p
    class="confirm-pretext mg-bodycopy"
    *ngIf="data.confirmPretext">
    {{ data.confirmPretext }}
  </p>
  <div class="divider"></div>
  <div
    fxLayout="row"
    fxLayoutGap="8px"
    fxLayoutAlign="start center">
    <ng-container *ngIf="personUpdatable">
      <ng-container *ngTemplateOutlet="checkboxTmpl"></ng-container>
    </ng-container>
    <h1 class="mg-cta">{{ displayData!.displayName }}</h1>
    <ng-container *ngIf="!personUpdatable">
      <div
        [ngClass]="'status--' + status"
        class="status"></div>
    </ng-container>
  </div>
  <ng-container *ngFor="let guest of guests">
    <div
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="start center">
      <ng-container *ngIf="!guest.canUpdate">
        <h2>{{ guest.name }}</h2>
        &nbsp;
        <ng-container
          [ngTemplateOutlet]="checkedInTmpl"
          [ngTemplateOutletContext]="{ item: guest }">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="guest.canUpdate">
        <div>
          <mat-checkbox
            class="mg-align-center"
            [checked]="isSelected(guest)"
            (change)="toggleGuestSelected(guest)">
          </mat-checkbox>
        </div>
        <h2>{{ guest.name }}</h2>
        &nbsp;
      </ng-container>
    </div>
  </ng-container>
  <div
    class="spacer"
    fxFlex></div>
  <div class="column">
    <mg-generic-button
      *ngIf="showUpdateButton"
      variant="primary"
      colorTheme="blue"
      [isWide]="true"
      (onClick)="confirm()">
      <ng-container
        [ngTemplateOutlet]="buttonTextTmpl"
        [ngTemplateOutletContext]="{ $implicit: selectedCount }">
      </ng-container>
    </mg-generic-button>
    <mg-generic-button
      *ngIf="!showUpdateButton || !displayData!.ticket"
      class="mt-small"
      variant="primary"
      colorTheme="red"
      [isWide]="true"
      (click)="close()">
      Cancel
    </mg-generic-button>
  </div>
</ng-template>

<ng-template
  #buttonTextTmpl
  let-count>
  {{ buttonText }}
  {{ count > 0 ? '(' + count + ')' : '' }}
</ng-template>

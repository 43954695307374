import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { CancelConfirmationDialogComponent } from 'minga/app/src/app/dialog/CancelConfirmation';
import { PeopleAdminManager } from 'minga/proto/gateway/people_ng_grpc_pb';
import { ArchivePeopleBulkRequest } from 'minga/proto/gateway/people_pb';
import { BarcodeScanner } from 'src/app/barcodeScanner';
import { RootService } from 'src/app/minimal/services/RootService';
import { StreamManager } from 'src/app/minimal/services/StreamManager';
import { PeopleUpdated } from 'src/app/store/root/rootActions';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsArchiveService extends PeopleSelectorFormService<'Archive'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _rootService: RootService,
    private _systemModal: SystemAlertModalService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _streamManager: StreamManager,
    private _dialog: MatDialog,
    private _peopleAdminManager: PeopleAdminManager,
    private _store: Store<any>,
  ) {
    super(
      {
        name: 'Archive',
        pageDefinitions: {
          add: {
            submitFn: async () => this.submitArchive(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters, true),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
  }

  public async submitArchive(): Promise<void> {
    const selection = this.selection.getSelection();
    const emails = selection.map(person => person.email);
    if (emails.length) {
      const options = {
        data: {
          title: 'Archive',
          text: `You are about to archive ${emails.length} ${
            emails.length === 1 ? 'person' : 'people'
          }. This action CANNOT be undone.`,
        },
      };
      const dialogRef = this._dialog.open(
        CancelConfirmationDialogComponent,
        options,
      );
      const result = await dialogRef.afterClosed().toPromise();
      if (result) {
        const requestStartedModalRef = await this._systemModal.open({
          heading: 'Archiving Started',
          message: 'Archiving will start shortly, this may take a while',
          modalType: SystemAlertModalType.DEFAULT,
          hangTime: SystemAlertModalHangTime.LONG,
        });
        await requestStartedModalRef.afterClosed().toPromise();
        await this._rootService.addLoadingPromise(this._archivePeople(emails));
        const completeModalRef = await this._systemModal.open({
          heading: 'Success',
          message: 'Archiving complete',
          modalType: SystemAlertModalType.SUCCESS,
          hangTime: SystemAlertModalHangTime.SHORT,
        });
        await completeModalRef.afterClosed().toPromise();
        await this._streamManager.restartStreamIfAvailable('SearchPeople');
      }
    }
  }

  private async _archivePeople(emails: string[]) {
    const request = new ArchivePeopleBulkRequest();
    emails.forEach(email => {
      request.addEmail(email);
    });
    await this._peopleAdminManager.archivePeopleBulk(request).toPromise();
    this._streamManager.restartStreamIfAvailable('AdminPeopleFeed');
    this._store.dispatch(new PeopleUpdated());
  }
}

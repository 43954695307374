import { Injectable } from '@angular/core';

import { AppRuntime, Platform } from './runtime.interface';

@Injectable({ providedIn: 'root' })

/**
 * The default navigator and window parameters are browser implementations.
 * Both need to be replaced during testing because they are not available
 * in the node test environment.
 *
 * TODO: consider replacing navigator with window. Both have information
 *       about device features, eg. window.cordova, navigator.bluetooth,
 *       navigator.connection, navigator.geolocation, maxTouchPoints… etc.
 *       window.navigator
 */
export class AppRuntimeDefault implements AppRuntime {
  constructor(private _navigator: Navigator) {}

  isCordovaApp(platform?: Platform) {
    const isCordova = isCordovaUserAgent(this._navigator.userAgent);
    const isPlatform = platform ? this._isPlatform(platform) : true;

    /**
     * This additional check could be removed. They way the user agent
     * is appended to in cordova/config.xml solves this, but in cases where
     * we still want to match on the user agent of ios, this fixes that.
     */
    const isPlatformMatch =
      platform === 'ios'
        ? isPlatform || /ios/i.test(this._navigator.userAgent)
        : isPlatform;

    return isCordova && isPlatformMatch;
  }

  private _isPlatform(platform: Platform): boolean {
    if (platform === 'ios') {
      return isIosUserAgent(this._navigator.userAgent);
    } else {
      return isAndroidUserAgent(this._navigator.userAgent);
    }
  }

  isBrowserApp(platform?: Platform) {
    const isBrowser = !this.isCordovaApp(platform);
    const isPlatform = platform ? this._isPlatform(platform) : true;
    return isBrowser && isPlatform;
  }

  // TODO:
  // add "hasFeature" for checking device capabilities.
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator
  // (this._window && this._window.cordova !== undefined)
  // Things found in the code that need to be checked before usage:
  // - backgroundMode => plugin
  // - Barcode => plugins.mlkit.barcodeScanner
  // - BrowserTab
  // - Brightness
  // - Camera => plugins.diagnostic.getCameraAuthorizationStatus
}

/**
 * Do not use these functions directly. They are only exported for testing
 * large quantities of user agents. Use the AppRuntime to handle all of your
 * runtime app/device queries.
 *
 * These regexes were directly copied from app/src/config.ts; those regexes
 * could be replaced with these functions.
 */
export const isCordovaUserAgent = (agent: string): boolean => {
  // This regex was modified slightly from the original in app/src/config.ts;
  // brackets were removed.
  const regex = /io\.minga\.app/i;
  return regex.test(agent);
};

export const isAndroidUserAgent = (agent: string): boolean => {
  const regex = /android/i;
  return regex.test(agent);
};

export const isIosUserAgent = (agent: string): boolean => {
  // This regex was modified slightly from the original in app/src/config.ts;
  // ignore case was added.
  const regex = /iPad|iPhone|iPod/i;
  return regex.test(agent);
};

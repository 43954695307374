<mg-manager-overlay-reports-subpage
  [loading]="false"
  [noBottomPadding]="reportType.active === 'pbis_summary'"
  [columnSettingsButton]="reportType.active !== 'pbis_summary'"
  [scheduleReportsButton]="
    (SUPPPORTED_SCHEDULE_TYPES | includes: reportType.active) &&
    (canScheduleReport$ | async)
  "
  [dataService]="dataService$ | async"
  (scheduleReportsPressed)="onScheduleReport()"
  (exportListPressed)="onClickExport()">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media$ | async) !== 'xs' && (media$ | async) !== 'sm';
        else mobileHeaderTemplate
      ">
      <div class="header-container">
        <div class="filters-group">
          <div class="filters-group-row">
            <ng-container *ngTemplateOutlet="reportTypeFieldTemplate">
            </ng-container>
            <ng-container
              *ngIf="!noDateFilterTypes.includes(reportType.active)"
              [ngTemplateOutlet]="datePickerFieldTemplate"></ng-container>
            <ng-container
              *ngTemplateOutlet="personSearchFieldTemplate"></ng-container>
          </div>
          <div class="filters-group-row">
            <ng-container *ngTemplateOutlet="gradeFieldTemplate"></ng-container>
            <ng-container
              *ngIf="
                reportType.active !== autoCounterType;
                else automationTypeFieldTemplate
              ">
            </ng-container>
            <ng-container
              *ngIf="
                !consequenceReports.includes(reportType.active) &&
                reportType.active !== autoCounterType
              "
              [ngTemplateOutlet]="behaviorTypeFieldTemplate">
            </ng-container>
            <ng-container
              *ngIf="consequenceReports.includes(reportType.active)"
              [ngTemplateOutlet]="consequenceFieldTemplate">
            </ng-container>
            <ng-container
              *ngIf="reportType.active === consHistType"
              [ngTemplateOutlet]="consequenceIDFieldTemplate">
            </ng-container>
            <ng-container
              *ngIf="reportType.active === consHistType"
              [ngTemplateOutlet]="consequenceCategoryTemplate"></ng-container>
            <ng-container
              *ngIf="reportType.active === consHistType"
              [ngTemplateOutlet]="consequenceTypeTemplate"></ng-container>
            <ng-container
              *ngIf="reportType.active === consHistType"
              [ngTemplateOutlet]="consequenceStatusTemplate"></ng-container>
            <ng-container
              *ngIf="reportType.active === consHistType"
              [ngTemplateOutlet]="consequenceAutomationGroupTemplate">
            </ng-container>
            <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
            <ng-container
              [ngTemplateOutlet]="filterButtonTemplate"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Field Template -->
<ng-template #reportTypeFieldTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="335px">
    <mg-form-select
      placeholder="Report Type"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="reportType.active"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="reportType.options"
      [floatingLabel]="false"
      (selectionChanged)="onChangeReportType($any($event))">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Field Template -->
<ng-template #datePickerFieldTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- Person Search Field Template -->
<ng-template #personSearchFieldTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="300px">
    <mg-multi-person-search
      [id]="'report-report-view-click-filter-search'"
      (selectionChanged)="onPersonSelected($any($event))">
    </mg-multi-person-search>
  </div>
</ng-template>

<!-- Grade Field Template -->
<ng-template #gradeFieldTemplate>
  <div class="mobile-filter-row">
    <mg-form-select
      labelBackground="white"
      [id]="'report-report-view-click-filter-grade'"
      [placeholder]="MESSAGES.SELECT_LABEL_GRADE"
      [value]="grades$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [isFullWidth]="true"
      [searchable]="true"
      [hideSelected]="false"
      [enableMobileSheet]="true"
      [options]="gradeOptions$ | async"
      (selectionChanged)="onChangeGrade($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Automation Type Field Template -->
<ng-template #automationTypeFieldTemplate>
  <div
    fxFlex.gt-sm="200px"
    fxFlex="noshrink"
    class="mobile-filter-row">
    <mg-form-select
      labelBackground="white"
      [id]="'report-report-view-click-filter-automation'"
      [placeholder]="MESSAGES.SELECT_OPTION_AUTO_GROUP"
      [value]="automationTypes$ | async"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="true"
      [options]="automationTypeOptions$ | async"
      (selectionChanged)="onAutomationTypeChange($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Behavior Type Field Template -->
<ng-template #behaviorTypeFieldTemplate>
  <div>
    <mg-form-select
      labelBackground="white"
      [id]="'report-report-view-click-filter-behavior'"
      [placeholder]="MESSAGES.SELECT_LABEL_TYPE"
      [value]="behaviorTypes$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="typesOptions$ | async"
      (selectionChanged)="onChangeType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Consequence Field Template -->
<ng-template #consequenceFieldTemplate>
  <div class="mobile-filter-row">
    <mg-form-select
      [placeholder]="MESSAGES.SELECT_LABEL_CONS_TYPE"
      labelBackground="white"
      [value]="consequenceTypes$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [isFullWidth]="true"
      [searchable]="true"
      [options]="consTypesOptions$ | async"
      (selectionChanged)="onChangeType($event, true)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Consequence ID Field -->
<ng-template #consequenceIDFieldTemplate>
  <div class="mobile-filter-row">
    <mg-form-text-input
      width="large"
      iconRight="mg-search"
      [id]="'report-report-view-click-filter-csq-id'"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [label]="MESSAGES.LABEL_REPORT_CONS_ID"
      [control]="consequenceIDFC">
    </mg-form-text-input>
  </div>
</ng-template>

<!-- Consequence Type Template -->
<ng-template #consequenceTypeTemplate>
  <div class="mobile-filter-row">
    <mg-form-select
      placeholder="Type"
      labelBackground="white"
      [value]="consCatTypes$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [isFullWidth]="true"
      [searchable]="true"
      [options]="consCatTypes"
      (selectionChanged)="onChangeCatType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Consequence Status Template -->
<ng-template #consequenceStatusTemplate>
  <div class="mobile-filter-row">
    <mg-form-select
      placeholder="Status"
      labelBackground="white"
      [id]="'report-report-view-click-filter-status'"
      [value]="consStatus$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [isFullWidth]="true"
      [searchable]="true"
      [options]="consStatusOptions"
      (selectionChanged)="onChangeConsStatus($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Consequence Automation Group Template -->
<ng-template #consequenceAutomationGroupTemplate>
  <div
    fxFlex.gt-sm="200px"
    fxFlex="noshrink"
    class="mobile-filter-row">
    <mg-form-select
      placeholder="Automation group"
      labelBackground="white"
      [id]="'report-report-view-click-filter-automation'"
      [value]="automationGroup$ | async"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="true"
      [options]="automationGroupOptions$ | async"
      [isClearable]="true"
      (selectionChanged)="onAutomationGroupChange($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #userListTemplate>
  <div class="half-width">
    <mg-user-list-filter
      [id]="'report-report-view-click-filter-userlist'"
      [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
      [value]="userList$ | async"
      (selectChanged)="onChangeUserList($any($event))">
    </mg-user-list-filter>
  </div>
</ng-template>

<!-- Consequence Category Template -->
<ng-template #consequenceCategoryTemplate>
  <div
    fxFlex.gt-sm="150px"
    fxFlex="noshrink"
    class="mobile-filter-row">
    <mg-form-select
      placeholder="Category"
      labelBackground="white"
      [id]="'report-report-view-click-filter-cat'"
      [value]="consCategories$ | async"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="true"
      [options]="consCat"
      (selectionChanged)="onConsequenceCategoryChange($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonTemplate>
  <div
    fxFlex="noshrink"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="10px">
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-apply'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'filled'
            : 'outlined'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="applyFilter()">
        {{ MESSAGES.BUTTON_APPLY_FILTER }}
      </mg-btn>
    </div>
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-clear'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'outlined'
            : 'text'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="clearFilter()">
        {{ MESSAGES.BUTTON_CLEAR_FILTER }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="header-container">
    <div class="filters-group">
      <div class="filters-group-row">
        <ng-container *ngTemplateOutlet="reportTypeFieldTemplate">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="datePickerFieldTemplate"></ng-container>
        <ng-container
          *ngTemplateOutlet="personSearchFieldTemplate"></ng-container>
      </div>
      <mg-collapsible
        [collapseText]="MESSAGES.HIDE_FILTERS"
        [expandText]="MESSAGES.SHOW_FILTERS">
        <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="gradeFieldTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active !== autoCounterType;
              else automationTypeFieldTemplate
            ">
          </ng-container>
          <ng-container
            *ngIf="
              !consequenceReports.includes(reportType.active) &&
              reportType.active !== autoCounterType
            "
            [ngTemplateOutlet]="behaviorTypeFieldTemplate"></ng-container>
          <ng-container
            *ngIf="consequenceReports.includes(reportType.active)"
            [ngTemplateOutlet]="consequenceFieldTemplate"></ng-container>
          <ng-container
            *ngIf="consequenceReports.includes(reportType.active)"
            [ngTemplateOutlet]="consequenceIDFieldTemplate">
          </ng-container>
          <ng-container
            *ngIf="reportType.active === consHistType"
            [ngTemplateOutlet]="consequenceTypeTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active === consHistType"
            [ngTemplateOutlet]="consequenceStatusTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active === consHistType"
            [ngTemplateOutlet]="consequenceAutomationGroupTemplate">
          </ng-container>
          <ng-container
            *ngIf="reportType.active === consHistType"
            [ngTemplateOutlet]="consequenceCategoryTemplate">
          </ng-container>
          <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
        </div>
      </mg-collapsible>
      <div>
        <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

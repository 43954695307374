import { Component, Input } from '@angular/core';

import {
  BorderRadius,
  Height,
} from '../../skeleton/placeholder-skeleton.types';
import { Shape, Size } from './placeholder-avatar.types';

@Component({
  selector: 'mg-placeholder-avatar',
  template: `
    <mg-placeholder-skeleton
      [height]="height"
      [borderRadius]="borderRadius"
      aspectRatio="square">
    </mg-placeholder-skeleton>
  `,
})

//
export class PlaceholderAvatarComponent {
  @Input() size: Size = 'medium';
  @Input() shape: Shape = 'circle';

  get height() {
    const sizeLookup: Record<Size, Height> = {
      small: '30',
      medium: '56',
      large: '96',
    };
    return sizeLookup[this.size];
  }

  get borderRadius() {
    const radiusLookup: Record<Size, BorderRadius> = {
      small: 'small',
      medium: 'medium',
      large: 'large',
    };
    return this.shape === 'circle' ? 'full' : radiusLookup[this.size];
  }
}

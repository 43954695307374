import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from 'minga/app/src/app/components/MgStreamScroller';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgIconModule } from 'minga/app/src/app/icon';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { FormModule } from '@shared/components/form';
import { TextModule } from '@shared/components/text/text.module';

import { GenericModule } from '../../../shared/components/generic/generic.module';
import { PointsLeaderboardComponent } from './PointsLeaderboard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgStreamScrollerModule,
    MgIconModule,
    MgPipesModule,
    MgContentLinkModule,
    PermissionsModule,
    MgEmptyStateModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    MgSpinnerModule,
    GenericButtonModule,
    FormModule,
    TextModule,

    // External dependencies
    CommonModule,
    TranslateModule,
    MatRippleModule,
    FlexLayoutModule,
    RouterModule,
    GenericModule,
  ],
  declarations: [PointsLeaderboardComponent],
  exports: [PointsLeaderboardComponent],
})
export class PointsLeaderboardModule {}

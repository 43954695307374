import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AnalyticsService } from 'src/app/minimal/services/Analytics';

@Component({
  selector: 'mg-tt-root',
  templateUrl: './tt-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tt-root.component.scss'],
})
export class TtRootComponent {
  constructor(private _analytics: AnalyticsService) {
    this._analytics.logEvent('teacherToolsOpened', {});
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { Person } from 'minga/app/src/app/people';

import { FormSelectComponent } from '@shared/components/form';

import { FiltersFormPopoverMessage } from '../../constants';
import { FiltersFormData } from '../../types';

@Component({
  selector: 'mg-filters-form-popover',
  templateUrl: './filters-form-popover.component.html',
  styleUrls: ['./filters-form-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormPopoverComponent implements OnInit, OnDestroy {
  // Children

  @ViewChildren('formSelectComponent')
  formSelectComponent: QueryList<FormSelectComponent>;

  // Constants

  public readonly MESSAGE = FiltersFormPopoverMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  private readonly _draftFormDataSubject = new BehaviorSubject<any>(null);
  public readonly draftFormData$ = this._draftFormDataSubject.asObservable();

  // Inputs

  @Input() data: FiltersFormData;
  @Input() formGroup: FormGroup;
  @Input() fields: string[];

  // Outputs

  @Output() closePopover = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  /** Component constructor */
  constructor() {}

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(val => {
      const draftFormData = this._draftFormDataSubject.getValue();
      this._draftFormDataSubject.next({ ...draftFormData, ...val });
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.resetForm.complete();
    this.closePopover.complete();
  }

  public clear() {
    this.formSelectComponent.toArray().forEach(component => {
      component.setInitialValue(null);
    });
    this.resetForm.emit();
    this.closePopover.emit();
  }

  public submit() {
    const draftFormData = this._draftFormDataSubject.getValue();
    this.formGroup.patchValue(draftFormData);
    this.closePopover.emit();
  }

  public setSelect(field: string, value: any) {
    this._setDraftFormData(field, value);
  }

  public setToggleGroupValue(field: string, { value }: MatButtonToggleChange) {
    this._setDraftFormData(field, value);
  }

  public setUserList(field: string, value: number | number[]) {
    this._setDraftFormData(field, value);
  }

  public setPerson(field: string, value: Partial<Person>[]) {
    const hashes = value.map(person => person.hash);
    this._setDraftFormData(field, hashes);
  }

  private _setDraftFormData(field: string, value: any) {
    const draftFormData = this._draftFormDataSubject.getValue();
    this._draftFormDataSubject.next({ ...draftFormData, [field]: value });
  }
}

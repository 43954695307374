export enum ToolsMessages {
  TEACHER_TOOLS_PAGE_TITLE = 'Teacher Tools',
  STUDENT_TOOLS_PAGE_TITLE = 'Student Tools',

  STICKER_NO_NEW_CONTENT = "You don't have any stickers",
  GROUP_NO_NEW_CONTENT = "You haven't joined any groups",

  // Your Points
  EMPTY_POINTS = 'You have not earned any points yet!',

  PAGE_HEADER_TITLE = 'My Tools',
  PAGE_TITLE_DASHBOARD = 'Dashboard',
  PAGE_TITLE_POINTS = 'Points',
  PAGE_TITLE_ONLY_BEHAVIORS = 'Behaviors',
  PAGE_TITLE_FLEX_TIME = 'FlexTime',
  PAGE_TITLE_NOTIFICATIONS = 'Notifications',
  PAGE_TITLE_PREFENCES = 'Preferences',
}

export enum ToolsStickersMessage {
  SEE_ALL_LABEL = 'See all',

  EMPTY_STATE_TITLE = "You don't have any stickers yet",
  EMPTY_STATE_SUBTITLE = 'Assigned stickers will be visible here',

  ERROR_LOADING_STICKERS = 'There was an error loading stickers',
}

export enum ToolsGroupsMessage {
  SEE_ALL_LABEL = 'See all',

  EMPTY_STATE_TITLE = "You aren't a member of any groups yet",
  EMPTY_STATE_SUBTITLE = "Groups you're a member of will be visible here",

  ERROR_LOADING_GROUPS = 'There was an error loading groups',
}

import { InjectionToken } from '@angular/core';

/** Define the interface token for the dependency injection provider. */
export const AppRuntimeInterface = new InjectionToken<AppRuntime>('AppRuntime');

export type Platform = 'ios' | 'android';

/**
 * AppRuntime - get information about the user's runtime environment, such as
 * their device and/or browser.
 *
 * This should only be used for platform feature queries, and not screen size
 * (resolution) queries, eg. don't use this for determining style rules. It
 * should be used for determining whether to show/hide, or use alternate
 * solutions for feature implementation based on the client platform.
 *
 * For example, Cordova requires a specific plugin to support file downloads,
 * where as web desktop and mobile should be given the default solution.
 */
export interface AppRuntime {
  /**
   * Check if the app is a cordova build. We often need very different
   * solutions for Cordova specifically, regardless of iOS or Android.
   * Optionally you can also check what platform.
   */
  isCordovaApp(platform?: Platform): boolean;

  /**
   * Check if the app is running in a browser, and optionally check what
   * platform.
   */
  isBrowserApp(platform?: Platform): boolean;

  /**
   * TODO:
   * Check if the device or browser has a specific feature. eg.
   * camera | home button | DownloadManager | drag and drop
   *
   * hasFeature(feature: Feature): boolean;
   *
   * TODO:
   * Future methods:
   * getDeviceInfo(): DeviceInfo | undefined;
   *  - { cordovaVersion, platform, uuid, version, manufacturer, isVirtual, serial}
   *  - devicePixelRatio
   * getBrowserInfo() ???
   * hasPermissions ???
   * - permissionStatus: Granted | denied | not_determined
   */
}

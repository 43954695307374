import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ReportTypes } from 'minga/domain/reportTypes';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { BehaviorManagerRoutes } from '@modules/behavior-manager';
import { CheckinManagerRoutes } from '@modules/checkin-manager';
import { HpmRoutes } from '@modules/hallpass-manager';
import { MingaManagerRoutes } from '@modules/minga-manager';
import { PeopleViewProfilePerson } from '@modules/people/components/people-view-profile/types';
import { PointsManagerRoutes } from '@modules/points-manager';
import { ToolsActionsService } from '@modules/tools/services/tools.actions.service';
import { countActiveStatViews } from '@modules/tools/shared-tools/components/tools-stats-placeholder/tools-stats-placeholder.component';
import {
  ACTION_CATEGORY,
  ACTION_KEY,
} from '@modules/tools/shared-tools/types/shared-tools.types';
import { ToolsRoutes } from '@modules/tools/tools.types';

import { FormSelectOption } from '@shared/components/form';
import { StatsService } from '@shared/services/stats/stats.service';
import { MingaStats } from '@shared/services/stats/stats.types';
import { getQueryParamsFromDateRange, QueryParamKey } from '@shared/utils';

import {
  AdminToolsMessages,
  StatRangeKey,
  STAT_RANGE_PRESET,
  STATS_RANGE_PRESETS,
} from '../../admin-tools.constant';

@Component({
  selector: 'mg-at-my-tools',
  templateUrl: './at-my-tools.component.html',
  styleUrls: ['./at-my-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ToolsActionsService],
})
export class AtMyToolsComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = AdminToolsMessages;
  public readonly BEHAVIOR_MANAGER_ROUTES = BehaviorManagerRoutes;
  public readonly CHECKIN_MANAGER_ROUTES = CheckinManagerRoutes;
  public readonly POINTS_MANAGER_ROUTES = PointsManagerRoutes;
  public readonly MINGA_MANAGER_ROUTES = MingaManagerRoutes;
  public readonly REPORT_TYPES = ReportTypes;
  public readonly TOOLS_ROUTES = ToolsRoutes;
  public readonly HPM_ROUTES = HpmRoutes;

  /** Observables */
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _loadingStatsSubject = new BehaviorSubject<boolean>(true);
  public loadingStats$ = this._loadingStatsSubject.asObservable();

  private _mingaStatsSubject = new BehaviorSubject<MingaStats>(null);
  public mingaStats$ = this._mingaStatsSubject.asObservable();

  public dateRangeControl = new FormControl(StatRangeKey.LAST_7_DAYS);
  public dateRangeOptions: FormSelectOption<string>[] = STATS_RANGE_PRESETS;

  /** Observables */
  public readonly isHallpassEnabled$ =
    this._mingaSettings.isHallPassModuleEnabled();
  public readonly isCheckinEnabled$ =
    this._mingaSettings.isCheckinModuleEnabled();
  public readonly isBehaviorEnabled$ =
    this._mingaSettings.isPbisModuleEnabled();
  public isGroupsEnabled$ = this._mingaSettings.isCommunityModuleEnabled();

  /**
   * Get the quantity of stats that will be displayed in the view. Used for determining
   * the placeholder layout. The stats in this list should match the stats found in the
   * view template.
   *
   * Ideally the individual stats cards would emit events for their loading status,
   * and the placeholders would be part of those components.
   */
  public readonly totalStatsCount$: Observable<number> = combineLatest([
    this.isHallpassEnabled$,
    this.isCheckinEnabled$,
    this.isBehaviorEnabled$,
  ]).pipe(
    map(stats => {
      // The other stats is currently only activeUserCount.
      const otherStatsCount = 1;
      const statViewCounts = {
        0: 1, // Hall Pass
        1: 1, // Checkin
        2: 2, // Behavior
      };

      return countActiveStatViews(stats, statViewCounts) + otherStatsCount;
    }),
  );

  public assignActions = this._toolsActions.actions
    .filter(a => a.usedIn.includes('admin'))
    .filter(a =>
      [
        ACTION_CATEGORY.HALL_PASS,
        ACTION_CATEGORY.CHECK_IN,
        ACTION_CATEGORY.ACTIVITY,
        ACTION_CATEGORY.PBIS,
      ].includes(a.category),
    );

  public hasAssignActions$: Observable<boolean> = combineLatest(
    this.assignActions.map(action => action.hasPermission$),
  ).pipe(map(permissions => permissions.some(hasPermission => hasPermission)));

  public pointsActions = this._toolsActions.actions
    .filter(a => a.usedIn.includes('admin'))
    .filter(a => a.category === ACTION_CATEGORY.POINTS);

  public hasPointsActions$: Observable<boolean> = combineLatest(
    this.pointsActions.map(action => action.hasPermission$),
  ).pipe(map(permissions => permissions.some(hasPermission => hasPermission)));

  public kioskAction = this._toolsActions.actions.find(
    a => a.key === ACTION_KEY.KIOSK_MODE,
  );

  public person$: Observable<PeopleViewProfilePerson> =
    this._authInfoService.authPerson$.pipe(
      map(p => {
        if (!p) return null;

        return {
          hash: p.hash,
          badgeRoleName: p.badgeRoleName,
          firstName: p.firstName,
          lastName: p.lastName,
          profileImageUrl: p.avatarUrl,
          grade: p.grade,
          studentId: p.studentId,
        };
      }),
    );
  constructor(
    private _mingaSettings: MingaSettingsService,
    private _authInfoService: AuthInfoService,
    private _statsService: StatsService,
    private _toolsActions: ToolsActionsService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._route.queryParams
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(params => {
        const statRangeKey = params[QueryParamKey.STAT_RANGE_PRESET];
        if (statRangeKey) {
          this.dateRangeControl.setValue(statRangeKey, { emitEvent: false });
        }
        this._getMingaStats(this.dateRangeControl.value);
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public handleStatNavigate(route: string[]) {
    const preset = this._getPreset(this.dateRangeControl.value);
    const range = preset.getRange();
    const queryParams = getQueryParamsFromDateRange(range);
    this._router.navigate(route, {
      queryParams,
    });
  }

  public handleDateRangeChange(statRangeKey: StatRangeKey) {
    this._updateToolsUrl(statRangeKey);
  }

  private async _getMingaStats(statRangeKey: StatRangeKey) {
    try {
      this._loadingStatsSubject.next(true);

      const preset = this._getPreset(statRangeKey);
      const range = preset.getRange();

      const stats = await this._statsService.getMingaStats(range);
      this._mingaStatsSubject.next(stats);
    } catch (e) {
    } finally {
      this._loadingStatsSubject.next(false);
    }
  }

  private _getPreset(statRangeKey: StatRangeKey): STAT_RANGE_PRESET {
    return STATS_RANGE_PRESETS.find(p => p.value === statRangeKey);
  }

  private _updateToolsUrl(statRangeKey: StatRangeKey): void {
    this._router.navigate([], {
      queryParams: {
        [QueryParamKey.STAT_RANGE_PRESET]: statRangeKey,
      },
      replaceUrl: true,
    });
  }
}

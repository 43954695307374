<ng-container *ngIf="isDisabled">
  <span
    class="number"
    matRipple
    [class.selected]="true">
    {{ formControl.value }}
  </span>
</ng-container>

<ng-container *ngIf="!customAmount && !isDisabled">
  <span
    *ngFor="let number of availableDefaultNumbers; let index = index"
    class="number"
    matRipple
    [class.selected]="selectedIndex == index"
    [attr.data-analytics]="id"
    [attr.data-test]="id"
    (click)="selectDurationValue(index)">
    {{ number }}
  </span>
  <mat-icon
    class="more-ellipsis-icon"
    [attr.data-analytics]="id"
    [attr.data-test]="id"
    (click)="toggleCustomAmount()">
    more_horiz
  </mat-icon>
</ng-container>

<ng-container
  *ngIf="customAmount"
  [formGroup]="formGroupInstance"
  [attr.data-analytics]="id"
  [attr.data-test]="id">
  <mg-icon
    class="cancel-custom-icon"
    matRipple
    [attr.data-analytics]="id"
    [attr.data-test]="id"
    (click)="toggleCustomAmount()">
    left-arrow-active
  </mg-icon>
  <mat-form-field
    appearance="standard"
    class="no-material custom-input">
    <input
      matInput
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      name="numberInput"
      autocomplete="off"
      placeholder="Duration (mins)"
      [formControlName]="formControlIdentifier"
      [maxLength]="3"
      (input)="onCustomInput($event)" />
  </mat-form-field>
</ng-container>

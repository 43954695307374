import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';

import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { SelectElementModule } from '@shared/components/select-element';
import { UserListFilterModule } from '@shared/components/user-list-filter';
import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { FiltersFormPersonSearchComponent } from './components/filters-form-person-search/filters-form-person-search.component';
import { FiltersFormPopoverComponent } from './components/filters-form-popover/filters-form-popover.component';
import { FiltersFormSheetComponent } from './components/filters-form-sheet/filters-form-sheet.component';
import { FiltersFormUserListsComponent } from './components/filters-form-user-lists/filters-form-user-lists.component';
import { FiltersFormComponent } from './filters-form.component';

@NgModule({
  imports: [
    // Minga dependencies
    UserListFilterModule,
    MultiPersonSearchModule,
    SelectElementModule,

    // Common dependencies
    CommonUIModules,
    CommonFormModules,

    // External dependencies
    OverlayModule,
  ],
  exports: [FiltersFormComponent],
  declarations: [
    FiltersFormComponent,
    FiltersFormPopoverComponent,
    FiltersFormSheetComponent,
    FiltersFormPersonSearchComponent,
    FiltersFormUserListsComponent,
  ],
})
export class FiltersFormModule {}

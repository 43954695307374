import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';

import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgStreamScrollerModule } from 'src/app/components/MgStreamScroller';
import { PointsHistoryItemModule } from 'src/app/components/PointsHistoryItem';
import { MgDateSeparatorModule } from 'src/app/elements/MgDateSeparator';
import { MgIconModule } from 'src/app/icon';
import { PermissionsModule } from 'src/app/permissions';
import { PointsLeaderboardModule } from 'src/app/routes/points/Leaderboard';
import { MgSpinnerModule } from 'src/app/spinner';

import { TeacherToolsModule } from '@modules/teacher-tools';
import { MyClassActionsService } from '@modules/teacher-tools/components/tt-my-class/services/my-class-actions.service';

import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { PointsRewardsListModule } from '@shared/components/points-rewards-list';
import { ProfilePointsModule } from '@shared/components/profile-points';
import { StudentScheduleModule } from '@shared/components/student-schedule/student-schedule.module';
import { TextModule } from '@shared/components/text/text.module';

import { AdminToolsModule } from './admin-tools/admin-tools.module';
import { ToolsBehavioursComponent } from './components/tools-behaviours/tools-behaviours.component';
import { ToolsFlexTimeComponent } from './components/tools-flex-time/tools-flex-time.component';
import { ToolsMyToolsComponent } from './components/tools-my-tools/tools-my-tools.component';
import { ToolsLeaderboardComponent } from './components/tools-points/components/tools-leaderboard/tools-leaderboard.component';
import { ToolsRewardsComponent } from './components/tools-points/components/tools-rewards/tools-rewards.component';
import { ToolsYourPointsComponent } from './components/tools-points/components/tools-your-points/tools-your-points.component';
import { ToolsPointsComponent } from './components/tools-points/tools-points.component';
import { ToolsRootComponent } from './components/tools-root/tools-root.component';
import { ToolsScheduleComponent } from './components/tools-schedule/tools-schedule.component';
import { CanAccessBehavioursGuard } from './guards/CanAccessBehaviours.guard';
import { CanAccessFlexTimeGuard } from './guards/CanAccessFlexTime';
import { CanAccessPointsGuard } from './guards/CanAccessPoints.guard';
import { ToolsService } from './services/tools.service';
import { SharedToolsModule } from './shared-tools/shared-tools.module';
import { StudentToolsModule } from './student-tools/student-tools.module';

@NgModule({
  declarations: [
    ToolsRootComponent,
    ToolsMyToolsComponent,
    ToolsBehavioursComponent,
    ToolsPointsComponent,
    ToolsYourPointsComponent,
    ToolsRewardsComponent,
    ToolsLeaderboardComponent,
    ToolsFlexTimeComponent,
    ToolsScheduleComponent,
  ],
  imports: [
    CommonModule,
    TeacherToolsModule,
    SharedToolsModule,
    StudentToolsModule,
    AdminToolsModule,
    MgIconModule,
    MgSpinnerModule,
    TextModule,
    PermissionsModule,
    PointsRewardsListModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    MgStreamScrollerModule,
    MgEmptyStateModule,
    MgDateSeparatorModule,
    ProfilePointsModule,
    PointsHistoryItemModule,
    PointsLeaderboardModule,
    RouterModule,
    ManagerOverlayModule,
    StudentScheduleModule,
  ],
  exports: [],
  providers: [
    ToolsService,
    CanAccessBehavioursGuard,
    CanAccessPointsGuard,
    CanAccessFlexTimeGuard,
    MyClassActionsService,
  ],
})
export class ToolsModule {}

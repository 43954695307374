<mg-overlay-primary
  size="custom"
  [isLoading]="false"
  [overlayTitle]="eventTitle + ' Report'"
  [skipLoadCheck]="true">
  <ng-container [ngTemplateOutlet]="reportFeatures"></ng-container>
  <ng-container [ngTemplateOutlet]="table"></ng-container>
</mg-overlay-primary>

<ng-template #reportFeatures>
  <div class="reportFeatures">
    <div class="half-width">
      <mg-form-select
        appendTo="body"
        placeholder="Status"
        [value]="eventStatus.active"
        [multiple]="false"
        [searchable]="false"
        [options]="eventStatus.options"
        (selectionChanged)="onChangeEventStatus($event)">
      </mg-form-select>
    </div>
    <div class="export-button">
      <mg-btn
        *ngIf="canDownloadExport"
        variant="text"
        size="small"
        iconSet="minga"
        [icon]="'mg-export'"
        (pressed)="exportReport()">
        Export
      </mg-btn>
    </div>
  </div>
</ng-template>

<ng-template #table>
  <mg-report-table
    matSort
    [dataSourceService]="ds"
    (matSortChange)="ds.sort($event)">
  </mg-report-table>
</ng-template>

<mg-overlay-primary
  size="auto"
  overlayTitle="My Groups">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
</mg-overlay-primary>

<!-- Main content -->
<ng-template #mainTemplate>
  <div class="card-container-body">
    <ng-container *ngFor="let item of groups$ | async">
      <button
        class="button-wrapper"
        (click)="viewGroup(item.hash)">
        <div class="image-container">
          <div
            class="image"
            [style.background-image]="
              'url(' + getGroupBanner(item) + ')'
            "></div>
          <mg-text
            textAlign="center"
            [variant]="(media.isMobileView$ | async) ? 'body-xs' : 'body-sm'"
            [numberOfLines]="2">
            {{ item.name }}
          </mg-text>
        </div>
      </button>
    </ng-container>
  </div>
</ng-template>

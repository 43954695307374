<!-- Trigger button container-->
<div #triggerButton>
  <mg-btn
    variant="outlined"
    size="medium"
    iconSet="minga"
    [icon]="(appliedFiltersCount$ | async) > 0 ? 'mg-filter-on' : 'mg-filter'"
    [wide]="false"
    [canGrow]="false"
    [active]="overlay.isOpen$ | async"
    (pressed)="open()">
    {{ MESSAGE.BUTTON_LABEL_FILTERS }}
    <!-- Active filters count -->
    <ng-container *ngIf="appliedFiltersCount$ | async as appliedFiltersCount">
      ({{ appliedFiltersCount }})
    </ng-container>
  </mg-btn>
</div>

import { SelectionModel } from '@angular/cdk/collections';

import { SelectElementOption } from '../types';

export interface SelectElementSelectionModelData {
  multiple: boolean;
  initialSelection: SelectElementOption[];
}

export class SelectElementSelectionModel {
  // State

  private _model = new SelectionModel<SelectElementOption>(false, []);

  // Computed getters

  public get size() {
    return this._model.selected.length;
  }

  public get selected() {
    return this._model?.selected;
  }

  public get selected$() {
    return this._model.changed;
  }

  /** Utility constructor */
  constructor() {}

  public init({ initialSelection, multiple }: SelectElementSelectionModelData) {
    this._model = new SelectionModel<SelectElementOption>(
      multiple,
      initialSelection,
    );
  }

  public isSelected(option: SelectElementOption) {
    return this._model?.isSelected(option);
  }

  public toggle(option: SelectElementOption) {
    this._model.toggle(option);
  }

  public clear() {
    this._model.clear();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { MingaSettingsService } from 'minga/app/src/app/store/Minga/services';
import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PermissionsService } from 'src/app/permissions';
import { HallPassService } from 'src/app/services/HallPass';

import { FlexTimeManagerRoutes } from '@modules/flex-time-manager';
import { KioskModeModalComponent } from '@modules/kiosk/kiosk-ui/components/kiosk-mode-modal/kiosk-mode-modal.component';
import { LayoutService } from '@modules/layout/services';
import { TeacherToolsService } from '@modules/teacher-tools/services';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { RedeemPointsModalService } from '@shared/components/redeem-points-modal/services';
import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ProfilePersonInfo } from '@shared/components/user-profile-header';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import {
  Messages,
  PBIS_CHECKIN_TYPES,
} from '../../constants/tt-view.constants';
import {
  HALLPASS_MENU_OPTIONS,
  ITeacherToolsMenu,
  ITeacherToolsPbis,
  TeacherToolsNavigationState,
  VIEW_ID_MENU_OPTIONS,
  VIEW_YOUR_ID_MENU_OPTIONS,
} from '../../teacher-tools.types';

@Component({
  selector: 'mg-tt-my-tools',
  templateUrl: './tt-my-tools.component.html',
  styleUrls: ['./tt-my-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtMyToolsComponent implements OnInit, OnDestroy {
  /** Enums */
  MESSAGES: typeof Messages = Messages;

  /** Observables */
  isNewLayoutEnabled$ = this._layout.useLegacyLayout$.pipe(
    map(useLegacy => !useLegacy),
  );
  private readonly _destroyed$ = new ReplaySubject<void>(1);

  public authHash$ = this._authInfo.authPersonHash$;

  /** Constants */
  viewIdMenuOptions: ITeacherToolsMenu[] = [
    {
      label: Messages.ID_TOOLS_YOUR_ID,
      asset: '/assets/teacher-tools/digital_id_img.svg',
      onClick: item => this._navigate(['', { outlets: { o: ['student-id'] } }]),
      type: VIEW_YOUR_ID_MENU_OPTIONS.VIEW,
    },
  ];
  idMenuOptions: ITeacherToolsMenu[] = [
    {
      label: Messages.ID_TOOLS_DASHBOARD,
      asset: '/assets/teacher-tools/dashboard-icon.svg',
      onClick: item => this._navigate('/studentid'),
      type: VIEW_ID_MENU_OPTIONS.VIEW,
      hidden$: this.isNewLayoutEnabled$,
    },
  ];
  hallPassOptions: ITeacherToolsMenu[] = [
    {
      label: Messages.HALL_PASS_CREATE,
      asset: '/assets/teacher-tools/hall_pass_img.svg',
      onClick: item => this.handleLaunchHallpassAssignmentForm(),
      type: HALLPASS_MENU_OPTIONS.ASSIGN,
    },
    {
      label: Messages.HALL_PASS_VIEW,
      asset: '/assets/teacher-tools/view-all-passes.svg',
      onClick: item => this.gotoHallPasses(),
      type: HALLPASS_MENU_OPTIONS.VIEW,
      hidden$: this.isNewLayoutEnabled$,
    },
  ];
  pbisOptions: ITeacherToolsPbis[] = [
    {
      label: Messages.PBIS_PRAISE,
      asset: '/assets/teacher-tools/praise_img.svg',
      onClick: item => this.gotoPbisForm(item.type),
      type: PBIS_CHECKIN_TYPES.PRAISE,
    },
    {
      label: Messages.PBIS_GUIDANCE,
      asset: '/assets/teacher-tools/guidance_img.svg',
      onClick: item => this.gotoPbisForm(item.type),
      type: PBIS_CHECKIN_TYPES.GUIDANCE,
    },
    {
      label: Messages.PBIS_CONSEQUENCE,
      asset: '/assets/teacher-tools/consequence_img.svg',
      onClick: item => this.gotoPbisForm(item.type),
      type: PBIS_CHECKIN_TYPES.CONSEQUENCE,
    },
    {
      label: Messages.PBIS_REDEEM_POINTS,
      asset: '/assets/teacher-tools/points_img.svg',
      onClick: () => this.openRedeemPointsModal(),
      type: PBIS_CHECKIN_TYPES.REWARDS,
    },
    {
      label: Messages.PBIS_MANAGE_POINTS,
      asset: '/assets/teacher-tools/manage_pts_img.svg',
      onClick: () => this.goToManagePoints(),
      type: PBIS_CHECKIN_TYPES.MANAGE_POINTS,
    },
  ];

  /** Device Check */
  readonly isWebBrowser = window.MINGA_APP_BROWSER;
  /** Navigation State */
  private readonly _navigationState: TeacherToolsNavigationState;

  readonly canGrantHallPass$: Observable<boolean> =
    this._hallPassService.canUserGrantHallPasses();
  /** Settings Variables */
  isHallPassEnabled$: Observable<boolean>;
  isDigitalIDEnabled$: Observable<boolean>;
  isPbisEnabled$: Observable<boolean>;
  isCheckinEnabled$: Observable<boolean>;
  isGroupsEnabled$: Observable<boolean>;
  areRewardsEnabled$: Observable<boolean>;
  isKioskEnabled$: Observable<boolean>;

  public readonly person$: Observable<ProfilePersonInfo> =
    this._authInfo.authPerson$.pipe(
      map(person => ({
        profileImageUrl: person.avatarUrl,
        firstName: person.firstName,
        lastName: person.lastName,
        badgeRoleName: person.badgeRoleName,
      })),
    );

  canUserPerformCheckins: boolean;
  public isFlexTimeEnabled$ = this._settingService.isFlexTimeModuleEnabled();
  private _isFlexAdmin = this._flexPermissionService.isFlexTimeAdmin();
  public userHasFlexTimePermissions =
    this._flexPermissionService.isFlexTimeSelfManagerOrHigher();

  /** Constructor */
  constructor(
    private _router: Router,
    private _groupsFacadeService: GroupsFacadeService,
    private _hallPassService: HallPassService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _permissionService: PermissionsService,
    private _settingService: MingaSettingsService,
    private _teacherToolsService: TeacherToolsService,
    private _redeemPointsModalService: RedeemPointsModalService,
    private _flexPermissionService: FlexTimePermissionsService,
    private _layout: LayoutService,
    private _mingaSettings: MingaSettingsService,
    private _authInfo: AuthInfoService,
    private _modalOverlay: ModalOverlayService,
    private _kioskPermissions: KioskPermissionsService,
  ) {
    this._navigationState =
      this._router.getCurrentNavigation()?.extras?.state || {};

    // Check if each module is enabled
    this.isHallPassEnabled$ = this._settingService.isHallPassModuleEnabled();
    this.isDigitalIDEnabled$ = this._settingService.isIdModuleEnabled();
    this.isPbisEnabled$ = this._settingService.isPbisModuleEnabled();
    this.isCheckinEnabled$ = this._settingService.isCheckinModuleEnabled();
    this.isGroupsEnabled$ = this._settingService.isCommunityModuleEnabled();
    this.areRewardsEnabled$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.PM_REWARDS_STORE,
    );
    this.isKioskEnabled$ = this._kioskPermissions.isKioskEnabled();

    // Additional checks to see if user role is allowed
    this.canUserPerformCheckins = this._permissionService.hasPermission(
      MingaPermission.CHECKIN_MANAGE,
    );
  }

  ngOnInit(): void {
    this._groupsFacadeService.dispatchLoadAll();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async gotoHallPasses() {
    this._router.navigate(['/hall-pass', { outlets: { o: null } }]);
  }

  public async goToFlexTime() {
    const link = this._isFlexAdmin
      ? `/${FlexTimeManagerRoutes.ROOT}`
      : `/${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.PERIODS}`;
    this._router.navigate([link, { outlets: { o: null } }]);
  }

  public goToManagePoints() {
    this._teacherToolsService.openManagePointsForm();
  }

  public handleLaunchHallpassAssignmentForm() {
    this._teacherToolsService.openHallpassAssignmentForm();
  }

  public gotoPbisForm(type: PBIS_CHECKIN_TYPES) {
    this._teacherToolsService.openPbisTrackingForm(type);
  }

  public openRedeemPointsModal() {
    this._redeemPointsModalService.openPeopleSelector();
  }

  public gotoCheckinForm() {
    this._teacherToolsService.openCheckinForm();
  }

  public async goToFlexTimeActivities() {
    this._teacherToolsService.openAssignActivityForm();
  }

  /**
   * Navigate, shows snackbar error
   * if for some reason the redirect fails
   */
  private async _navigate(url): Promise<void> {
    if (typeof url === 'string') {
      await this._router
        .navigateByUrl(url)
        .catch(() =>
          this._systemAlertSnackBar.error(Messages.SNACKBAR_FAILED_URL),
        );
    } else {
      await this._router
        .navigate(url as any)
        .catch(() =>
          this._systemAlertSnackBar.error(Messages.SNACKBAR_FAILED_URL),
        );
    }
  }

  public async showKioskModal() {
    this._teacherToolsService.showKioskModal();
  }
}

export enum HomeMessage {
  ROOT_PAGE_TITLE = 'Home',
}

export enum HomeRoute {
  ROOT = 'home',
  FEED = 'feed',
  CHALLENGES = 'challenges',
  POLLS = 'polls',
  MY_CONTENT = 'my-content',
  MODERATION = 'moderation',
  MODERATION_REPORTS = 'reports',
  MODERATION_DIRECT_MESSAGES = 'direct-messages',
  FILES = 'files',
  GALLERY = 'gallery',
  PROGRAMS = 'programs',
}

import { Validators } from '@angular/forms';

import { setDefaultRestrictionControlValue } from '@shared/components/form-restriction-input/form-restriction-input.constants';

export enum HpmTypesEditMessages {
  MODAL_TITLE_HALLPASS = 'Edit Hall Pass Type',
  MODAL_TITLE_NEW_HALLPASS = 'Create Hall Pass Type',

  LABEL_PASS_TIME = 'Default Pass Time',
  LABEL_PRIORITY = 'Priority',
  LABEL_NAME = 'Pass Name',
  LABEL_STUDENT_CREATED = 'Students Can Self Issue',
  LABEL_MEMBERSHIP = 'Limit students this pass is available to',
  LABEL_PASS_RESTRICTIONS = 'Limit who can assign this pass',
  LABEL_RESTRICTION_SECTION = 'Hall pass restrictions',
  LABEL_SETTINGS_SECTION = 'Settings',
  LABEL_DAILY_LIMIT_TOGGLE = 'Limit passes per student, per day',
  LABEL_DAILY_LIMIT = 'Daily limit',
  LABEL_MANUALLY_END_PASS = 'Hall pass ending method',

  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_CREATE = 'Create',

  ERROR_SAVING = 'Failed to update hall pass type',

  FIELD_LABEL_NAME = 'Hall pass name',
  FIELD_LABEL_COLOR = 'Icon color',
  FIELD_LABEL_PRIORITY = 'Priority (optional)',
  FIELD_LABEL_PASS_TIME = 'Default pass time',
  FIELD_LABEL_MAX_PASSES = 'Max simultaneous passes',
  FIELD_LABEL_ICON = 'Icon',

  STUDENT_END_PASS_MSG = 'Allow students to manually end their hall passes',
  MOBILE_DEVICE_END_MSG = 'Allow to end hall passes on mobile device',
  STUDENT_CREATED_MSG = 'Students can create a pass',
  TEACHER_APPROVED_MSG = 'Teacher must approve pass',
  IGNORE_BLACKOUTS_MSG = 'Ignore blackouts',
  IGNORE_LIMITS_MSG = 'Ignore limits',
  ENABLE_FOR_KIOSK_MSG = 'Enable this hall pass in kiosk mode',
}

export enum HpmTypesEditHelpMessages {
  INPUT_PRIORITY = 'Positions the hall pass type further up/down the list and hall pass form depending on the value set. 0 represents the highest priority',
  INPUT_DEFAULT_DURATION = 'The default selected duration when creating a hall pass of the current type',
  INPUT_MAX_ACTIVE_TYPES = 'The max limit of active hall passes of the current hall pass type',
  INPUT_DAILY_LIMIT = 'The setting "Maximum hall passes per student per day" in the Hall Pass settings will cap this limit',
  INPUT_ENABLE_FOR_KIOSK = 'Allow to create and end this hall pass in kiosk mode. Hall pass kiosk needs to be turned on in the hall pass general settings',
  INPUT_STUDENT_END_PASS = 'Allow students to end hall pass with their account. It does not affect kiosk mode',
  INPUT_STUDENT_CREATED = 'Allow students to create a hall pass with their account. It does not affect kiosk mode',
  INPUT_TEACHER_APPROVED = "Teachers must approve this pass if it's created from a student account. This setting disables hall pass visibility in Kiosk Mode.",
}

export enum HpmTypesEditFormFields {
  NAME = 'NAME',
  COLOR = 'COLOR',
  PRIORITY = 'PRIORITY',
  DEFAULT_PASS_TIME = 'DEFAULT_PASS_TIME',
  MAX_PASSES = 'MAX_PASSES',
  STUDENT_CREATED = 'STUDENT_CREATED',
  REQUIRED_TEACHER_APPROVAL = 'REQUIRED_TEACHER_APPROVAL',
  IGNORE_BLACKOUTS = 'IGNORE_BLACKOUTS',
  IGNORE_LIMITS = 'IGNORE_LIMITS',
  ICON = 'ICON',
  LIMIT_STUDENTS = 'LIMIT_STUDENTS',
  LIMIT_PASS_RESTRICTIONS = 'LIMIT_PASS_RESTRICTIONS',
  LIMIT_DAILY_PASSES_TOGGLE = 'LIMIT_DAILY_PASSES_TOGGLE',
  LIMIT_DAILY_PASSES = 'LIMIT_DAILY_PASSES',
  MANUALLY_END_PASS = 'MANUALLY_END_PASS',
  STUDENT_CAN_END_PASS = 'STUDENT_CAN_END_PASS',
  CAN_END_ON_MOBILE = 'CAN_END_ON_MOBILE',
  AVAILABLE_IN_KIOSK = 'AVAILABLE_IN_KIOSK',
}

export const HPM_TYPE_FORM = {
  [HpmTypesEditFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(25)],
  ],
  [HpmTypesEditFormFields.COLOR]: [undefined],
  [HpmTypesEditFormFields.PRIORITY]: [0],
  [HpmTypesEditFormFields.DEFAULT_PASS_TIME]: [0, [Validators.min(0)]],
  [HpmTypesEditFormFields.MAX_PASSES]: [0, [Validators.min(0)]],
  [HpmTypesEditFormFields.STUDENT_CREATED]: false,
  [HpmTypesEditFormFields.REQUIRED_TEACHER_APPROVAL]: false,
  [HpmTypesEditFormFields.IGNORE_BLACKOUTS]: false,
  [HpmTypesEditFormFields.IGNORE_LIMITS]: false,
  [HpmTypesEditFormFields.ICON]: [undefined],
  [HpmTypesEditFormFields.LIMIT_STUDENTS]: [false], // just used for local control state, we dont persist to db
  [HpmTypesEditFormFields.LIMIT_PASS_RESTRICTIONS]:
    setDefaultRestrictionControlValue(),
  [HpmTypesEditFormFields.LIMIT_DAILY_PASSES_TOGGLE]: [false], // just used for local control state, we dont persist to db
  [HpmTypesEditFormFields.LIMIT_DAILY_PASSES]: [
    1,
    [Validators.min(1), Validators.max(99)],
  ],
  [HpmTypesEditFormFields.MANUALLY_END_PASS]: [undefined],
  [HpmTypesEditFormFields.STUDENT_CAN_END_PASS]: undefined,
  [HpmTypesEditFormFields.CAN_END_ON_MOBILE]: undefined,
  [HpmTypesEditFormFields.AVAILABLE_IN_KIOSK]: false,
};

<!-- Bottom sheet presentional container -->
<mg-bottom-sheet
  [title]="data.title"
  (onEsc)="onEsc()"
  (onClose)="onClose()">
  <!-- Main content -->
  <section id="main-bottom-sheet-content">
    <!-- Search input -->
    <div
      *ngIf="data.enableSearch"
      id="search-input">
      <mg-form-text-input
        placeholder="Search"
        iconRight="mg-search"
        [isClearable]="true"
        [control]="filter.control">
      </mg-form-text-input>
    </div>
    <!-- Virtual scroll area -->
    <div class="viewport-container">
      <!-- Virtual scroller -->
      <cdk-virtual-scroll-viewport
        *ngIf="filter.hasFilteredData$ | async"
        itemSize="50"
        class="option-viewport">
        <button
          *cdkVirtualFor="let option of filter.filteredData$ | async"
          matRipple
          class="option"
          [class.checkbox]="data.multiple"
          [class.radio]="!data.multiple"
          [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
          [matRippleDisabled]="option?.disabled"
          [disabled]="option?.disabled"
          (click)="selectOption(option)">
          <!-- Multiple selection -->
          <mg-form-checkbox
            *ngIf="data.multiple; else radioTemplate"
            [checked]="selection.isSelected(option)"
            [disabled]="option?.disabled">
            {{ option.label }}
          </mg-form-checkbox>
          <!-- Single selection -->
          <ng-template #radioTemplate>
            <mat-radio-button
              [value]="option.value"
              [checked]="selection.isSelected(option)"
              [disabled]="option?.disabled">
              {{ option.label }}
            </mat-radio-button>
          </ng-template>
        </button>
      </cdk-virtual-scroll-viewport>
      <!-- Empty state -->
      <ng-container
        *ngIf="(filter.hasFilteredData$ | async) === false"
        [ngTemplateOutlet]="emptyStateTemplate">
      </ng-container>
    </div>
  </section>
  <!-- Footer -->
  <ng-template #actions>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-btn
        variant="text"
        [disabled]="selection.size === 0"
        (pressed)="selection.clear()">
        {{ MESSAGE.LABEL_UNSELECT_ALL }}
      </mg-btn>
      <mg-btn
        variant="filled"
        (pressed)="submit()">
        {{ MESSAGE.LABEL_DONE }}
      </mg-btn>
    </div>
  </ng-template>
</mg-bottom-sheet>

<!-- Empty state template -->
<ng-template #emptyStateTemplate>
  <div class="empty-state">
    <mg-empty-state
      [title]="MESSAGE.EMPTY_STATE_TITLE"
      [subtitle]="MESSAGE.EMPTY_STATE_SUBTITLE">
    </mg-empty-state>
  </div>
</ng-template>

import { Injectable } from '@angular/core';

import { from, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { MingaSettingsService } from 'minga/app/src/app/store/Minga/services';
import { EventStatus, IEventTicket } from 'minga/domain/event';
import { CheckinManager } from 'minga/proto/checkin/checkin_ng_grpc_pb';
import {
  CheckinRequest,
  CheckoutRequest,
  SelfCheckInRequest,
  SelfCheckInResponse,
  SelfCheckOutResponse,
} from 'minga/proto/checkin/checkin_pb';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { StreamFilterOp } from 'minga/proto/common/stream_pb';
import { Event } from 'minga/proto/content/event_ng_grpc_pb';
import {
  AddEventTicketsRequest,
  CancelEventRequest,
  EventTicketsPeopleRequest,
  ExportEventTicketsRequest,
  GetEventGoingPeopleRequest,
  GetEventManagementInfoRequest,
  GetEventTicketsByStudentIdRequest,
  GetInvitedPeopleRequest,
  InvitePeopleRequest,
  RemovePeopleFromEventRequest,
} from 'minga/proto/content/event_pb';
import {
  LongEventCardView,
  ShortEventCardView,
} from 'minga/proto/gateway/content_views_pb';
import { FeedEventManager } from 'minga/proto/gateway/feed_event_ng_grpc_pb';
import {
  CalendarEventsRequest,
  CalendarEventsResponse,
  GetEventsRequest,
} from 'minga/proto/gateway/feed_event_pb';
import { EventStatusMessage } from 'minga/proto/gateway/login_pb';
import { PersonViewEvent } from 'minga/proto/gateway/person_view_pb';
import { PostManager } from 'minga/proto/gateway/post_ng_grpc_pb';
import {
  CheckInToEventRequest,
  CheckInToEventResponse,
} from 'minga/proto/gateway/post_pb';
import { IEventTicketProtoMapper } from 'minga/shared-grpc/event';
import { RestrictionErrorMapper } from 'minga/shared-grpc/restriction';
import { EVENT_SCHEDULE_TYPE } from 'minga/shared/event';
import {
  eventCategoryColor,
  EventCategoryEnum,
} from 'minga/shared/event/categories';
import { MingaPermission, PersonEventStatus } from 'minga/util';
import { dateMessageToDateObject } from 'minga/util/protobuf';
import { QuickFilterType } from 'src/app/components/CalendarQuickFilter';
import { IEventCalendarSettings } from 'src/app/services/EventCalendar';
import { IMgStreamItem } from 'src/app/util/stream';

import { CheckinResponseDetails } from '@shared/services/checkin';

export interface ICalendarSelectDay {
  going: boolean;
  date: Date;
  past?: boolean;
  checkedIn?: boolean;
  endDate?: Date | null;
  color: string;
}

export interface IGoingMapValue {
  actualValue: boolean;
  value: boolean;
}

export interface IFilterEventsEvent {
  date?: Date;
  going?: boolean;
  restartStream?: boolean;
  checkedIn?: boolean;
}

export interface IEventContentPeopleEvent {
  contextHash: string;
  content?: ShortEventCardView.AsObject | LongEventCardView.AsObject;
  initialPeopleStatusFilter?: PersonEventStatus;
}

export interface IEventCheckinResponse {
  requiresTicket: boolean;
  people: IEventCheckinPerson[];
  singleCheckinOnly: boolean;
}
export interface IEventCheckinPerson {
  personHash: string;
  displayName: string;
  ticket?: IEventTicket;
  checkInTime?: Date;
  checkOutTime?: Date;
}

export enum CALENDAR_EVENT_FILTER {
  PAST = 1,
  UPCOMING,
  GOING,
  ALL,
}

export enum EVENT_CHECKIN_ACTION {
  'LEAVE' = 1,
  'GOING',
  'CHECK_IN',
  'CHECK_OUT',
  'INTERESTED',
}

export interface IEventManagementCounts {
  checkinCount: number;
  checkoutCount: number;
  ticketCount: number;
  inviteCount: number;
  goingCount: number;
  responseCount: number;
  managerCount: number;
  notCheckedInCount: number;
}

/**
 * Is this status one where you'd change the going count
 *
 * @param status
 */
export const isGoingCountStatus = (status: EventStatus): boolean => {
  const goingStatuses = [
    EventStatus.GOING,
    EventStatus.INTERESTED,
    EventStatus.CHECK_IN,
  ];
  return goingStatuses.includes(status);
};

export enum eventErrorOutput {
  DONT_CHECK_IN = 0,
  SKIP_BLOCKED = 1,
  ALLOW_BLOCKED = 2,
  NO_ERRORS = -1,
}

@Injectable({ providedIn: 'root' })
export class EventContentService {
  private _goingCountChange: Subject<[string, number]>;
  private _checkInMap: Map<string, EventStatus>;
  private _checkInMapChange: Subject<[string, EventStatus]>;
  eventCalendarDays: ICalendarSelectDay[] = [];
  private _onFilterEvents: Subject<IFilterEventsEvent>;
  private _onUnFilterEvents: Subject<IFilterEventsEvent>;
  private _onSeeEventPeopleGoing: Subject<IEventContentPeopleEvent>;
  private _onEventManagementChange: Subject<string>;
  private _goingPeopleContentMap: Map<
    string,
    ShortEventCardView.AsObject | LongEventCardView.AsObject
  >;
  private _personEventStatusFilter: PersonEventStatus;
  private _ticketsRequiredMap: Map<string, boolean>;

  constructor(
    private _contentEvents: ContentEvents,
    private _postManager: PostManager,
    private _feedEventManager: FeedEventManager,
    private _analyticsService: AnalyticsService,
    private _permissions: PermissionsService,
    private _authInfoService: AuthInfoService,
    private _eventService: Event,
    private _settingService: MingaSettingsService,
    private _checkinService: CheckinManager,
  ) {
    this._goingCountChange = new Subject();
    this._checkInMap = new Map();
    this._checkInMapChange = new Subject();
    this._goingPeopleContentMap = new Map();
    this._ticketsRequiredMap = new Map();
    this._onFilterEvents = new Subject();
    this._onUnFilterEvents = new Subject();
    this._onEventManagementChange = new ReplaySubject(1);
    this._onSeeEventPeopleGoing = new Subject();
    this._personEventStatusFilter = PersonEventStatus.Going;

    // reset the calendar if the minga is changed
    _contentEvents.onMingaUpdate.subscribe(ev => {
      this.eventCalendarDays = [];
    });

    _contentEvents.onContentEventCheckIn.subscribe(async ev => {
      const currentValue = this.getCheckIn(ev.contentContextHash);

      let newStatus = ev.status;
      let increment = 0;

      if (
        (!currentValue || !isGoingCountStatus(currentValue)) &&
        isGoingCountStatus(newStatus)
      ) {
        increment = 1;
      } else if (
        isGoingCountStatus(currentValue) &&
        !isGoingCountStatus(newStatus)
      ) {
        increment = -1;
      }
      this._goingCountChange.next([ev.contentContextHash, increment]);

      // if event has code open the code check in dialog
      if (ev.hasCode && ev.status === EventStatus.CHECK_IN) {
        this._contentEvents.emitContentEventCodeCheckIn({
          contentContextHash: ev.contentContextHash,
          contentTitle: ev.contentTitle || '',
          date: ev.date,
          eventReasonId: ev.eventReasonId,
        });
        return;
      }
      if (newStatus === EventStatus.CHECK_IN) {
        newStatus = EventStatus.CHECKED_IN;
      } else if (newStatus === EventStatus.CHECK_OUT) {
        newStatus = EventStatus.CHECKED_OUT;
      }
      // optimistically change value.
      this._checkInMap.set(ev.contentContextHash, newStatus);
      this._checkInMapChange.next([ev.contentContextHash, newStatus]);

      let response:
        | SelfCheckInResponse
        | SelfCheckOutResponse
        | CheckInToEventResponse
        | void;
      if (newStatus === EventStatus.CHECKED_IN) {
        response = await this.checkInToEvent(ev.eventReasonId);
      }
      if (newStatus === EventStatus.CHECKED_OUT) {
        response = await this.checkOutOfEvent(ev.eventReasonId);
      } else {
        response = await this.setEventStatus(ev.contentContextHash, newStatus);
      }
      if (!response) {
        ev.checkedIn = false;

        // reverse the change
        this._checkInMap.set(ev.contentContextHash, currentValue);
        this._checkInMapChange.next([ev.contentContextHash, currentValue]);
        this._goingCountChange.next([ev.contentContextHash, increment * -1]);

        _contentEvents.emitContentEventGoingError({
          status: ev.status,
          contentContextHash: ev.contentContextHash,
          going: ev.going,
          checkedIn: ev.checkedIn,
        });
        return;
      } else {
        if (!response.getErrorMessage()) {
          this._contentEvents.emitContentEventCodeCheckInSuccess({
            contentContextHash: ev.contentContextHash,
            date: ev.date,
          });
        } else {
          this._contentEvents.emitContentEventGoingError({
            contentContextHash: ev.contentContextHash,
            status: ev.status,
            going: ev.going,
            checkedIn: ev.checkedIn,
          });
        }
      }
    });
  }

  get onFilterEvents(): Observable<IFilterEventsEvent> {
    return this._onFilterEvents.asObservable();
  }

  get onUnFilterEvents(): Observable<IFilterEventsEvent> {
    return this._onUnFilterEvents.asObservable();
  }

  get onSeeEventPeopleGoing(): Observable<IEventContentPeopleEvent> {
    return this._onSeeEventPeopleGoing.asObservable();
  }

  emitFilterEvent(ev: IFilterEventsEvent) {
    this._onFilterEvents.next(ev);
  }

  emitUnFilterEvent(ev: IFilterEventsEvent) {
    this._onUnFilterEvents.next(ev);
  }

  emitSeeEventPeopleGoing(ev: IEventContentPeopleEvent) {
    this._onSeeEventPeopleGoing.next(ev);
  }

  getStartedAndCurrentDayFilter(targetDate: Date) {
    const todayMorning = new Date(targetDate);
    todayMorning.setHours(0);
    todayMorning.setMinutes(0);
    todayMorning.setSeconds(0);
    todayMorning.setMilliseconds(0);

    const todayMidnight = new Date(targetDate);
    todayMidnight.setHours(23);
    todayMidnight.setMinutes(59);
    todayMidnight.setSeconds(59);
    todayMidnight.setMilliseconds(999);

    const todayMorningValue = new StreamFilterOp.Value();
    todayMorningValue.setUint64Value(todayMorning.getTime());
    const todayMidnightValue = new StreamFilterOp.Value();
    todayMidnightValue.setUint64Value(todayMidnight.getTime());

    const greaterThanOp = new StreamFilterOp.GteValue();
    greaterThanOp.setValue(todayMorningValue);
    const lessThanEqualOp = new StreamFilterOp.LteValue();
    lessThanEqualOp.setValue(todayMidnightValue);

    const streamFilterOpEnd = new StreamFilterOp();
    streamFilterOpEnd.setGteValue(greaterThanOp);

    const streamFilterOpStart = new StreamFilterOp();
    streamFilterOpStart.setLteValue(lessThanEqualOp);

    return {
      'shortEventCard.endTimestamp': streamFilterOpEnd,
      'shortEventCard.startTimestamp': streamFilterOpStart,
    };
  }

  async setEventStatus(contextHash: string, eventStatus: EventStatus) {
    const request = new CheckInToEventRequest();
    request.setContextHash(contextHash);
    request.setStatus(eventStatus);

    const response = await this._postManager
      .checkInToEvent(request)
      .catch(err => {
        console.error(`[EventContent] checkInToEvent() error occurred:`, err);

        const checkInResponse = new CheckInToEventResponse();
        checkInResponse.setStatus(StatusCode.ERROR);
        checkInResponse.setErrorMessage(err.message);

        return checkInResponse;
      });

    const status = response.getStatus();
    if (status === StatusCode.INSUFFICIENT_PERMISSIONS) {
      // this.snackBar.open(`You have insufficient _permissions to check-in to
      // events`);
    }
    this._analyticsService.sendEventGoingTriggered(true);
    return response;
  }

  async checkInToEvent(eventReasonId: number, code: string = '') {
    const request = new SelfCheckInRequest();
    request.setCheckinReasonId(eventReasonId);
    if (!!code) {
      request.setCode(code);
    }
    const response = await this._checkinService
      .selfCheckIn(request)
      .catch(err => {
        console.error(`[EventContent] checkInToEvent() error occurred:`, err);
        const checkinResponse = new SelfCheckInResponse();
        checkinResponse.setErrorMessage(err.message);
        return checkinResponse;
      });
    this._analyticsService.sendEventGoingTriggered(true);
    return response;
  }

  async checkOutOfEvent(eventReasonId: number) {
    const request = new SelfCheckInRequest();
    request.setCheckinReasonId(eventReasonId);
    const response = await this._checkinService
      .selfCheckOut(request)
      .catch(err => {
        console.error(`[EventContent] checkOutOfEvent() error occurred:`, err);
        const checkinResponse = new SelfCheckOutResponse();
        checkinResponse.setErrorMessage(err.message);
        return checkinResponse;
      });
    this._analyticsService.sendEventGoingTriggered(true);
    return response;
  }

  /** @deprecated not used anywhere */
  setEventCheckIns(msgs: EventStatusMessage[]) {
    this._checkInMap.clear();
    if (msgs && msgs.length) {
      msgs.forEach(msg => {
        const status = $enum(EventStatus).asValueOrDefault(
          msg.getCurrentEventStatus(),
        );
        if (status) {
          this._checkInMap.set(msg.getEventHash(), status);
          this._checkInMapChange.next([msg.getEventHash(), status]);
        }
      });
    }
  }

  getCheckIn(contextHash: string) {
    const status = this._checkInMap.get(contextHash);
    return status || EventStatus.NONE;
  }

  setCheckIn(contextHash: string, status: EventStatus) {
    this._checkInMap.set(contextHash, status);
    this._checkInMapChange.next([contextHash, status]);
  }

  observeCheckIn(contextHash: string): Observable<EventStatus> {
    return this._checkInMapChange.pipe(
      filter(([key]) => key === contextHash),
      map(([key, value]) => value),
      startWith(this.getCheckIn(contextHash)),
    );
  }

  observeGoingCountChange(contextHash: string): Observable<number> {
    return this._goingCountChange.pipe(
      filter(([key]) => key === contextHash),
      map(([key, value]) => value),
    );
  }

  private async _fetchCalendarEvents() {
    const request = new CalendarEventsRequest();

    const response: CalendarEventsResponse =
      await this._feedEventManager.getCalendarEvents(request);
    const status = response.getStatus();

    this.eventCalendarDays = [];
    if (status === StatusCode.OK) {
      const events = response.getEventsList();

      const todayMorning = new Date();
      todayMorning.setHours(0);
      todayMorning.setMinutes(0);
      todayMorning.setSeconds(0);
      todayMorning.setMilliseconds(0);
      const todayMS = todayMorning.getTime();

      events.forEach(event => {
        const eventDate = event.getDate();
        const eventEnd = event.getEndDate();
        const categoryKey = $enum(EventCategoryEnum).asValueOrDefault(
          event.getEventCategory(),
          EventCategoryEnum.OTHER,
        );
        this.eventCalendarDays.push({
          going: event.getGoing(),
          date: new Date(eventDate),
          past: todayMS > eventDate,
          checkedIn: event.getCheckedIn(),
          endDate: new Date(eventEnd),
          color: eventCategoryColor(categoryKey),
        });
      });
    }
    return this.eventCalendarDays;
  }

  async getCalendarEvents(fetch = false) {
    if (!fetch && this.eventCalendarDays.length) return this.eventCalendarDays;

    return await this._fetchCalendarEvents();
  }

  async getEventsAsStreamItem(
    settings: IEventCalendarSettings,
    limit?: number,
    offset?: number,
    startDate?: Date,
    endDate?: Date,
  ) {
    const request = new GetEventsRequest();
    const type = settings.showPastEvents
      ? EVENT_SCHEDULE_TYPE.PAST
      : EVENT_SCHEDULE_TYPE.UPCOMING;
    request.setEventType(type);
    if (limit) {
      request.setLimit(limit);
    }
    if (offset) {
      request.setOffset(offset);
    }
    if (startDate && endDate) {
      request.setStartDate(startDate.toISOString());
      request.setEndDate(endDate.toISOString());
    }
    if (settings.groupHashes?.length) {
      request.setGroupHashList(settings.groupHashes);
    }
    if (settings.category) request.setCategory(settings.category);

    const response = await this._feedEventManager.getEvents(request);
    const items: IMgStreamItem<ShortEventCardView.AsObject>[] = [];
    const events = response.getEventList();
    for (let i = 0; i < events.length; i++) {
      //set the _checkInMap map when event stream has a new pull
      const status = $enum(EventStatus).asValueOrDefault(
        events[i].getEventStatus(),
      );
      this.setCheckIn(events[i].getContextHash(), status);

      if (settings.quickFilter) {
        if (
          !this._filterEvent(events[i].getContextHash(), settings.quickFilter)
        ) {
          continue;
        }
      }

      items.push({
        item: events[i].toObject(),
        itemId: events[i].getContextHash(),
        itemIndex: i,
      });
    }

    return items;
  }

  // @TODO move to BE
  private _filterEvent(contextHash: string, filter: QuickFilterType): boolean {
    const status = this.getCheckIn(contextHash);
    if (filter === 'My Events') {
      // Statuses that make an event 'my' event
      const myEventsStatuses = [
        EventStatus.CHECKED_IN,
        EventStatus.CHECKED_OUT,
        EventStatus.GOING,
        EventStatus.INVITED,
        EventStatus.INTERESTED,
      ];
      return myEventsStatuses.includes(status);
    } else if (filter === EventStatus.NONE) {
      return true;
    } else if (status === filter) {
      return true;
    }
    return false;
  }

  getContentForPeopleGoing(contextHash: string) {
    return this._goingPeopleContentMap.get(contextHash);
  }

  clearContentForPeopleGoing(contextHash: string = '') {
    if (contextHash) {
      this._goingPeopleContentMap.delete(contextHash);
    } else {
      this._goingPeopleContentMap.clear();
    }
  }

  setGoingPeopleContent(
    contextHash: string,
    content: ShortEventCardView.AsObject | LongEventCardView.AsObject,
    personFilter?: PersonEventStatus,
  ) {
    this._goingPeopleContentMap.set(contextHash, content);
    this._personEventStatusFilter = personFilter || PersonEventStatus.Going;
  }

  async checkIfCanManageEvent(
    content?: ShortEventCardView.AsObject | LongEventCardView.AsObject,
  ): Promise<boolean> {
    const featureEnabled = this._settingService.isPbisModuleEnabled();
    if (!featureEnabled) {
      return false;
    }
    if (this._permissions.hasPermission(MingaPermission.EVENTS_MANAGE)) {
      return true;
    }
    if (
      content?.authorPersonView?.personHash ===
      this._authInfoService.authPersonHash
    ) {
      return true;
    }
    const managers = content?.eventManagersList;
    if (managers) {
      const isManager = managers.find(
        person => person === this._authInfoService.authPersonHash,
      );
      if (isManager) {
        return true;
      }
    }
    return false;
  }

  getInitialStatusFilter = () => this._personEventStatusFilter;

  async cancelEvent(contentHash: string) {
    const request = new CancelEventRequest();
    request.setContentHash(contentHash);
    return await this._eventService.cancelEvent(request);
  }

  async getEventInvitePeople(
    contextHash: string,
  ): Promise<PersonViewEvent.AsObject[]> {
    const request = new GetInvitedPeopleRequest();

    request.setContextHash(contextHash);
    const response = await this._eventService.getInvitedPeople(request);

    const people = response.getPeopleList();

    return people.map(person => person.toObject());
  }

  async invitePeopleToEvent(contextHash: string, personHashes: string[]) {
    const request = new InvitePeopleRequest();
    request.setContextHash(contextHash);
    request.setPersonHashList(personHashes);

    return this._eventService.invitePeople(request);
  }

  async checkInUsers(
    eventReasonId: number,
    personHashes: string[],
    guestIds: number[],
    bypassValidation: boolean,
  ): Promise<CheckinResponseDetails> {
    const request = new CheckinRequest();
    request.setCheckinReasonId(eventReasonId);
    request.setPeopleHashesList(personHashes);
    request.setGuestList(guestIds);

    if (bypassValidation) {
      request.setBypassMultiCheck(bypassValidation);
    }

    const response = await this._checkinService.checkin(request);
    const validationProtoErrors = response.getErrorList();

    return {
      errors: validationProtoErrors.map(RestrictionErrorMapper.fromProto),
      successPeopleHashes: response.getSuccessPeopleHashesList(),
    };
  }

  async checkOutUsers(
    eventReasonId: number,
    personHashes: string[],
    guestIds: number[],
  ) {
    const request = new CheckoutRequest();
    request.setCheckinReasonId(eventReasonId);
    request.setPeopleHashesList(personHashes);
    request.setGuestList(guestIds);

    const response = await this._checkinService.checkout(request);
    const validationProtoErrors = response.getErrorList();

    return {
      errors: validationProtoErrors.map(RestrictionErrorMapper.fromProto),
      successPeopleHashes: response.getSuccessPeopleHashesList(),
    };
  }

  async sendAddEventTicketRequest(request: AddEventTicketsRequest) {
    return await this._eventService.addEventTickets(request);
  }

  async getEventTicketsForStudentId(
    contextHash: string,
    studentIds: string[],
    personHashes: string[],
  ): Promise<IEventCheckinResponse | null> {
    const request = new GetEventTicketsByStudentIdRequest();
    request.setContextHash(contextHash);
    request.setStudentIdList(studentIds);
    request.setPersonHashList(personHashes);

    const msg = await this._eventService.getEventTicketsByStudentId(request);

    const requiresTicket = msg.getRequiresTicket();
    const singleCheckinOnly = msg.getSingleCheckin();

    const people = msg.getPersonList();
    const checkinPeople: IEventCheckinPerson[] = [];
    const response: IEventCheckinResponse = {
      people: checkinPeople,
      requiresTicket,
      singleCheckinOnly,
    };

    for (const person of people) {
      const personHash = person.getPersonHash();
      const displayName = person.getDisplayName();

      if (!personHash || !displayName) {
        return null;
      }
      const checkinPerson: IEventCheckinPerson = {
        personHash,
        displayName,
      };

      const checkin = person.getCheckedIn();
      const checkout = person.getCheckedOut();
      if (checkin) {
        checkinPerson.checkInTime = dateMessageToDateObject(checkin);
      }
      if (checkout) {
        checkinPerson.checkOutTime = dateMessageToDateObject(checkout);
      }

      const ticketMsg = person.getTicket();
      if (ticketMsg) {
        const tickets =
          IEventTicketProtoMapper.fromEventTicketMessage(ticketMsg);
        checkinPerson.ticket = tickets;
      }
      checkinPeople.push(checkinPerson);
    }

    return response;
  }

  async getEventTickets(
    contextHash: string,
  ): Promise<PersonViewEvent.AsObject[]> {
    const request = new EventTicketsPeopleRequest();
    request.setContextHash(contextHash);

    const msg = await this._eventService.getEventTickets(request);
    const people = msg.getPersonList();

    return people.map(person => person.toObject());
  }

  exportEventTickets(contextHash: string) {
    const request = new ExportEventTicketsRequest();
    request.setContextHash(contextHash);

    return this._eventService.exportTickets(request);
  }

  async removePeopleFromEvent(contextHash: string, peopleHashes: string[]) {
    const request = new RemovePeopleFromEventRequest();
    request.setContextHash(contextHash);
    request.setPersonHashList(peopleHashes);

    return this._eventService.removePeopleFromEvent(request);
  }

  getEventManagementInfo(
    contextHash: string,
  ): Observable<IEventManagementCounts> {
    return this._onEventManagementChange.asObservable().pipe(
      filter(hash => contextHash === hash),
      switchMap(hash => this.sendGetManagementInfoRequest(hash)),
      map(response => {
        return {
          checkinCount: response.getCheckinCount(),
          checkoutCount: response.getCheckoutCount(),
          ticketCount: response.getTicketCount(),
          inviteCount: response.getInviteCount(),
          goingCount: response.getGoingCount(),
          responseCount: response.getResponsesCount(),
          managerCount: response.getManagerCount(),
          notCheckedInCount: response.getNotCheckedInCount(),
        };
      }),
    );
  }

  sendGetManagementInfoRequest(contextHash: string) {
    const request = new GetEventManagementInfoRequest();
    request.setContextHash(contextHash);

    return from(this._eventService.getEventManagementInfo(request));
  }

  public async getEventGoingPeople(
    contextHash: string,
    checkinStatus: PersonEventStatus,
  ): Promise<PersonViewEvent.AsObject[]> {
    const request = new GetEventGoingPeopleRequest();
    request.setContextHash(contextHash);
    request.setCheckinStatus(checkinStatus);

    const response = await this._eventService.getEventGoingPeople(request);

    return response.getPersonList().map(person => person.toObject());
  }

  /**
   * Update whether to get an update from backend on event management
   * data for this context.
   *
   * @param contextHash
   */
  triggerEventManagementUpdate(contextHash: string) {
    this._onEventManagementChange.next(contextHash);
  }

  /**
   * Keep track of whether an event requires tickets.
   *
   * @param contextHash
   * @returns
   */
  getTicketsRequired(contextHash: string) {
    const status = this._ticketsRequiredMap.get(contextHash);

    return status;
  }

  setTicketsRequired(contextHash: string, required: boolean) {
    this._ticketsRequiredMap.set(contextHash, required);
  }

  clear() {
    this._checkInMap = new Map();
    this._checkInMapChange = new Subject();
  }
}

import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { isEmpty } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { BottomSheetService } from '@shared/components/bottom-sheet';
import { MediaService } from '@shared/services/media';

import { FiltersFormSheetComponent } from './components/filters-form-sheet/filters-form-sheet.component';
import { FiltersFormMessage } from './constants';
import {
  FiltersFormData,
  FiltersFormSheetData,
  FiltersFormSheetResponseData,
} from './types';
import { FilterFormOverlay } from './utils';

@Component({
  selector: 'mg-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormComponent implements OnDestroy {
  // Children

  @ViewChild('triggerButton') triggerButton: ElementRef;

  // Contants

  public readonly MESSAGE = FiltersFormMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Overlay

  public readonly overlay = new FilterFormOverlay(
    this._overlay,
    this._viewContainerRef,
  );

  // Form state

  public readonly form = new FormGroup({});
  public readonly initialFormValueState: { [key: string]: any } = {};
  private readonly _formFieldsSet = new Set<string>();

  // State

  private _data: FiltersFormData;

  // Events

  private readonly _resetSubject = new Subject<void>();

  // Subscriptions

  private readonly _resetSubscription = this._resetSubject
    .pipe(takeUntil(this._destroyedSubject))
    .subscribe(() => {
      this.form.reset(this.initialFormValueState);
    });

  private readonly _formValueChangeSubscription = this.form.valueChanges
    .pipe(takeUntil(this._destroyedSubject), distinctUntilChanged())
    .subscribe(v => {
      this.state.emit(v);
    });

  /**
   * Applied filters count
   *
   * Number of applied/active filters
   */
  public readonly appliedFiltersCount$ = this.form.valueChanges.pipe(
    takeUntil(this._destroyedSubject),
    startWith(this.form.value),
    distinctUntilChanged(),
    map(state => {
      let count = 0;
      for (const key in state) {
        if (state[key] !== this.initialFormValueState[key]) count++;
      }
      return count;
    }),
  );

  // Inputs

  @Input() set data(data: FiltersFormData) {
    this._data = data;
    for (const field in data) {
      if (data.hasOwnProperty(field)) {
        this._formFieldsSet.add(field);
        const { initialValue = null } = data[field];
        this.initialFormValueState[field] = initialValue;
        this.form.registerControl(field, this._fb.control(initialValue));
      }
    }
    if (!isEmpty(this.initialFormValueState)) {
      this.state.emit(this.initialFormValueState);
    }
  }
  @Input() title = 'Filters';
  @Input() appearance: 'primary' | 'secondary' = 'primary';

  // Outputs

  @Output() state = new EventEmitter<any>();

  /** Component constructor */
  constructor(
    public media: MediaService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _bottomSheet: BottomSheetService<
      FiltersFormSheetData,
      FiltersFormSheetResponseData
    >,
    private _fb: FormBuilder,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._resetSubject.complete();
    this._resetSubscription.unsubscribe();
    this._formFieldsSet.clear();
    this._formValueChangeSubscription.unsubscribe();
  }

  public async open() {
    const fields = this._getFormFields();
    if (this.media.isMobileView) {
      const bottomSheetRef = this._bottomSheet.open(FiltersFormSheetComponent, {
        data: {
          title: this.title,
          data: this._data,
          fields,
          formGroup: this.form,
          resetSubject: this._resetSubject,
        },
      });
      return bottomSheetRef.afterDismissed();
    } else {
      this.overlay.open(
        this.triggerButton,
        fields,
        this._data,
        this.form,
        this._resetSubject,
      );
    }
  }

  private _getFormFields(): string[] {
    return Array.from(this._formFieldsSet);
  }
}

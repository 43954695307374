import {
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { LayoutService } from '@modules/layout/services';

import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';

import { ReportsSubpageMessages } from './constants';

@Component({
  selector: 'mg-manager-overlay-reports-subpage',
  templateUrl: './manager-overlay-reports-subpage.component.html',
  styleUrls: ['./manager-overlay-reports-subpage.component.scss'],
})
export class ManagerOverlayReportsSubpageComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = ReportsSubpageMessages;

  /** General Observables */
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Media */
  public readonly media$ = this.mediaObserver.asObservable().pipe(
    takeUntil(this._destroyedSubject),
    map(change => change[0].mqAlias),
    distinctUntilChanged(),
  );

  /** Template Ref Forwarding */
  @ContentChild('header', { read: TemplateRef })
  header: TemplateRef<any>;
  @ContentChild('content', { read: TemplateRef })
  content: TemplateRef<any>;

  /** Inputs */
  @Input() loading: boolean;
  @Input() noBottomPadding: boolean;
  @Input() scheduleReportsButton = true;
  @Input() exportListButton = true;
  @Input() columnSettingsButton = true;
  @Input() dataService: any;

  /** Outputs */
  @Output() scheduleReportsPressed = new EventEmitter<void>();
  @Output() exportListPressed = new EventEmitter<void>();
  @Output() columnSettingsPressed = new EventEmitter<void>();

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public layout: LayoutService,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {}

  public get canDownloadExport(): boolean {
    return !this._runtime.isCordovaApp();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}

<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px">
  <mg-tools-actions-header
    *ngIf="person$ | async; else headerLoader"
    [person]="person$ | async"
    [activeHallPass]="activeHallPass$ | async"
    [onOwnProfile]="true">
    <mg-btn
      *ngIf="hallPassCreateAction?.hasPermission$ | async"
      variant="outlined"
      [iconSvg]="hallPassCreateAction.icon"
      (pressed)="hallPassCreateAction.onPress()">
      {{ MESSAGES.HALL_PASS_CREATE }}
    </mg-btn>
    <mg-btn
      *ngIf="selfCheckInAction?.hasPermission$ | async"
      variant="outlined"
      [iconSvg]="selfCheckInAction.icon"
      (pressed)="selfCheckInAction.onPress()">
      {{ selfCheckInAction.label }}
    </mg-btn>
    <ng-container *ngIf="hasAssignActions$ | async">
      <mg-btn
        class="dropdown-btn"
        [matMenuTriggerFor]="assignDropdown"
        variant="outlined"
        iconSvg="/assets/teacher-tools/assign.svg"
        iconSet="minga"
        iconRight="mg-drop-down-arrow">
        {{ MESSAGES.ASSIGN_BTN }}
      </mg-btn>
      <mat-menu #assignDropdown="matMenu">
        <ng-container *ngFor="let option of assignActions">
          <button
            *ngIf="option.hasPermission$ | async"
            class="action-dropdown"
            variant="outlined"
            (click)="option.onPress()">
            <img
              [src]="option.icon"
              [alt]="option.label" />
            <mg-text variant="body-md">{{ option.label }} </mg-text>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </mg-tools-actions-header>
  <ng-container
    *ngIf="(totalStatsCount$ | async) > 1; then metricsCards"></ng-container>

  <section class="schedule-stickers-groups">
    <div
      *ngIf="isScheduleEnabled$ | async"
      class="student-schedule">
      <mg-student-schedule-widget
        *ngIf="(loadingSchedule$ | async) === false; else loadingActivities"
        [scheduleItems]="scheduleItems$ | async"
        (viewAllPressed)="goToSchedule($event)"></mg-student-schedule-widget>
    </div>
    <div class="stickers-groups">
      <ng-container
        quantity="1"
        *ngIf="
          (totalStatsCount$ | async) === 1 &&
            (isScheduleEnabled$ | async) === true;
          then metricsCards
        "></ng-container>
      <mg-tools-stickers [personHash]="(person$ | async)?.hash">
      </mg-tools-stickers>
      <div class="tw-grid tw-gap-2">
        <ng-container
          quantity="1"
          *ngIf="
            (totalStatsCount$ | async) === 1 &&
              (isScheduleEnabled$ | async) === false;
            then metricsCards
          "></ng-container>
        <mg-tools-groups
          *ngIf="isGroupsEnabled$ | async"
          [personHash]="(person$ | async)?.hash">
        </mg-tools-groups>
      </div>
    </div>
  </section>
</section>

<!-- Metrics Template -->
<ng-template #metricsCards>
  <div
    *ngIf="
      (loadingStats$ | async) === false && userStats$ | async as stats;
      else loadingTemplate
    "
    class="metric-cards">
    <mg-tools-metrics-card
      *ngIf="isBehaviorEnabled$ | async"
      [metric]="'redeem-points'"
      [id]="'tools-dashboard-click-metric-redeemable-points'"
      [value]="stats?.redeemablePointCount"
      (pressed)="
        handleStatNavigate([
          TOOLS_ROUTES.ROOT,
          TOOLS_ROUTES.POINTS,
          TOOLS_ROUTES.YOUR_POINTS
        ])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      *ngIf="isConsequencesEnabled$ | async"
      [metric]="'overdue-consequence'"
      [id]="'tools-dashboard-click-metric-overdue-consequences'"
      [value]="stats?.overdueConsequenceCount"
      (pressed)="
        handleStatNavigate([TOOLS_ROUTES.ROOT, TOOLS_ROUTES.BEHAVIOURS])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      *ngIf="isConsequencesEnabled$ | async"
      [metric]="'active-consequence'"
      [id]="'tools-dashboard-click-metric-active-consequences'"
      [value]="stats?.activeConsequenceCount"
      (pressed)="
        handleStatNavigate([TOOLS_ROUTES.ROOT, TOOLS_ROUTES.BEHAVIOURS])
      ">
    </mg-tools-metrics-card>
    <mg-tools-metrics-card
      *ngIf="isFlexEnabled$ | async"
      [metric]="'unregistered-periods'"
      [id]="'tools-dashboard-click-metric-unregistered-periods'"
      [value]="stats?.unregisteredPeriodCount"
      (pressed)="
        handleStatNavigate([TOOLS_ROUTES.ROOT, TOOLS_ROUTES.FLEX_TIME])
      ">
    </mg-tools-metrics-card>
  </div>
</ng-template>

<!-- Placeholder Loading Template -->
<ng-template #loadingTemplate>
  <mg-tools-stats-placeholder
    [quantity]="totalStatsCount$ | async"></mg-tools-stats-placeholder>
</ng-template>

<ng-template #headerLoader>
  <mg-placeholder-card>
    <div class="tw-grid tw-gap-2.5">
      <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <mg-placeholder-header
          class="tw-w-2/3"
          size="medium"></mg-placeholder-header>
      </div>
      <mg-placeholder-button class="tw-w-1/3"></mg-placeholder-button>
    </div>
  </mg-placeholder-card>
</ng-template>

<ng-template #loadingActivities>
  <mg-placeholder-card>
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="8px">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <mg-placeholder-header
          class="w-32"
          size="small"></mg-placeholder-header>
        <mg-placeholder-button class="w-20"></mg-placeholder-button>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="4px">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <div
          fxLayout="column"
          fxLayoutGap="4px"
          fxFlex>
          <mg-placeholder-header
            size="small"
            class="w-1-3"></mg-placeholder-header>
          <mg-placeholder-text
            size="small "
            class="w-1-2"></mg-placeholder-text>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="4px">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <div
          fxLayout="column"
          fxLayoutGap="4px"
          fxFlex>
          <mg-placeholder-header
            size="small"
            class="w-1-3"></mg-placeholder-header>
          <mg-placeholder-text
            size="small "
            class="w-1-2"></mg-placeholder-text>
        </div>
      </div>
    </div>
  </mg-placeholder-card>
</ng-template>

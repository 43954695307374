import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { GroupRoutes } from 'src/app/groups/groups.types';
import { Group } from 'src/app/groups/models';
import { mgResolveImageUrl } from 'src/app/util/asset';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { MediaService } from '@shared/services/media';

import { ToolsGroupsModalData } from '../../types/shared-tools.types';

@Component({
  selector: 'mg-tools-groups-view-modal',
  templateUrl: './tools-groups-view-modal.component.html',
  styleUrls: ['./tools-groups-view-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsGroupsViewModalComponent implements OnInit {
  /** Observables */
  public groups$: Observable<Group[]>;

  /** Constructor */
  constructor(
    public media: MediaService,
    @Inject(MODAL_OVERLAY_DATA) private _data: ToolsGroupsModalData,
    private _modalOverlay: ModalOverlayRef,
    private _router: Router,
  ) {}

  public ngOnInit(): void {
    this.groups$ = this._data.groups$;
  }

  public viewGroup(personHash: string) {
    this._router.navigate([GroupRoutes.ROOT, GroupRoutes.VIEW, personHash]);
    this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public getGroupBanner(group: Group) {
    if (!group || (!group.banner && !group.bannerObject)) return '';

    const image = group.bannerObject
      ? group.bannerObject.getImage()
      : group.banner;

    return mgResolveImageUrl(image);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { Person } from 'minga/app/src/app/people';
import { PmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { ReportBaseAbstract } from 'src/app/components/manager-report/services/report-base.util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { GradesService } from 'src/app/services/Grades';

import { PointsManagerMessages } from '@modules/points-manager/constants';

import { FormSelectComponent } from '@shared/components/form';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';

import { PointsManagerRoutes } from '../../constants';
import { PointsManagerService } from '../../services';
import { PmRewardsService } from '../pm-rewards/services';
import {
  PM_REPORT_SELECT_OPTIONS,
  PM_REPORTS_FILTERS_INITIAL_STATE,
  PmReportsMessages,
} from './constants';
import { PmReportsService } from './services';

@Component({
  selector: 'mg-pm-reports',
  templateUrl: './pm-reports.component.html',
  styleUrls: ['./pm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PmReportsService],
})
export class PmReportsComponent
  extends ReportBaseAbstract
  implements OnDestroy
{
  @ViewChild(MultiPersonSearchComponent)
  private _peopleSearchComponent: MultiPersonSearchComponent;
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** Constants */
  public readonly PM_MESSAGES = PointsManagerMessages;
  public readonly MESSAGES = PmReportsMessages;
  public readonly REPORT_TYPES = ReportTypes;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** Media */
  public readonly media$ = this.mediaObserver.asObservable().pipe(
    takeUntil(this._destroyed),
    map(change => change[0].mqAlias),
    distinctUntilChanged(),
  );

  /** Select Options */
  public readonly gradeOptions$ = this._gradesService.grades$.pipe(
    takeUntil(this._destroyed),
    map(grades => grades.map(grade => ({ label: grade, value: grade }))),
  );
  public readonly teamsOptions$ = this._pmService.teams$.pipe(
    takeUntil(this._destroyed),
    map(teams => teams.map(team => ({ label: team.name, value: team.id }))),
  );
  public readonly rewardOptions$ = this._rewardService.rewardTypes$.pipe(
    takeUntil(this._destroyed),
    map(rewards => rewards.map(r => ({ label: r.name, value: r.id }))),
  );

  /** General Variables */
  public reportType = {
    active: ReportTypes.POINTS_HISTORY,
    options: PM_REPORT_SELECT_OPTIONS,
  };

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public pmReports: PmReportsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _gradesService: GradesService,
    private _pmService: PointsManagerService,
    private _analytics: AnalyticsService,
    private _rewardService: PmRewardsService,
    private _route: ActivatedRoute,
  ) {
    super();
    this._gradesService.fetchIfNeeded();
    this._pmService.fetchTeams();
    this._rewardService.fetchRewardTypes();
    this._activatedRoute.url.pipe(takeUntil(this._destroyed)).subscribe(x => {
      if (this._activatedRoute.children[0]) {
        this._activatedRoute.children[0].url
          .pipe(takeUntil(this._destroyed))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path as ReportTypes;
              const possiblePaths = this.reportType.options.map(
                type => type.value,
              );
              if (possiblePaths.includes(report)) {
                this.reportType.active = report;
              }
            }
          });
      }
    });
    this.pmReports.onPersonSelectedFromTable
      .pipe(takeUntil(this._destroyed))
      .subscribe(person => this._peopleSearchComponent.setHashValue([person]));

    this._initializeDates(
      PM_REPORTS_FILTERS_INITIAL_STATE,
      this._route.snapshot?.queryParams,
      this._destroyed,
      (range, fromChangeEvent) => {
        this.pmReports.setFilter(PmReportsFilter.START_DATE, range.start);
        this.pmReports.setFilter(PmReportsFilter.END_DATE, range.end);

        if (fromChangeEvent) {
          this.pmReports.applyFilter();
        }
      },
    );
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async changeReportType(type: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${PointsManagerRoutes.ROOT}/${PointsManagerRoutes.REPORTS}/${type}`,
    );
  }

  public changePersonHash(people: Person[]) {
    this.pmReports.setFilter(
      PmReportsFilter.ISSUED_TO,
      people.map(p => p.hash),
    );
  }

  public changeGrade(grades: string) {
    this.pmReports.setFilter(PmReportsFilter.GRADES, grades);
  }

  public changeTeams(teams: string) {
    this.pmReports.setFilter(PmReportsFilter.TEAMS, teams);
  }

  public changeRewards(rewards: number[]) {
    this.pmReports.setFilter(PmReportsFilter.REWARDS, rewards);
  }

  public changeUserList(list: number[]) {
    this.pmReports.setFilter(PmReportsFilter.USER_LIST, list);
  }

  public async clearFilter() {
    this._peopleSearchComponent.setValue([]);
    this.pmReports.clearFilter();

    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: PM_REPORTS_FILTERS_INITIAL_STATE.startDate,
      end: PM_REPORTS_FILTERS_INITIAL_STATE.endDate,
    });
  }

  public async applyFilter() {
    this.pmReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'points',
      reportType: this.reportType.active,
    });
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }
}

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import * as day from 'dayjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { getDateRangeFromQueryParams } from '@shared/utils';

@Injectable()
export abstract class ReportBaseAbstract {
  public range: FormGroup;

  constructor() {}

  protected _initializeDates(
    initialState,
    queryParams,
    destroyed$: ReplaySubject<void>,
    setRange: (
      range: { start: day.Dayjs; end: day.Dayjs },
      fromChangeEvent: boolean,
    ) => void,
  ) {
    const initialDates = getDateRangeFromQueryParams(queryParams, initialState);

    setRange(
      {
        start: initialDates.startDate,
        end: initialDates.endDate,
      },
      false,
    );

    this.range = initializeRange({
      start: {
        value: initialDates.startDate,
      },
      end: {
        value: initialDates.endDate,
      },
    });

    this.range.valueChanges.pipe(takeUntil(destroyed$)).subscribe(range => {
      setRange(range, true);
    });
  }
}

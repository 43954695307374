import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { FormModule } from '@shared/components/form';
import { PlaceholderModule } from '@shared/components/placeholder/placeholder.module';
import { CommonUIModules } from '@shared/modules';

import { SharedToolsModule } from '../shared-tools/shared-tools.module';
import { AtMyToolsComponent } from './components/at-my-tools/at-my-tools.component';
import { AtRootComponent } from './components/at-root/at-root.component';

@NgModule({
  imports: [
    CommonModule,
    CommonUIModules,
    SharedToolsModule,
    MatMenuModule,
    FormModule,
    PlaceholderModule,
  ],
  declarations: [AtRootComponent, AtMyToolsComponent],
  exports: [AtRootComponent, AtMyToolsComponent],
})
export class AdminToolsModule {}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { device$ } from '@lib/cordova/device';
import { indexOf, sortBy } from 'lodash';
import { Observable, ReplaySubject, Subject, from } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  throttleTime,
  toArray,
} from 'rxjs/operators';

import { badgeRoleNameToMingaRoleType } from 'minga/shared/auth/util';
import { MingaRoleType } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

import { HOME_BUTTON_IOS_MODEL_CODES } from '@shared/constants/homeButtonIphoneModels';
import {
  NavigationService,
  PrimaryNavigationRoute,
} from '@shared/services/navigation';

import {
  LAYOUT_NAVIGATION_DRAWER_TOGGLE_DEBOUNCE_TIME,
  LAYOUT_NAVIGATION_SORT_ORDER,
  MOBILE_LAYOUT_ADMIN_SORT_ORDER,
  VIEW_ID_ROUTE,
} from '../../constants';
import { LayoutService } from '../../services';

@Component({
  selector: 'mg-layout-primary-mobile-nav',
  templateUrl: './layout-primary-mobile-nav.component.html',
  styleUrls: ['./layout-primary-mobile-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPrimaryMobileNavComponent
  implements OnDestroy, AfterViewInit, OnInit
{
  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Host bindings
  @HostBinding('class.ios-bottom-safe-area') _enableSafeAreaPadding = false;

  /** Toggle drawer button clicked */
  private readonly _toggleDrawerClickedSubject = new Subject();
  private readonly _toggleDrawerClickedSubscription =
    this._getToggleDrawerClickedSubscription();

  // Outputs
  @Output()
  public readonly toggleDrawer = new EventEmitter<void>();

  public readonly sortedNavigationItems$ =
    this.navigation.primaryNavigationRoutes$.pipe(
      takeUntil(this._destroyedSubject),
      switchMap(routes => {
        const person = this._authInfo.authPerson;
        const roleType = badgeRoleNameToMingaRoleType(person?.badgeRoleName);
        const sortedRoutes = this._sortRoutes(routes, roleType);

        return this._filterRoutes(sortedRoutes);
      }),
    );

  /** Constructor */
  constructor(
    public router: Router,
    public layout: LayoutService,
    public navigation: NavigationService,
    private _authInfo: AuthInfoService,
    private _cdr: ChangeDetectorRef,
    private _elementRef: ElementRef,
  ) {}

  ngOnInit() {
    /**
     * TODO:
     * This component shouldn't have to know how to check for a home button,
     * it's beyond its responsibilities. Move this into AppRuntime, eg.
     * hasFeature('homebutton', 'ios')
     */
    device$.pipe(takeUntil(this._destroyedSubject)).subscribe(device => {
      if (!device?.model) return;
      const isHomeButtonPhone = HOME_BUTTON_IOS_MODEL_CODES.includes(
        device.model,
      );
      if (window.MINGA_APP_IOS && !isHomeButtonPhone) {
        this._enableSafeAreaPadding = true;
        this._cdr.detectChanges();
      }
    });
  }

  ngAfterViewInit() {
    this._initKeyboardListners();
  }

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._toggleDrawerClickedSubscription.unsubscribe();
  }

  public async handleToggleDrawerPressed() {
    this._toggleDrawerClickedSubject.next();
  }

  private _getToggleDrawerClickedSubscription() {
    return this._toggleDrawerClickedSubject
      .pipe(throttleTime(LAYOUT_NAVIGATION_DRAWER_TOGGLE_DEBOUNCE_TIME))
      .subscribe(() => {
        this.toggleDrawer.emit();
      });
  }

  private _initKeyboardListners() {
    window.addEventListener('keyboardWillShow', () => {
      this._elementRef.nativeElement.style.setProperty('display', 'none');
    });
    window.addEventListener('keyboardWillHide', () => {
      this._elementRef.nativeElement.style.setProperty('display', 'flex');
    });
  }

  private _sortRoutes(
    routes: PrimaryNavigationRoute[],
    roleType: MingaRoleType,
  ): PrimaryNavigationRoute[] {
    switch (roleType) {
      // show hallpass and behavior manager modules first
      case MingaRoleType.SUPERADMIN:
      case MingaRoleType.DISTRICT_MANAGER:
      case MingaRoleType.OWNER:
      case MingaRoleType.MANAGER:
      case MingaRoleType.TEACHER:
      case MingaRoleType.STAFF:
        return sortBy(routes, item =>
          indexOf(MOBILE_LAYOUT_ADMIN_SORT_ORDER, item.title),
        );

      // show shortcut for ID
      case MingaRoleType.STUDENT:
      case MingaRoleType.STUDENT_LEADER:
        const sortedRoutes = sortBy(routes, item =>
          indexOf(LAYOUT_NAVIGATION_SORT_ORDER, item.title),
        );
        sortedRoutes.splice(2, 0, VIEW_ID_ROUTE);
        return sortedRoutes;

      // show events and groups first
      default:
        return sortBy(routes, item =>
          indexOf(LAYOUT_NAVIGATION_SORT_ORDER, item.title),
        );
    }
  }

  // only show routes that the user has access to
  private _filterRoutes(
    routes: PrimaryNavigationRoute[],
  ): Observable<PrimaryNavigationRoute[]> {
    return from(routes).pipe(
      concatMap(route =>
        route.canAccess$.pipe(
          take(1),
          map(canAccess => (canAccess ? route : null)),
        ),
      ),
      filter(route => route !== null),
      toArray(),
    );
  }
}

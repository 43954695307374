import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { IMingaFeatureToggleKeys } from 'minga/domain/featureToggle';

import { MingaStatus } from '@modules/minga-manager/constants';

import { FormSelectOption } from '@shared/components/form';
import { US_STATES } from '@shared/constants/location';
import { getDateRangeFromQueryParams } from '@shared/utils';

import {
  MINGA_STATUS_LABEL_MAP,
  MINGA_MODULE_LABEL_MAP,
} from '../../../constants';
import { MingaManagerService } from '../../../services';
import { MmDistrictsService } from '../../mm-districts/services/mm-districts.service';
import {
  MmReportsFilter,
  MM_REPORTS_FILTERS_INITIAL_STATE,
} from '../constants';
import { MmReportsFilters } from '../types';

@Injectable()
export class MmReportsFiltersService implements OnDestroy {
  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Filters */
  private readonly _filters$ = new BehaviorSubject<MmReportsFilters>({
    ...MM_REPORTS_FILTERS_INITIAL_STATE,
    ...getDateRangeFromQueryParams(
      this._route.snapshot?.queryParams,
      MM_REPORTS_FILTERS_INITIAL_STATE,
    ),
  });
  public readonly filters$ = this._filters$.asObservable().pipe(shareReplay());

  /** Select Options */
  public readonly moduleOptions$: Observable<FormSelectOption<string>[]>;
  public readonly stateOptions$: Observable<FormSelectOption<string>[]>;
  public readonly districtOptions$: Observable<FormSelectOption<string>[]>;
  public readonly partnerOptions$: Observable<FormSelectOption<string>[]>;
  public readonly statusOptions$: Observable<FormSelectOption<string>[]>;
  public readonly subscriptionOptions$: Observable<any>;

  /** Form Controls */
  private readonly _name = new FormControl('', [Validators.maxLength(40)]);
  public get name() {
    return this._name;
  }

  /** Class Constructor */
  constructor(
    private _mingaManagerService: MingaManagerService,
    private _mmDistrictService: MmDistrictsService,
    private _route: ActivatedRoute,
  ) {
    this.stateOptions$ = of(US_STATES).pipe(
      map(states =>
        states.map(state => ({
          label: state,
          value: state,
        })),
      ),
    );
    this.moduleOptions$ = of($enum(IMingaFeatureToggleKeys).getValues()).pipe(
      map(modules =>
        modules.map(option => ({
          label: MINGA_MODULE_LABEL_MAP[option],
          value: option,
        })),
      ),
    );
    this.districtOptions$ = this._mmDistrictService.districts$.pipe(
      map(districts =>
        districts.map(district => ({
          label: district.name,
          value: district.name,
        })),
      ),
    );
    this.partnerOptions$ = this._mingaManagerService.mingaPartners$.pipe(
      map(partners =>
        partners.map(partner => ({
          label: partner.name,
          value: partner.name,
        })),
      ),
    );
    this.subscriptionOptions$ = this._mingaManagerService.subscriptions$.pipe(
      map(subscriptions =>
        subscriptions.map(subscription => ({
          label: subscription.name ?? '',
          value: subscription.planId ?? '',
        })),
      ),
    );
    this.statusOptions$ = of(MingaStatus as any).pipe(
      map(status =>
        Object.keys(status).map(option => ({
          label: MINGA_STATUS_LABEL_MAP[option],
          value: option,
        })),
      ),
    );
    this._name.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        debounceTime(250),
        distinctUntilChanged(),
      )
      .subscribe(val => this.setFilter(MmReportsFilter.HASH, val));
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._filters$.complete();
    this._destroyed$.complete();
  }

  public async getCurrentFilterState(): Promise<MmReportsFilters> {
    return this._filters$.getValue();
  }

  public async clearFilters(): Promise<void> {
    this._filters$.next({ ...MM_REPORTS_FILTERS_INITIAL_STATE });
  }

  public async setFilter(filter: MmReportsFilter, value: any): Promise<void> {
    const currentFilters = this._filters$.getValue() as any;
    currentFilters[filter] = value;
    this._filters$.next({ ...currentFilters });
  }
}

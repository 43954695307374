import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { EventStatus } from 'minga/domain/event';
import { ReportTypes } from 'minga/domain/reportTypes';

import { MODAL_OVERLAY_DATA } from '@shared/components/modal-overlay';
import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';

import { ManagerReportHeaderFilterReportType } from '../../manager-report/components';
import {
  EventReportFiltersKeys,
  EVENT_STATUS_OPTIONS,
} from './EventPeople.constants';
import { EventPeopleDatasource } from './EventPeople.datasource.service';
import { EventPeopleReportService } from './EventPeople.report.service';
import { EventPeopleDialogData } from './EventPeople.types';

@Component({
  selector: 'mg-event-people-component',
  templateUrl: './EventPeople.component.html',
  styleUrls: ['./EventPeople.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventPeopleDatasource],
})
export class EventPeopleComponent {
  eventTitle = '';

  eventStatus: ManagerReportHeaderFilterReportType = {
    active: EventStatus.CHECKED_IN,
    options: EVENT_STATUS_OPTIONS,
  };

  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: EventPeopleDialogData,
    public ds: EventPeopleDatasource,
    public reportService: EventPeopleReportService,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {
    this.eventTitle = dialogData.eventTitle;
    this.eventStatus.active = dialogData.status;

    if (dialogData.hasTickets) this.ds.setEventHasTickets();
    this.reportService.setFilter(
      EventReportFiltersKeys.EVENT_CONTEXT,
      dialogData.contextHash,
    );
    this.reportService.setFilter(
      EventReportFiltersKeys.PERSON_STATUS,
      this.eventStatus.active,
    );
    this.reportService.applyFilter();
  }

  onChangeEventStatus(status: EventStatus): void {
    this.eventStatus.active = status;
    this.reportService.setAndApplyFilter(
      EventReportFiltersKeys.PERSON_STATUS,
      status,
    );
  }

  public get canDownloadExport(): boolean {
    return !this._runtime.isCordovaApp();
  }

  exportReport() {
    this.reportService.exportReport(ReportTypes.EVENT_PEOPLE);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlaceholderSkeletonModule } from '../skeleton/placeholder-skeleton.module';
import { PlaceholderAvatarComponent } from './avatar/placeholder-avatar.component';
import { PlaceholderButtonComponent } from './button/placeholder-button.component';
import { PlaceholderHeaderComponent } from './header/placeholder-header.component';
import { PlaceholderTextComponent } from './text/placeholder-text.component';

@NgModule({
  imports: [CommonModule, PlaceholderSkeletonModule],
  declarations: [
    PlaceholderAvatarComponent,
    PlaceholderHeaderComponent,
    PlaceholderTextComponent,
    PlaceholderButtonComponent,
  ],
  exports: [
    PlaceholderAvatarComponent,
    PlaceholderHeaderComponent,
    PlaceholderTextComponent,
    PlaceholderButtonComponent,
  ],
})
export class PlaceholderElementsModule {}

<mg-card
  paddingX="4"
  paddingY="4">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="12px">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-text variant="header-md-secondary">
        {{ MESSAGES.SCHEDULE_TITLE }}
      </mg-text>
      <!-- TODO change pressed to goToSchedule(), when schedule is enabled -->
      <mg-btn
        variant="outlined"
        size="small"
        (pressed)="goToSchedule({ isFlexActivity: true })">
        {{ MESSAGES.SCHEDULE_BUTTON_LABEL }}
      </mg-btn>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="4px">
      <ng-container *ngIf="scheduleItems.length > 0; else emptyStateTemplate">
        <button
          *ngFor="let schedule of scheduleItems"
          [title]="schedule.course"
          class="schedule btn-reset"
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="8px"
          (click)="goToSchedule(schedule)">
          <div
            class="schedule-icon"
            fxLayoutAlign="center center"
            fxFlex="none">
            <mg-icon
              class="icon"
              [iconName]="
                schedule.isFlexActivity ? 'mg-flextime-menu-o' : 'mg-schedule'
              ">
            </mg-icon>
          </div>

          <div
            class="schedule-details"
            fxLayout="column">
            <mg-text
              variant="header-sm"
              matTooltipClass="mg-tooltip"
              [matTooltip]="schedule.course"
              [numberOfLines]="1"
              spacing="0-half">
              {{ schedule.course }}
            </mg-text>
            <mg-text
              variant="body-sm"
              [numberOfLines]="1">
              Period: {{ schedule.period }}</mg-text
            >
            <mg-text
              *ngIf="schedule.startTime && schedule.endTime"
              variant="body-sm"
              [numberOfLines]="">
              Time: {{ schedule | formatTimeRange }}
            </mg-text>
            <mg-text
              *ngIf="schedule.room"
              variant="body-sm"
              [numberOfLines]="1">
              Room: {{ schedule.room }}</mg-text
            >
          </div>
        </button>
      </ng-container>

      <ng-template #emptyStateTemplate>
        <mg-empty-state
          [title]="MESSAGES.EMPTY_SCHEDULE_TITLE"
          [subtitle]="MESSAGES.EMPTY_SCHEDULE_DESC">
        </mg-empty-state>
      </ng-template>
    </div>
  </div>
</mg-card>

import { SelectOption } from 'src/app/components/manager-report/components';

import { ToggleMenuData } from '../types';

export enum MmDashboardMessages {
  PAGE_TITLE = 'Dashboard',

  CLEAR_FILTER_BUTTON_LABEL = 'Clear filters',
  APPLY_FILTER_BUTTON_LABEL = 'Apply filters',

  ADD_NEW_BUTTON_LABEL = 'Add new',
  NEW_MINGA_BUTTON_LABEL = 'Minga',
  NEW_PARTNER_BUTTON_LABEL = 'Partner',
  NEW_DISTRICT_BUTTON_LABEL = 'District',

  SEARCH_INPUT_LABEL = 'Search by name',
  SEARCH_INPUT_PLACEHOLDER = 'Search by name',

  BUTTON_LABEL_ADD_MINGA = 'Add new minga',
  BUTTON_LABEL_VIEW_ALL_FILTERS = 'View all filters',
  BUTTON_LABEL_REFRESH = 'Refresh dashboard',
  BUTTON_LABEL_EXPORT = 'Export list',

  COLUMN_SETTINGS_BUTTON = 'Column settings',

  COLUMN_TOGGLE_SHOW_COLUMNS = 'Show Columns:',
  COLUMN_TOGGLE_ACTIVITY_SECTION = 'Activity:',
  COLUMN_TOGGLE_ACTIVITY = 'Activity',
  COLUMN_TOGGLE_ACTIVITY_PCT = '% Activity',
  COLUMN_TOGGLE_MODULES = 'Modules',
  COLUMN_TOGGLE_USER_SECTION = 'User Data:',
  COLUMN_TOGGLE_USER = 'User Data',
  COLUMN_TOGGLE_USER_PCT = '% User Data',
  COLUMN_TOGGLE_HEALTH_SCORE = 'Health Score Data:',
  COLUMN_TOGGLE_ID = 'ID:',
  COLUMN_TOGGLE_HALL_PASS = 'Hall Pass:',
  COLUMN_TOGGLE_COMMUNITY = 'Community:',
  COLUMN_TOGGLE_BEHAVIORS = 'Behaviors:',
  COLUMN_TOGGLE_POINTS = 'Points:',
  COLUMN_TOGGLE_CHECK_IN = 'Check In:',
  COLUMN_TOGGLE_FLEX = 'FlexTime:',
  COLUMN_TOGGLE_USER_LIST_COUNT = 'User Lists',

  MODULE_TITLE_DIGITAL_IDS = 'Digital IDs',
  MODULE_TITLE_HALL_PASS = 'Hall Pass',
  MODULE_TITLE_HALL_PASS_DENIED = 'Denied Hall Pass',
  MODULE_TITLE_COMMUNITY = 'Community',
  MODULE_TITLE_PBIS = 'PBIS',
  MODULE_TITLE_TRACKING = 'Tracking',

  MODULE_INPUT_PLACEHOLDER = 'Module',
  DISTRICT_INPUT_PLACEHOLDER = 'District',
  STATE_INPUT_PLACEHOLDER = 'State',
  STATUS_INPUT_PLACEHOLDER = 'Status',
  TYPE_INPUT_PLACEHOLDER = 'Type',
  PARTNER_INPUT_PLACEHOLDER = 'Partner',
  SUBSCRIPTION_INPUT_PLACEHOLDER = 'Subscription',

  HIDE_FILTERS = 'Hide filters',
  SHOW_FILTERS = 'Show filters',
}

/** @deprecated use MmDashboardTableColumnToMessage, which uses columns as keys */
export enum MmDashboardTableMessages {
  EMPTY_STATE_TITLE = 'No mingas found',
  EMPTY_STATE_SUBTITLE = 'Create a minga or try resetting your search filters',

  COLUMN_LABEL_GO = 'Go',
  COLUMN_LABEL_NAME = 'Name',
  COLUMN_LABEL_DISTRICT = 'District',
  COLUMN_LABEL_CITY = 'City',
  COLUMN_LABEL_STATE = 'State',
  COLUMN_LABEL_PARTNER = 'Partner',
  COLUMN_LABEL_STATUS = 'Status',
  COLUMN_LABEL_TYPE = 'Type',
  COLUMN_LABEL_REGISTERED = 'Registered People',
  COLUMN_LABEL_PEOPLE = 'Total People',
  COLUMN_LABEL_ACTIVITY_DAILY = 'Daily Active',
  COLUMN_LABEL_ACTIVITY_WEEKLY = 'Weekly Active',
  COLUMN_LABEL_ACTIVITY_MONTHLY = 'Monthly Active',
  COLUMN_LABEL_PHOTOS = 'Photos',
  COLUMN_LABEL_STICKERS = 'Stickers',
  COLUMN_LABEL_HALL_PASSES = 'Hall Passes',
  COLUMN_LABEL_HALL_PASSES_DENIED = 'Denied Hall Passes',
  COLUMN_LABEL_POSTS = 'Posts',
  COLUMN_LABEL_GROUPS = 'Groups',
  COLUMN_LABEL_CHECKIN = 'Check Ins',
  COLUMN_LABEL_BEHAVIORS = 'Behaviors',
  COLUMN_LABEL_SIZE = 'Subscription',
  COLUMN_LABEL_TOTAL_STUDENTS = 'Total Students',
  COLUMN_LABEL_REGISTERED_STUDENTS = 'Registered Students',
  COLUMN_LABEL_REGISTERED_STUDENTS_PCT = 'Registered Students %',
  COLUMN_LABEL_UNREGISTERED_STUDENTS = 'Unregistered Students',
  COLUMN_LABEL_UNREGISTERED_STUDENTS_PCT = 'Unregistered Students %',
  COLUMN_LABEL_TOTAL_STAFF = 'Total Staff',
  COLUMN_LABEL_REGISTERED_STAFF = 'Registered Staff',
  COLUMN_LABEL_REGISTERED_STAFF_PCT = 'Registered Staff %',
  COLUMN_LABEL_UNREGISTERED_STAFF = 'Unregistered Staff',
  COLUMN_LABEL_UNREGISTERED_STAFF_PCT = 'Unregistered Staff %',
  COLUMN_LABEL_REGISTERED_PCT = 'Registered People %',
  COLUMN_LABEL_UNREGISTERED = 'Unregistered People',
  COLUMN_LABEL_UNREGISTERED_PCT = 'Unregistered People %',
  COLUMN_LABEL_ACTIVITY_DAILY_PCT = 'Daily Active %',
  COLUMN_LABEL_ACTIVITY_WEEKLY_PCT = 'Weekly Active %',
  COLUMN_LABEL_ACTIVITY_MONTHLY_PCT = 'Monthly Active %',
  COLUMN_LABEL_DATE = 'Date Created',
  COLUMN_LABEL_SIS = 'SIS',
  COLUMN_LABEL_HUB_SPOT = 'HubSpot Id',
  COLUMN_LABEL_DISTRICT_ID = 'HubSpot District Id',
  COLUMN_LABEL_SUBSCRIPTION_END_DATE = 'Subscription End Date',
  COLUMN_LABEL_SUBSCRIPTION_START_DATE = 'Subscription Start Date',

  BUTTON_VIEW_MORE = 'View More Details',

  COLUMN_LABEL_HP = 'HP',
  COLUMN_LABEL_ID = 'ID',
  COLUMN_LABEL_BH = 'PBIS',
  COLUMN_LABEL_CH = 'CH',
  COLUMN_LABEL_CM = 'COMM',
  COLUMN_LABEL_FL = 'FLEX',
  COLUMN_LABEL_SMS = 'SMS',
}

export enum MmDashboardTableColumn {
  GO_MINGA = 'hash',
  MINGA_NAME = 'name',
  DISTRICT = 'district',
  DISTRICT_ID = 'districtId',
  CITY = 'city',
  STATE = 'state',
  PARTNER = 'partner',
  TYPE = 'type',
  STATUS = 'status',
  DAILY_ACTIVE = 'dailyActiveCount',
  WEEKLY_ACTIVE = 'weeklyActiveCount',
  MONTHLY_ACTIVE = 'monthlyActiveCount',
  STICKER_COUNT = 'stickerCount',
  HALL_PASS_COUNT = 'mingaHealthCheckStats.hallPassCountInLast30Days',
  HALL_PASS_DENIED_COUNT = 'mingaHealthCheckStats.hallPassDeniedCountInLast30Days',
  POSTS_COUNT = 'mingaHealthCheckStats.postCountInLast30Days',
  GROUPS_COUNT = 'mingaHealthCheckStats.groupCount',
  CHECKIN_COUNT = 'mingaHealthCheckStats.checkinCount',
  BEHAVIOR_COUNT = 'mingaHealthCheckStats.numberOfBehaviorsInLast30Days',
  SIZE = 'size',
  TOTAL_STUDENTS = 'totalStudents',
  REGISTERED_STUDENTS = 'registeredStudents',
  REGISTERED_STUDENTS_PCT = 'registeredStudentsPct',
  UNREGISTERED_STUDENTS = 'unregisteredStudents',
  UNREGISTERED_STUDENTS_PCT = 'unregisteredStudentsPct',
  TOTAL_STAFF = 'totalStaff',
  REGISTERED_STAFF = 'registeredStaff',
  REGISTERED_STAFF_PCT = 'registeredStaffPct',
  UNREGISTERED_STAFF = 'unregisteredStaff',
  UNREGISTERED_STAFF_PCT = 'unregisteredStaffPct',
  TOTAL_COUNT = 'totalCount',
  REGISTERED_COUNT = 'registeredCount',
  REGISTERED_PCT = 'registeredPct',
  UNREGISTERED = 'unregistered',
  UNREGISTERED_PCT = 'unregisteredPct',
  DAILY_ACTIVE_PCT = 'dailyActivePct',
  WEEKLY_ACTIVE_PCT = 'weeklyActivePct',
  MONTHLY_ACTIVE_PCT = 'monthlyActivePct',
  DATE_CREATED = 'createdAt',
  SIS = 'sisType',
  SUBSCRIPTION_END_DATE = 'subscriptionEndDate',
  SUBSCRIPTION_START_DATE = 'subscriptionStartDate',

  ID = 'featureToggle.studentIdEnabled',
  HP = 'featureToggle.hallPassEnabled',
  BH = 'featureToggle.trackingEnabled',
  FL = 'featureToggle.flexTimeEnabled',
  CH = 'featureToggle.checkinEnabled',
  CM = 'featureToggle.communityEnabled',

  MOBILE = 'MOBILE',
  HUB_SPOT_ID = 'hubSpotId',

  SIS_SCORE = 'mingaHealthCheckStats.sisScore.score',
  MONTHLY_ACTIVE_USERS_SCORE = 'mingaHealthCheckStats.monthlyActiveUsersScore.score',
  STUDENTS_REGISTERED_SCORE = 'mingaHealthCheckStats.studentsRegisteredScore.score',
  TOTAL_USERS_ADDED_SCORE = 'mingaHealthCheckStats.totalUsersAddedScore.score',
  HALL_PASS_SCORE = 'mingaHealthCheckStats.hallPassScore.score',
  POST_SCORE = 'mingaHealthCheckStats.postScore.score',
  GROUP_SCORE = 'mingaHealthCheckStats.groupScore.score',
  BEHAVIOR_SCORE = 'mingaHealthCheckStats.behaviorScore.score',
  CONSEQUENCE_SCORE = 'mingaHealthCheckStats.consequenceScore.score',
  POINT_ACTION_SCORE = 'mingaHealthCheckStats.pointActionScore.score',
  POINTS_RECEIVED_SCORE = 'mingaHealthCheckStats.pointsReceivedScore.score',
  CHECK_IN_REASON_SCORE = 'mingaHealthCheckStats.checkInReasonScore.score',
  CHECK_IN_EVENT_SCORE = 'mingaHealthCheckStats.checkInEventScore.score',
  FLEX_REGISTERED_SCORE = 'mingaHealthCheckStats.flexRegisteredScore.score',
  FLEX_CHECK_IN_SCORE = 'mingaHealthCheckStats.flexCheckInScore.score',
  MINGA_SCORE = 'mingaHealthCheckStats.mingaScore.score',
  MINGA_SCORE_PCT = 'mingaHealthCheckStats.mingaScorePct.score',
  GENERAL_SCORE = 'mingaHealthCheckStats.generalScore.score',
  HALL_PASS_AVERAGE_SCORE = 'mingaHealthCheckStats.hallPassAvgScore.score',

  TOTAL_USERS_ADDED = 'mingaHealthCheckStats.totalUsersAddedPercent',
  BEHAVIOR_AVERAGE = 'mingaHealthCheckStats.behaviorAvg',
  CONSEQUENCE_COUNT = 'mingaHealthCheckStats.numberOfConsquencesIssuedByAutomationInLast30Days',
  CONSEQUENCE_AVERAGE = 'mingaHealthCheckStats.consequenceAvg',
  POINT_ACTION_COUNT = 'mingaHealthCheckStats.pointActionCount',
  POINT_ACTION_AVERAGE = 'mingaHealthCheckStats.pointActionAvg',
  POINTS_RECEIVED_PCT = 'mingaHealthCheckStats.studentsPointsReceivedPercent',
  CHECKIN_AVERAGE = 'mingaHealthCheckStats.checkInReasonAvg',
  CHECK_IN_EVENT_COUNT = 'mingaHealthCheckStats.checkInEventCount',
  CHECK_IN_EVENT_AVERAGE = 'mingaHealthCheckStats.checkInEventAvg',
  FLEX_REGISTERED_COUNT = 'mingaHealthCheckStats.flexRegistrationCount',
  FLEX_REGISTERED_AVERAGE = 'mingaHealthCheckStats.flexRegisteredAvg',
  FLEX_CHECK_IN_COUNT = 'mingaHealthCheckStats.flexCheckinCount',
  FLEX_CHECK_IN_AVERAGE = 'mingaHealthCheckStats.flexCheckInAvg',
  HALL_PASS_AVERAGE = 'mingaHealthCheckStats.hallPassAvg',
  USER_LIST_COUNT = 'mingaHealthCheckStats.userListCount',

  SMS_SENT = 'smsCount',
  SMS_REMAINING = 'smsRemaining',
  SIS_SYNC = 'sisSync',
  SIS_LIST_ACTIVE = 'sisListActive',
  SIS_LIST_COUNT = 'sisListCount',
}

export const MmDashboardTableColumnToMessage: Record<
  MmDashboardTableColumn,
  string
> = {
  name: 'Name',
  district: 'District',
  [MmDashboardTableColumn.DISTRICT_ID]: 'Hubspot District Id',
  city: 'City',
  state: 'State',
  partner: 'Partner',
  status: 'Status',
  type: 'Type',
  createdAt: 'Date Created',
  registeredCount: 'Registered',
  size: 'Subscription',
  dailyActiveCount: 'Daily',
  weeklyActiveCount: 'Weekly',
  monthlyActiveCount: 'Monthly',
  stickerCount: 'Stickers',
  [MmDashboardTableColumn.USER_LIST_COUNT]: 'User Lists',
  [MmDashboardTableColumn.HALL_PASS_COUNT]: 'Hall Passes',
  [MmDashboardTableColumn.HALL_PASS_DENIED_COUNT]: 'Denied Hall Passes',
  [MmDashboardTableColumn.POSTS_COUNT]: 'Posts',
  [MmDashboardTableColumn.GROUPS_COUNT]: 'Groups',
  [MmDashboardTableColumn.CHECKIN_COUNT]: 'Check Ins',
  [MmDashboardTableColumn.BEHAVIOR_COUNT]: 'Behaviors',
  totalStudents: 'Total Students',
  registeredStudents: 'Registered Students',
  [MmDashboardTableColumn.REGISTERED_STUDENTS_PCT]: '% Registered Students',
  unregisteredStudents: 'Unregistered Students',
  [MmDashboardTableColumn.UNREGISTERED_STUDENTS_PCT]: '% Unregistered Students',
  totalStaff: 'Total Staff',
  registeredStaff: 'Registered Staff',
  [MmDashboardTableColumn.REGISTERED_STAFF_PCT]: '% Registered Staff',
  unregisteredStaff: 'Unregistered Staff',
  [MmDashboardTableColumn.UNREGISTERED_STAFF_PCT]: '% Unregistered Staff',
  [MmDashboardTableColumn.REGISTERED_PCT]: '% Registered',
  unregistered: 'Unregistered People',
  [MmDashboardTableColumn.UNREGISTERED_PCT]: '% Unregistered',
  [MmDashboardTableColumn.DAILY_ACTIVE_PCT]: '% Daily Active',
  [MmDashboardTableColumn.WEEKLY_ACTIVE_PCT]: '% Weekly Active',
  [MmDashboardTableColumn.MONTHLY_ACTIVE_PCT]: '% Monthly Active',
  sisType: 'SIS',
  ['featureToggle.hallPassEnabled']: 'HP',
  ['featureToggle.studentIdEnabled']: 'ID',
  ['featureToggle.trackingEnabled']: 'BH',
  ['featureToggle.checkinEnabled']: 'CH',
  ['featureToggle.flexTimeEnabled']: 'FL',
  ['featureToggle.communityEnabled']: 'CM',
  hubSpotId: 'HubSpot ID',
  subscriptionStartDate: 'Subscription Start Date',
  subscriptionEndDate: 'Subscription End Date',
  [MmDashboardTableColumn.MINGA_SCORE_PCT]: '% Health Score',
  [MmDashboardTableColumn.MINGA_SCORE]: 'Health Score',
  [MmDashboardTableColumn.GENERAL_SCORE]: 'General Score',
  [MmDashboardTableColumn.MONTHLY_ACTIVE_USERS_SCORE]: '% Monthly Active Score',
  [MmDashboardTableColumn.HALL_PASS_SCORE]: 'Hall Pass Score',
  [MmDashboardTableColumn.HALL_PASS_AVERAGE_SCORE]: 'Avg. Hall Pass Score',
  [MmDashboardTableColumn.POST_SCORE]: 'Posts Score',
  [MmDashboardTableColumn.GROUP_SCORE]: 'Groups Score',
  [MmDashboardTableColumn.BEHAVIOR_SCORE]: 'Avg. Behaviors Score',
  [MmDashboardTableColumn.BEHAVIOR_AVERAGE]: 'Avg. Behaviors',
  [MmDashboardTableColumn.CONSEQUENCE_SCORE]: 'Avg. Auto Consequences Score',
  [MmDashboardTableColumn.CONSEQUENCE_AVERAGE]: 'Avg. Auto Consequences',
  [MmDashboardTableColumn.CONSEQUENCE_COUNT]: 'Auto Consequences',
  [MmDashboardTableColumn.POINT_ACTION_SCORE]: 'Avg. Point Actions Score',
  [MmDashboardTableColumn.POINT_ACTION_AVERAGE]: 'Avg. Point Actions',
  [MmDashboardTableColumn.POINTS_RECEIVED_PCT]: '% Student Points',
  [MmDashboardTableColumn.POINTS_RECEIVED_SCORE]: '% Student Points Score',
  [MmDashboardTableColumn.CHECK_IN_REASON_SCORE]: 'Avg. Check Ins Score',
  [MmDashboardTableColumn.CHECKIN_AVERAGE]: 'Avg. Check Ins',
  [MmDashboardTableColumn.CHECK_IN_EVENT_SCORE]: 'Avg. Event Check Ins Score',
  [MmDashboardTableColumn.CHECK_IN_EVENT_COUNT]: 'Event Check Ins',
  [MmDashboardTableColumn.CHECK_IN_EVENT_AVERAGE]: 'Avg. Event Check Ins',
  [MmDashboardTableColumn.FLEX_REGISTERED_SCORE]: 'Avg. Flex Registered Score',
  [MmDashboardTableColumn.FLEX_REGISTERED_COUNT]: 'Flex Registrations',
  [MmDashboardTableColumn.FLEX_REGISTERED_AVERAGE]: 'Avg. Flex Registrations',
  [MmDashboardTableColumn.FLEX_CHECK_IN_SCORE]: 'Avg. Flex Check Ins Score',
  [MmDashboardTableColumn.FLEX_CHECK_IN_COUNT]: 'Flex Check Ins',
  [MmDashboardTableColumn.FLEX_CHECK_IN_AVERAGE]: 'Avg. Flex Check Ins',
  [MmDashboardTableColumn.TOTAL_USERS_ADDED]: '% Students Added',
  [MmDashboardTableColumn.TOTAL_USERS_ADDED_SCORE]: '% Students Added Score',
  [MmDashboardTableColumn.HALL_PASS_AVERAGE]: 'Avg. Hall Passes',
  [MmDashboardTableColumn.SMS_SENT]: 'SMS Sent',
  [MmDashboardTableColumn.SMS_REMAINING]: 'SMS Remaining',
  [MmDashboardTableColumn.SIS_SCORE]: 'SIS Sync Score',
  [MmDashboardTableColumn.GO_MINGA]: '',
  [MmDashboardTableColumn.POINT_ACTION_COUNT]: 'Point Actions',
  [MmDashboardTableColumn.STUDENTS_REGISTERED_SCORE]:
    '% Registered Students Score',
  [MmDashboardTableColumn.MOBILE]: '',
  [MmDashboardTableColumn.TOTAL_COUNT]: '',
  [MmDashboardTableColumn.SIS_SYNC]: 'SIS Sync',
  [MmDashboardTableColumn.SIS_LIST_ACTIVE]: 'List Sync',
  [MmDashboardTableColumn.SIS_LIST_COUNT]: 'SIS List Count',
};

export enum MmDashboardEditMessages {
  EDIT_TITLE = 'Edit Minga',

  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',

  NAME_INPUT_LABEL = 'Name',
  PARTNER_NAME_INPUT_PLACEHOLDER = 'Partner Name',
  DISTRICT_NAME_INPUT_PLACEHOLDER = 'District Name',
}

export enum MmDashboardEditModalType {
  MINGA = 'MINGA',
  PARTNER = 'PARTNER',
}

export enum MmDashboardColumnPresets {
  ALL = 'ALL',
  HEALTH = 'HEALTH',
}

export const MM_DASHBOARD_COLUMN_PRESET_OPTIONS: SelectOption[] = [
  { label: 'All data', value: MmDashboardColumnPresets.ALL },
  { label: 'Health Score', value: MmDashboardColumnPresets.HEALTH },
];

export const MM_DASHBOARD_EDIT_MODAL_TITLE_MAP: Record<
  MmDashboardEditModalType,
  string
> = {
  PARTNER: 'Create Minga Partner',
  MINGA: 'Create New Minga',
};

export const MM_DASHBOARD_TABLE_STICKY_COLUMNS: MmDashboardTableColumn[] = [
  MmDashboardTableColumn.GO_MINGA,
  MmDashboardTableColumn.MINGA_NAME,
  MmDashboardTableColumn.DISTRICT,
  MmDashboardTableColumn.DISTRICT_ID,
  MmDashboardTableColumn.CITY,
  MmDashboardTableColumn.STATE,
  MmDashboardTableColumn.DATE_CREATED,
  MmDashboardTableColumn.SUBSCRIPTION_START_DATE,
  MmDashboardTableColumn.SUBSCRIPTION_END_DATE,
  MmDashboardTableColumn.PARTNER,
];

export const TOGGLE_COLUMNS_OPTIONS: ToggleMenuData = [
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_SHOW_COLUMNS,
    options: [
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_DISTRICT,
        value: [MmDashboardTableColumn.DISTRICT],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_DISTRICT_ID,
        value: [MmDashboardTableColumn.DISTRICT_ID],
        sa: true,
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_CITY,
        value: [MmDashboardTableColumn.CITY],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_STATE,
        value: [MmDashboardTableColumn.STATE],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_DATE,
        value: [MmDashboardTableColumn.DATE_CREATED],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_SUBSCRIPTION_START_DATE,
        value: [MmDashboardTableColumn.SUBSCRIPTION_START_DATE],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_SUBSCRIPTION_END_DATE,
        value: [MmDashboardTableColumn.SUBSCRIPTION_END_DATE],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_PARTNER,
        value: [MmDashboardTableColumn.PARTNER],
        sa: true,
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_TYPE,
        value: [MmDashboardTableColumn.TYPE],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_STATUS,
        value: [MmDashboardTableColumn.STATUS],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_HUB_SPOT,
        value: [MmDashboardTableColumn.HUB_SPOT_ID],
        sa: true,
      },
      {
        label: MmDashboardMessages.COLUMN_TOGGLE_USER_LIST_COUNT,
        value: [MmDashboardTableColumn.USER_LIST_COUNT],
      },
      {
        label: MmDashboardMessages.COLUMN_TOGGLE_MODULES,
        value: [
          MmDashboardTableColumn.ID,
          MmDashboardTableColumn.BH,
          MmDashboardTableColumn.CH,
          MmDashboardTableColumn.HP,
          MmDashboardTableColumn.CM,
          MmDashboardTableColumn.SIS_SYNC,
          MmDashboardTableColumn.FL,
          MmDashboardTableColumn.SIS,
          MmDashboardTableColumn.SIS_LIST_ACTIVE,
          MmDashboardTableColumn.SIS_LIST_COUNT,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_USER_SECTION,
    options: [
      {
        label: MmDashboardMessages.COLUMN_TOGGLE_USER,
        value: [
          MmDashboardTableColumn.SIZE,
          MmDashboardTableColumn.TOTAL_STUDENTS,
          MmDashboardTableColumn.REGISTERED_STUDENTS,
          MmDashboardTableColumn.UNREGISTERED_STUDENTS,
          MmDashboardTableColumn.TOTAL_STAFF,
          MmDashboardTableColumn.REGISTERED_STAFF,
          MmDashboardTableColumn.UNREGISTERED_STAFF,
          MmDashboardTableColumn.REGISTERED_COUNT,
          MmDashboardTableColumn.UNREGISTERED,
          MmDashboardTableColumn.TOTAL_USERS_ADDED,
          MmDashboardTableColumn.TOTAL_COUNT,
        ],
      },
      {
        label: MmDashboardMessages.COLUMN_TOGGLE_USER_PCT,
        value: [
          MmDashboardTableColumn.REGISTERED_STUDENTS_PCT,
          MmDashboardTableColumn.UNREGISTERED_STUDENTS_PCT,
          MmDashboardTableColumn.UNREGISTERED_STAFF_PCT,
          MmDashboardTableColumn.REGISTERED_PCT,
          MmDashboardTableColumn.UNREGISTERED_PCT,
          MmDashboardTableColumn.REGISTERED_STAFF_PCT,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_ACTIVITY_SECTION,
    options: [
      {
        label: MmDashboardMessages.COLUMN_TOGGLE_ACTIVITY,
        value: [
          MmDashboardTableColumn.DAILY_ACTIVE,
          MmDashboardTableColumn.WEEKLY_ACTIVE,
          MmDashboardTableColumn.MONTHLY_ACTIVE,
        ],
      },
      {
        label: MmDashboardMessages.COLUMN_TOGGLE_ACTIVITY_PCT,
        value: [
          MmDashboardTableColumn.DAILY_ACTIVE_PCT,
          MmDashboardTableColumn.WEEKLY_ACTIVE_PCT,
          MmDashboardTableColumn.MONTHLY_ACTIVE_PCT,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_ID,
    options: [
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_STICKERS,
        value: [MmDashboardTableColumn.STICKER_COUNT],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_HALL_PASS,
    options: [
      {
        label: MmDashboardMessages.MODULE_TITLE_HALL_PASS,
        value: [
          MmDashboardTableColumn.HALL_PASS_COUNT,
          MmDashboardTableColumn.HALL_PASS_AVERAGE,
        ],
      },
      {
        label: MmDashboardMessages.MODULE_TITLE_HALL_PASS_DENIED,
        value: [
          MmDashboardTableColumn.HALL_PASS_DENIED_COUNT,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_COMMUNITY,
    options: [
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_POSTS,
        value: [MmDashboardTableColumn.POSTS_COUNT],
      },
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_GROUPS,
        value: [MmDashboardTableColumn.GROUPS_COUNT],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_BEHAVIORS,
    options: [
      {
        label: MmDashboardTableMessages.COLUMN_LABEL_BEHAVIORS,
        value: [
          MmDashboardTableColumn.BEHAVIOR_COUNT,
          MmDashboardTableColumn.BEHAVIOR_AVERAGE,
        ],
      },
      {
        label:
          MmDashboardTableColumnToMessage[
            MmDashboardTableColumn.CONSEQUENCE_COUNT
          ],
        sa: true,
        value: [
          MmDashboardTableColumn.CONSEQUENCE_COUNT,
          MmDashboardTableColumn.CONSEQUENCE_AVERAGE,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_POINTS,
    sa: true,
    options: [
      {
        label:
          MmDashboardTableColumnToMessage[
            MmDashboardTableColumn.POINT_ACTION_COUNT
          ],
        value: [
          MmDashboardTableColumn.POINT_ACTION_COUNT,
          MmDashboardTableColumn.POINT_ACTION_AVERAGE,
        ],
      },
      {
        label:
          MmDashboardTableColumnToMessage[
            MmDashboardTableColumn.POINTS_RECEIVED_PCT
          ],
        value: [MmDashboardTableColumn.POINTS_RECEIVED_PCT],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_CHECK_IN,
    options: [
      {
        label:
          MmDashboardTableColumnToMessage[MmDashboardTableColumn.CHECKIN_COUNT],
        value: [
          MmDashboardTableColumn.CHECKIN_COUNT,
          MmDashboardTableColumn.CHECKIN_AVERAGE,
        ],
      },
      {
        label:
          MmDashboardTableColumnToMessage[
            MmDashboardTableColumn.CHECK_IN_EVENT_COUNT
          ],
        sa: true,
        value: [
          MmDashboardTableColumn.CHECK_IN_EVENT_COUNT,
          MmDashboardTableColumn.CHECK_IN_EVENT_AVERAGE,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_FLEX,
    sa: true,
    options: [
      {
        label:
          MmDashboardTableColumnToMessage[
            MmDashboardTableColumn.FLEX_REGISTERED_COUNT
          ],
        value: [
          MmDashboardTableColumn.FLEX_REGISTERED_COUNT,
          MmDashboardTableColumn.FLEX_REGISTERED_AVERAGE,
        ],
      },
      {
        label:
          MmDashboardTableColumnToMessage[
            MmDashboardTableColumn.FLEX_CHECK_IN_COUNT
          ],
        value: [
          MmDashboardTableColumn.FLEX_CHECK_IN_COUNT,
          MmDashboardTableColumn.FLEX_CHECK_IN_AVERAGE,
        ],
      },
    ],
  },
  {
    title: MmDashboardMessages.COLUMN_TOGGLE_HEALTH_SCORE,
    sa: true,
    options: [
      {
        label:
          MmDashboardTableColumnToMessage[MmDashboardTableColumn.MINGA_SCORE],
        value: [
          MmDashboardTableColumn.MINGA_SCORE,
          MmDashboardTableColumn.MINGA_SCORE_PCT,
          MmDashboardTableColumn.BEHAVIOR_SCORE,
          MmDashboardTableColumn.SIS_SCORE,
          MmDashboardTableColumn.POST_SCORE,
          MmDashboardTableColumn.GROUP_SCORE,
          MmDashboardTableColumn.GENERAL_SCORE,
          MmDashboardTableColumn.BEHAVIOR_SCORE,
          MmDashboardTableColumn.HALL_PASS_SCORE,
          MmDashboardTableColumn.CONSEQUENCE_SCORE,
          MmDashboardTableColumn.POINT_ACTION_SCORE,
          MmDashboardTableColumn.FLEX_CHECK_IN_SCORE,
          MmDashboardTableColumn.CHECK_IN_EVENT_SCORE,
          MmDashboardTableColumn.CHECK_IN_REASON_SCORE,
          MmDashboardTableColumn.FLEX_REGISTERED_SCORE,
          MmDashboardTableColumn.POINTS_RECEIVED_SCORE,
          MmDashboardTableColumn.MONTHLY_ACTIVE_USERS_SCORE,
          MmDashboardTableColumn.STUDENTS_REGISTERED_SCORE,
          MmDashboardTableColumn.TOTAL_USERS_ADDED_SCORE,
          MmDashboardTableColumn.HALL_PASS_AVERAGE_SCORE,
        ],
      },
    ],
  },
];

export const MM_DASHBOARD_TABLE_COLUMNS: MmDashboardTableColumn[] = [
  MmDashboardTableColumn.TYPE,
  MmDashboardTableColumn.STATUS,
  MmDashboardTableColumn.ID,
  MmDashboardTableColumn.HP,
  MmDashboardTableColumn.CM,
  MmDashboardTableColumn.BH,
  MmDashboardTableColumn.CH,
  MmDashboardTableColumn.FL,
  MmDashboardTableColumn.SIS,
  MmDashboardTableColumn.SIS_SYNC,
  MmDashboardTableColumn.SIS_LIST_ACTIVE,
  MmDashboardTableColumn.SIS_LIST_COUNT,
  MmDashboardTableColumn.SIS_SCORE,
  MmDashboardTableColumn.HUB_SPOT_ID,
  MmDashboardTableColumn.MINGA_SCORE_PCT,
  MmDashboardTableColumn.MINGA_SCORE,
  MmDashboardTableColumn.GENERAL_SCORE,
  MmDashboardTableColumn.SIZE,
  MmDashboardTableColumn.USER_LIST_COUNT,
  MmDashboardTableColumn.TOTAL_STUDENTS,
  MmDashboardTableColumn.TOTAL_USERS_ADDED,
  MmDashboardTableColumn.TOTAL_USERS_ADDED_SCORE,
  MmDashboardTableColumn.REGISTERED_STUDENTS,
  MmDashboardTableColumn.REGISTERED_STUDENTS_PCT,
  MmDashboardTableColumn.STUDENTS_REGISTERED_SCORE,
  MmDashboardTableColumn.UNREGISTERED_STUDENTS,
  MmDashboardTableColumn.UNREGISTERED_STUDENTS_PCT,
  MmDashboardTableColumn.TOTAL_STAFF,
  MmDashboardTableColumn.REGISTERED_STAFF,
  MmDashboardTableColumn.REGISTERED_STAFF_PCT,
  MmDashboardTableColumn.UNREGISTERED_STAFF,
  MmDashboardTableColumn.UNREGISTERED_STAFF_PCT,
  MmDashboardTableColumn.TOTAL_COUNT,
  MmDashboardTableColumn.REGISTERED_COUNT,
  MmDashboardTableColumn.REGISTERED_PCT,
  MmDashboardTableColumn.UNREGISTERED,
  MmDashboardTableColumn.UNREGISTERED_PCT,
  MmDashboardTableColumn.DAILY_ACTIVE,
  MmDashboardTableColumn.DAILY_ACTIVE_PCT,
  MmDashboardTableColumn.WEEKLY_ACTIVE,
  MmDashboardTableColumn.WEEKLY_ACTIVE_PCT,
  MmDashboardTableColumn.MONTHLY_ACTIVE,
  MmDashboardTableColumn.MONTHLY_ACTIVE_PCT,
  MmDashboardTableColumn.MONTHLY_ACTIVE_USERS_SCORE,
  MmDashboardTableColumn.STICKER_COUNT,
  MmDashboardTableColumn.HALL_PASS_COUNT,
  MmDashboardTableColumn.HALL_PASS_DENIED_COUNT,
  MmDashboardTableColumn.HALL_PASS_SCORE,
  MmDashboardTableColumn.HALL_PASS_AVERAGE,
  MmDashboardTableColumn.HALL_PASS_AVERAGE_SCORE,
  MmDashboardTableColumn.POSTS_COUNT,
  MmDashboardTableColumn.POST_SCORE,
  MmDashboardTableColumn.GROUPS_COUNT,
  MmDashboardTableColumn.GROUP_SCORE,
  MmDashboardTableColumn.BEHAVIOR_COUNT,
  MmDashboardTableColumn.BEHAVIOR_AVERAGE,
  MmDashboardTableColumn.BEHAVIOR_SCORE,
  MmDashboardTableColumn.CONSEQUENCE_COUNT,
  MmDashboardTableColumn.CONSEQUENCE_AVERAGE,
  MmDashboardTableColumn.CONSEQUENCE_SCORE,
  MmDashboardTableColumn.POINT_ACTION_COUNT,
  MmDashboardTableColumn.POINT_ACTION_AVERAGE,
  MmDashboardTableColumn.POINT_ACTION_SCORE,
  MmDashboardTableColumn.POINTS_RECEIVED_PCT,
  MmDashboardTableColumn.POINTS_RECEIVED_SCORE,
  MmDashboardTableColumn.CHECKIN_COUNT,
  MmDashboardTableColumn.CHECKIN_AVERAGE,
  MmDashboardTableColumn.CHECK_IN_REASON_SCORE,
  MmDashboardTableColumn.CHECK_IN_EVENT_COUNT,
  MmDashboardTableColumn.CHECK_IN_EVENT_AVERAGE,
  MmDashboardTableColumn.CHECK_IN_EVENT_SCORE,
  MmDashboardTableColumn.FLEX_REGISTERED_COUNT,
  MmDashboardTableColumn.FLEX_REGISTERED_AVERAGE,
  MmDashboardTableColumn.FLEX_REGISTERED_SCORE,
  MmDashboardTableColumn.FLEX_CHECK_IN_COUNT,
  MmDashboardTableColumn.FLEX_CHECK_IN_AVERAGE,
  MmDashboardTableColumn.FLEX_CHECK_IN_SCORE,
  MmDashboardTableColumn.SMS_SENT,
  MmDashboardTableColumn.SMS_REMAINING,
];

export const MM_DASHBOARD_TABLE_SA_COLUMNS: MmDashboardTableColumn[] = [
  MmDashboardTableColumn.PARTNER,
  MmDashboardTableColumn.HUB_SPOT_ID,
  MmDashboardTableColumn.POST_SCORE,
  MmDashboardTableColumn.GROUP_SCORE,
  MmDashboardTableColumn.BEHAVIOR_AVERAGE,
  MmDashboardTableColumn.BEHAVIOR_SCORE,
  MmDashboardTableColumn.CONSEQUENCE_COUNT,
  MmDashboardTableColumn.CONSEQUENCE_AVERAGE,
  MmDashboardTableColumn.CONSEQUENCE_SCORE,
  MmDashboardTableColumn.POINT_ACTION_COUNT,
  MmDashboardTableColumn.POINT_ACTION_AVERAGE,
  MmDashboardTableColumn.POINT_ACTION_SCORE,
  MmDashboardTableColumn.POINTS_RECEIVED_PCT,
  MmDashboardTableColumn.POINTS_RECEIVED_SCORE,
  MmDashboardTableColumn.CHECKIN_AVERAGE,
  MmDashboardTableColumn.CHECK_IN_REASON_SCORE,
  MmDashboardTableColumn.CHECK_IN_EVENT_COUNT,
  MmDashboardTableColumn.CHECK_IN_EVENT_AVERAGE,
  MmDashboardTableColumn.CHECK_IN_EVENT_SCORE,
  MmDashboardTableColumn.FLEX_REGISTERED_COUNT,
  MmDashboardTableColumn.FLEX_REGISTERED_AVERAGE,
  MmDashboardTableColumn.FLEX_REGISTERED_SCORE,
  MmDashboardTableColumn.FLEX_CHECK_IN_COUNT,
  MmDashboardTableColumn.FLEX_CHECK_IN_AVERAGE,
  MmDashboardTableColumn.FLEX_CHECK_IN_SCORE,
  MmDashboardTableColumn.HALL_PASS_SCORE,
  MmDashboardTableColumn.HALL_PASS_AVERAGE,
  MmDashboardTableColumn.HALL_PASS_AVERAGE_SCORE,
  MmDashboardTableColumn.MONTHLY_ACTIVE_USERS_SCORE,
  MmDashboardTableColumn.SIS_SCORE,
  MmDashboardTableColumn.MINGA_SCORE_PCT,
  MmDashboardTableColumn.MINGA_SCORE,
  MmDashboardTableColumn.GENERAL_SCORE,
  MmDashboardTableColumn.TOTAL_USERS_ADDED,
  MmDashboardTableColumn.TOTAL_USERS_ADDED_SCORE,
  MmDashboardTableColumn.STUDENTS_REGISTERED_SCORE,
  MmDashboardTableColumn.SIS_LIST_COUNT,
];

export const MM_DASHBOARD_TABLE_HEALTH_COLUMNS: MmDashboardTableColumn[] = [
  MmDashboardTableColumn.GO_MINGA,
  MmDashboardTableColumn.MINGA_NAME,
  MmDashboardTableColumn.SIS_SYNC,
  MmDashboardTableColumn.POST_SCORE,
  MmDashboardTableColumn.GROUP_SCORE,
  MmDashboardTableColumn.BEHAVIOR_AVERAGE,
  MmDashboardTableColumn.BEHAVIOR_SCORE,
  MmDashboardTableColumn.CONSEQUENCE_COUNT,
  MmDashboardTableColumn.CONSEQUENCE_AVERAGE,
  MmDashboardTableColumn.CONSEQUENCE_SCORE,
  MmDashboardTableColumn.POINT_ACTION_COUNT,
  MmDashboardTableColumn.POINT_ACTION_AVERAGE,
  MmDashboardTableColumn.POINT_ACTION_SCORE,
  MmDashboardTableColumn.POINTS_RECEIVED_PCT,
  MmDashboardTableColumn.POINTS_RECEIVED_SCORE,
  MmDashboardTableColumn.CHECKIN_AVERAGE,
  MmDashboardTableColumn.CHECK_IN_REASON_SCORE,
  MmDashboardTableColumn.CHECK_IN_EVENT_COUNT,
  MmDashboardTableColumn.CHECK_IN_EVENT_AVERAGE,
  MmDashboardTableColumn.CHECK_IN_EVENT_SCORE,
  MmDashboardTableColumn.FLEX_REGISTERED_COUNT,
  MmDashboardTableColumn.FLEX_REGISTERED_AVERAGE,
  MmDashboardTableColumn.FLEX_REGISTERED_SCORE,
  MmDashboardTableColumn.FLEX_CHECK_IN_COUNT,
  MmDashboardTableColumn.FLEX_CHECK_IN_AVERAGE,
  MmDashboardTableColumn.FLEX_CHECK_IN_SCORE,
  MmDashboardTableColumn.HALL_PASS_SCORE,
  MmDashboardTableColumn.HALL_PASS_AVERAGE,
  MmDashboardTableColumn.HALL_PASS_AVERAGE_SCORE,
  MmDashboardTableColumn.MONTHLY_ACTIVE_USERS_SCORE,
  MmDashboardTableColumn.SIS_SCORE,
  MmDashboardTableColumn.MINGA_SCORE_PCT,
  MmDashboardTableColumn.MINGA_SCORE,
  MmDashboardTableColumn.GENERAL_SCORE,
  MmDashboardTableColumn.TOTAL_USERS_ADDED,
  MmDashboardTableColumn.TOTAL_USERS_ADDED_SCORE,
  MmDashboardTableColumn.STUDENTS_REGISTERED_SCORE,
  MmDashboardTableColumn.REGISTERED_STUDENTS_PCT,
  MmDashboardTableColumn.MONTHLY_ACTIVE_USERS_SCORE,
  MmDashboardTableColumn.MONTHLY_ACTIVE,
  MmDashboardTableColumn.POSTS_COUNT,
  MmDashboardTableColumn.GROUPS_COUNT,
  MmDashboardTableColumn.BEHAVIOR_COUNT,
  MmDashboardTableColumn.CONSEQUENCE_COUNT,
  MmDashboardTableColumn.CHECKIN_COUNT,
  MmDashboardTableColumn.SMS_SENT,
  MmDashboardTableColumn.SMS_REMAINING,
];

export enum ActionMesssages {
  HALL_PASS_BTN = 'Hall Pass',
  CHECK_IN_BTN = 'Check in',
  ACTIVITY_BTN = 'Activity',
  PRAISE_BTN = 'Praise',
  GUIDANCE_BTN = 'Guidance',
  CONSEQUENCE_BTN = 'Consequence',
  REDEEM_POINTS_BTN = 'Redeem',
  MANAGE_POINTS_BTN = 'Manage',
  ASSIGN_BTN = 'Assign',
  POINTS_BTN = 'Points',
  SELF_CHECK_IN_BTN = 'Self check in',
  KIOSK_MODE_BTN = 'Kiosk mode',
}

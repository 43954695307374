import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { BottomSheetEventType } from '@shared/components/bottom-sheet';
import { SelectElementOption } from '@shared/components/select-element';

import { FiltersFormSheetMessage } from '../../constants';
import { FiltersFormSheetData } from '../../types';

@Component({
  selector: 'mg-filters-form-sheet',
  templateUrl: './filters-form-sheet.component.html',
  styleUrls: ['./filters-form-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormSheetComponent implements OnInit, OnDestroy {
  // Constants

  public readonly MESSAGE = FiltersFormSheetMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  private readonly _activeFieldSubject = new BehaviorSubject<string | null>(
    null,
  );
  public readonly activeField$ = this._activeFieldSubject.asObservable();

  public readonly hasActiveField = this.activeField$.pipe(
    takeUntil(this._destroyedSubject),
    map(field => field !== null),
  );

  public readonly title$ = this.activeField$.pipe(
    takeUntil(this._destroyedSubject),
    map(field => {
      if (field === null) return this.data.title;
      return this.data.data[field]?.label ?? this.data.title;
    }),
  );

  private readonly _draftFormDataSubject = new BehaviorSubject<any>(null);
  public readonly draftFormData$ = this._draftFormDataSubject.asObservable();

  // Events

  private readonly _submitFieldChangesEvent = new Subject<void>();
  public readonly submitFieldChangesEvent$ =
    this._submitFieldChangesEvent.asObservable();

  /** Component constructor */
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: FiltersFormSheetData,
    private _bottomSheetRef: MatBottomSheetRef<FiltersFormSheetComponent>,
  ) {}

  ngOnInit(): void {
    this.data.formGroup.valueChanges
      .pipe(startWith(this.data.formGroup.value))
      .subscribe(val => {
        const draftFormData = this._draftFormDataSubject.getValue();
        this._draftFormDataSubject.next({ ...draftFormData, ...val });
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._activeFieldSubject.complete();
    this._submitFieldChangesEvent.complete();
  }

  public onEsc() {
    const response = {
      type: BottomSheetEventType.ESC,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onClose() {
    const response = {
      type: BottomSheetEventType.CLOSE,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public setActiveFilter(field: string) {
    this._activeFieldSubject.next(field);
  }

  public clear() {
    this.data.resetSubject.next();
    const response = {
      type: BottomSheetEventType.SUBMIT,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public setToggleGroupValue(field: string, { value }: MatButtonToggleChange) {
    this._setDraftFormData(field, value);
  }

  public clearActiveField() {
    this._activeFieldSubject.next(null);
  }

  public submitFieldChanges() {
    this._submitFieldChangesEvent.next();
    this.clearActiveField();
  }

  public setSelectValue(field: string, value: SelectElementOption[]) {
    this._setDraftFormData(field, value);
  }

  public submit() {
    const draftFormData = this._draftFormDataSubject.getValue();
    this.data.formGroup.patchValue(draftFormData);
    const response = {
      type: BottomSheetEventType.SUBMIT,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  private _setDraftFormData(field: string, value: any) {
    const draftFormData = this._draftFormDataSubject.getValue();
    this._draftFormDataSubject.next({ ...draftFormData, [field]: value });
  }
}

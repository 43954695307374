import { CacheItemMethods } from '../cache/cache.types';

/**
 * Helper for keeping a collection of items in a cache
 */
export const createCacheCollection = <T = object>(
  creatorFn: (key) => CacheItemMethods<T>,
) => {
  const cache = new Map<string, CacheItemMethods<T>>();
  return {
    get: (key): CacheItemMethods<T> => {
      if (cache.has(key)) {
        return cache.get(key);
      }

      const item = creatorFn(key);
      cache.set(key, item);
      return item;
    },
  };
};

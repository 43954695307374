import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'mg-mobile-card',
  templateUrl: './mobile-card.component.html',
  styleUrls: ['./mobile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() imagePath: string;
  @Input() alwaysOpen: boolean;

  @ContentChild('actions', { read: TemplateRef })
  actions: TemplateRef<any>;

  public panelOpenState = false;

  constructor() {}

  ngOnInit(): void {
    this.panelOpenState = this.alwaysOpen;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlaceholderSkeletonComponent } from './placeholder-skeleton.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PlaceholderSkeletonComponent],
  exports: [PlaceholderSkeletonComponent],
})
export class PlaceholderSkeletonModule {}

<section>
  <div
    #formContent
    class="form-content">
    <div class="inputs-wrap extra">
      <ng-container
        [ngTemplateOutlet]="hpDurationAndNoteTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="hpScheduleDateTemplate"></ng-container>
    </div>
  </div>
</section>

<!-- HP Duration & Note Input Template -->
<ng-template #hpDurationAndNoteTemplate>
  <div class="pass-assignment">
    <ng-container *ngIf="!hpFG.get(HP_FORM_FIELDS.SELF_GRANT).value">
      <div class="duration-selector-container">
        <mg-text
          variant="body-sm-secondary"
          textAlign="left"
          fontWeight="700"
          spacing="2"
          [numberOfLines]="1">
          {{ ASSIGNMENT_LABELS.DURATION_LABEL }}
        </mg-text>
        <div class="duration-selector-wrapper">
          <mg-hall-pass-time-select
            formControlIdentifier="hpDuration"
            [id]="'hallpass-assign-hall-pass-click-duration'"
            [formGroupInstance]="hpFG"
            [defaultHPTypeDuration]="hpTypeDuration$ | async"
            [isDisabled]="hpFG.get(HP_FORM_FIELDS.SELF_GRANT).value">
          </mg-hall-pass-time-select>
        </div>
      </div>
    </ng-container>
    <ng-container [ngTemplateOutlet]="hpAssignedByInputTemplate"></ng-container>
    <div
      *ngIf="!hpFG.get(HP_FORM_FIELDS.SELF_GRANT).value && canAddNote$ | async"
      class="note-wrapper">
      <mg-form-text-input
        [id]="'hallpass-assign-hall-pass-click-note'"
        [control]="hpFG.get(HP_FORM_FIELDS.NOTE)"
        [label]="ASSIGNMENT_LABELS.NOTE_LABEL">
      </mg-form-text-input>
    </div>
  </div>
</ng-template>

<!-- Assigned By Search Template -->
<ng-template #hpAssignedByInputTemplate>
  <div class="assigned-by-wrapper">
    <ng-container
      *ngIf="hpFG.get(HP_FORM_FIELDS.SELF_GRANT).value"
      [ngTemplateOutlet]="selfGrantDuration">
    </ng-container>
    <ng-container
      *ngIf="
        (hpFG.get(HP_FORM_FIELDS.SELF_GRANT).value &&
          (hpTypeFC.value?.requireTeacherApproval ||
            (mustAssignStaff$ | async))) ||
        ('HALL_PASS_MANAGE' | hasPermission)
      ">
      <mg-form-select
        mgTeacherSearch
        [id]="'hallpass-assign-hall-pass-click-approve'"
        [excludeCurrentUser]="true"
        [placeholder]="
          ('HALL_PASS_MANAGE' | hasPermission)
            ? ASSIGNMENT_LABELS.ASSIGNED_BY_LABEL_STAFF
            : ASSIGNMENT_LABELS.ASSIGNED_BY_LABEL
        "
        [closeOnSelect]="true"
        [useCachedValues]="false"
        [multiple]="false"
        [isClearable]="true"
        [searchable]="true"
        [hideSelected]="false"
        [floatingLabel]="true"
        [control]="hpFG.get(HP_FORM_FIELDS.ASSIGNED_BY)"
        [canGrantHallPass]="true"
        returnMode="full"
        (selectionChanged)="assignerChange($event)">
      </mg-form-select>
    </ng-container>
  </div>
</ng-template>

<!-- Scheduled Date Input Template -->
<ng-template #hpScheduleDateTemplate>
  <div *ngIf="!hpFG.get(HP_FORM_FIELDS.SELF_GRANT).value">
    <div class="schedule-date-toggle">
      <mg-form-checkbox
        [id]="'hallpass-assign-hall-pass-click-schedule'"
        [checked]="hpFG.get(HP_FORM_FIELDS.IS_SCHEDULED).value"
        (changed)="setIsScheduledState()">
      </mg-form-checkbox>
      <mg-text
        variant="body-sm-secondary"
        textAlign="left"
        fontWeight="700"
        [numberOfLines]="1">
        {{ ASSIGNMENT_LABELS.SCHEDULE_LABEL }}
      </mg-text>
    </div>

    <div
      @fadeInOut
      *ngIf="hpFG.get(HP_FORM_FIELDS.IS_SCHEDULED).value"
      class="schedule-date-fields">
      <mg-form-date
        [label]="ASSIGNMENT_LABELS.SCHEDULE_DATE_LABEL"
        [minDate]="today"
        [control]="hpFG.get(HP_FORM_FIELDS.SCHEDULED_DATE)">
      </mg-form-date>

      <mg-form-text-input
        [label]="ASSIGNMENT_LABELS.SCHEDULE_TIME_LABEL"
        inputType="time"
        [step]="60"
        [control]="hpFG.get(HP_FORM_FIELDS.SCHEDULED_TIME)"
        (click)="setDefaultScheduledTimeValue()">
      </mg-form-text-input>
    </div>
  </div>
</ng-template>

<ng-template #selfGrantDuration>
  <ng-container *ngIf="hpTypeDuration$ | async as duration">
    <div class="duration-selector-mobile">
      <mg-text
        variant="body-sm-secondary"
        textAlign="left">
        Pass duration: {{ duration }} min
      </mg-text>
    </div>
  </ng-container>
</ng-template>

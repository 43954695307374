import { Component, Input } from '@angular/core';

import { Quantity } from './placeholder-text.types';

@Component({
  selector: 'mg-placeholder-text',
  template: `
    <div
      class="tw-mb-2"
      *ngFor="let index of [].constructor(lines); let i = index">
      <mg-placeholder-skeleton
        height="10"
        borderRadius="small">
      </mg-placeholder-skeleton>
    </div>
  `,
})

//
export class PlaceholderTextComponent {
  @Input() lines: Quantity = '1';
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import * as day from 'dayjs';

import { BottomSheetEventType } from '@shared/components/bottom-sheet/bottom-sheet.types';
import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import {
  DateMessages,
  DatePickerDefaultPresetKey,
} from '../../form-date-range.constants';
import { RangeBottomSheetData } from '../../form-date-range.types';
import {
  FormRangeAbstract,
  RangeResponseData,
} from '../../services/form-range-abstract';
import { FormRangeService } from '../../services/form-range.service';

@Component({
  selector: 'mg-form-date-range-mobile',
  templateUrl: './form-date-range-mobile.component.html',
  styleUrls: ['./form-date-range-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateRangeMobileComponent extends FormRangeAbstract {
  public MESSAGES = DateMessages;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: RangeBottomSheetData,
    public cdr: ChangeDetectorRef,
    public rangeService: FormRangeService,
    private _bottomSheetRef: MatBottomSheetRef,
    public presetService: DatePresetsService,
  ) {
    super(cdr, rangeService, presetService);
  }

  public onInit() {
    this.setData(this.data.range, this.data.minDate, this.data.maxDate);
  }

  public onClear(data) {
    const response: RangeResponseData = {
      type: BottomSheetEventType.CANCEL,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onDone(data) {
    const response: RangeResponseData = {
      type: BottomSheetEventType.SUBMIT,
      data: {
        range: data.range,
      },
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onEsc() {
    const response: RangeResponseData = {
      type: BottomSheetEventType.ESC,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }

  public onClose() {
    const response: RangeResponseData = {
      type: BottomSheetEventType.CLOSE,
      data: {},
    };
    this._bottomSheetRef.dismiss(response);
  }
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

import { FileUploadComponent } from 'minga/app/src/app/components/Input/FileUpload';
import { DEFAULT_IMAGE_ACCEPT } from 'minga/app/src/app/file';

export const MG_FILE_INPUT_UPLOAD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputUploadComponent),
  multi: true,
};

export const MG_FILE_INPUT_UPLOAD_VALUE_VALIDATOR: any = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => FileInputUploadComponent),
  multi: true,
};

@Component({
  providers: [
    MG_FILE_INPUT_UPLOAD_VALUE_VALIDATOR,
    MG_FILE_INPUT_UPLOAD_VALUE_ACCESSOR,
  ],
  selector: 'mg-file-input-upload',
  templateUrl: './FileInputUpload.component.html',
  styleUrls: ['./FileInputUpload.component.scss'],
})
export class FileInputUploadComponent
  implements ControlValueAccessor, AsyncValidator, OnChanges
{
  private onChange;
  private onTouched;
  private pendingPromise: Promise<void>;
  private pendingResolve: () => void;

  _value = '';
  files: File[] = [];
  formGroup: FormGroup;

  @Input()
  multiple = false;

  @Input()
  customTitleText = '';

  @Input() // Passed to child mg-file-input component
  customBottomText = '';

  @Input()
  previewSize = 150;

  @Input()
  previewAspectRatio = '';

  @Input()
  icon = 'camera';

  @Input()
  changeText = 'Change';

  @Input()
  fallback: string | null = null;

  @Input()
  showFileDetails = true;

  @Input()
  friendlyName = 'photo';

  @Input()
  accept: string = DEFAULT_IMAGE_ACCEPT;

  @Input()
  radius?: boolean = false;

  @Output()
  onFileUpload: EventEmitter<File> = new EventEmitter();

  @Output()
  onStatusChange?: EventEmitter<'PENDING' | 'VALID'> = new EventEmitter();

  @ViewChild(FileUploadComponent, { static: true })
  fileUpload?: FileUploadComponent;

  @Input()
  correctPreviewAspectRatio = false;

  @Input()
  required = false;

  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  _height = '';
  _width = '';
  _paddingBottom = '';
  _enableHover = false;

  constructor(
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this._enableHover = !window.MINGA_APP_IOS;

    this.formGroup = new FormGroup({
      fileInput: new FormControl(),
      fileUpload: new FormControl(),
    });

    this.formGroup.controls.fileUpload.statusChanges.subscribe(status => {
      const previousValue = this._value;

      this.onStatusChange.emit(status);

      if (status === 'VALID') {
        this._value = this.formGroup.controls.fileUpload.value;
      }

      if (status === 'PENDING' && !this.pendingPromise) {
        this.pendingPromise = new Promise(resolve => {
          this.pendingResolve = resolve;
        });
      }

      if (status !== 'PENDING' && this.pendingResolve) {
        this.pendingResolve();
      }

      if (this.onChange && this._value != previousValue) {
        this.onChange(this._value);
        // emit file that was uploaded
        if (this.files.length) {
          this.onFileUpload.emit(this.files[0]);
        }
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  reset() {
    this._value = '';
    this.files = [];

    if (this.fileUpload) {
      this.fileUpload.clearPreview();
    }

    this.changeDetectorRef.markForCheck();
  }

  shouldShowChangeOverlay(): boolean {
    const status = this.formGroup.controls.fileUpload.status;
    return !!this._value || (this.files[0] && status === 'VALID');
  }

  onErrorAcknowledged() {
    if (!this.hasFallback()) {
      this.cancel();
    } else {
      this.resetToFallback();
    }
  }

  hasFallback() {
    return this.fallback !== null;
  }

  isFallback() {
    return this.fallback === this._value;
  }

  resetToFallback() {
    this._value = this.fallback;
    this.formGroup.controls.fileUpload.setValue(this._value);
    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  click() {
    if (this.element) {
      const inputEl = this.element.nativeElement.querySelector('input');
      if (inputEl) {
        inputEl.click();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.previewAspectRatio) {
      if (this.previewAspectRatio) {
        const components = this.previewAspectRatio.split(':');
        const aspectX = parseFloat(components[0]);
        const aspectY = parseFloat(components[1]);

        if (isNaN(aspectX) || isNaN(aspectY)) {
          this._height = '';
          this._width = '';
          this._paddingBottom = '';
        } else {
          this._height = '0px';
          this._width = '100%';
          this._paddingBottom = (aspectY / aspectX) * 100 + '%';
        }
      } else {
        this._height = '';
        this._width = '';
        this._paddingBottom = '';
      }
    }
  }

  async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    await this.pendingPromise;

    if (this.required && !this._value) {
      return { required: true };
    }

    return null;
  }

  writeValue(value: any) {
    if (!value) {
      this._value = '';
      this.files = [];
      this.formGroup.controls.fileUpload.setValue(this._value);
      // https://github.com/angular/angular/issues/10816
      this.changeDetectorRef.markForCheck();
    } else if (typeof value === 'string') {
      this._value = value;
      this.formGroup.controls.fileUpload.setValue(this._value);
      // https://github.com/angular/angular/issues/10816
      this.changeDetectorRef.markForCheck();
    } else {
      console.warn(
        '<file-input-upload> Unsupported type for writeValue:',
        value,
      );
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  cancel(e?: any) {
    this.files = [];
    this._value = '';
    this.formGroup.controls.fileUpload.setValue(this._value);
    this.onChange(this._value);
    this.onTouched();
  }
}

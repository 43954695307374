import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'mg-filters-form-user-lists',
  templateUrl: './filters-form-user-lists.component.html',
  styleUrls: ['./filters-form-user-lists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormUserListsComponent implements OnInit {
  /** Component constructor */
  constructor() {}

  ngOnInit(): void {}
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import { SelectElementMessage } from './constants';
import { SelectElementOption } from './types';
import { ElementSelectFilter, SelectElementSelectionModel } from './utils';

@Component({
  selector: 'mg-select-element',
  templateUrl: './select-element.component.html',
  styleUrls: ['./select-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectElementComponent implements OnInit, OnDestroy {
  // Constants

  public readonly MESSAGE = SelectElementMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs

  @Input() enableSearch = false;
  @Input() multiple = false;
  @Input() options: SelectElementOption[];
  @Input() selected: SelectElementOption[];
  @Input() done: Observable<void>;

  // Outputs

  @Output() selectionChange = new EventEmitter<SelectElementOption[]>();

  // Filter

  public readonly filter = new ElementSelectFilter(this._destroyedSubject);

  // Selection model

  public readonly selection = new SelectElementSelectionModel();

  // Subscriptipns

  private _doneSubscription: Subscription;

  /** Component constructor */
  constructor(public systemAlert: SystemAlertSnackBarService) {}

  public ngOnInit(): void {
    this.filter.init(this.options);
    this.selection.init({
      multiple: this.multiple,
      initialSelection: this.selected,
    });
    this._doneSubscription = this.done?.subscribe(() => {
      this.selectionChange.emit(this.selection.selected);
    });
  }

  public ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._doneSubscription?.unsubscribe();
  }

  public selectOption(option: SelectElementOption) {
    try {
      this.selection.toggle(option);
    } catch {
      this.systemAlert.error('Error selecting option');
    }
  }
}

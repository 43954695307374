import { Component, Input } from '@angular/core';

import { Animation, Padding } from './placeholder-card.types';

/**
 * The placeholder card component wraps elements with padding and a border.
 * You can combine multiple cards together to make a page placeholder.
 *
 * The card will always fill the entire width and height it is given. If you
 * want to restrict the width/height of a card, the parent should do that with
 * a wrapping div.
 */
@Component({
  selector: 'mg-placeholder-card',
  template: `
    <div [ngClass]="styleClasses">
      <ng-content></ng-content>
      <mg-animated-wave *ngIf="hasWaveAnimation"></mg-animated-wave>
    </div>
  `,
})

//
export class PlaceholderCardComponent {
  @Input() padding: Padding = 'medium';
  @Input() animation: Animation = 'wave';
  @Input() quantity = 1;

  get hasWaveAnimation(): boolean {
    return this.animation === 'wave';
  }

  // Map input properties to style classes.
  get styleClasses(): string[] {
    return [
      'tw-relative tw-overflow-hidden tw-rounded-lg tw-h-full',
      'tw-border tw-border-solid tw-border-gray-300',
      this._getPadding(),
    ];
  }

  private _getPadding(): string {
    const lookup: Record<Padding, string> = {
      small: 'tw-p-2',
      medium: 'tw-p-4',
      large: 'tw-p-6',
    };
    return lookup[this.padding];
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { UserListMinimal } from 'minga/domain/userList';

import {
  OptionItem,
  SelectGroupCategory,
} from '../form/components/form-grouped-select/form-grouped-select.types';
import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import { UserListFilterService } from './services/user-list-filter.service';
import { UserListFilterMessages } from './user-list.constants';
import { UserListCategory } from './user-list.types';

@Component({
  selector: 'mg-user-list-filter',
  templateUrl: './user-list-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-list-filter.component.scss'],
})
export class UserListFilterComponent implements OnInit {
  /**
   * By default we lazy load all the lists but there's times we want to prefetch
   * especially in forms when we need to set default values
   */
  @Input() prefetchData: UserListCategory[] = [];
  @Input() id: string;
  @Input() value: number | number[];
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() multiple = true;
  @Output() selectChanged: EventEmitter<number | number[] | null> =
    new EventEmitter();

  private _dataLoadedSubj = new BehaviorSubject<boolean>(false);
  public dataLoaded$ = this._dataLoadedSubj.asObservable();

  public options: SelectGroupCategory<string, number, UserListMinimal>[] = [
    {
      value: UserListCategory.MY_LISTS_CURRENT_TERM,
      label: UserListFilterMessages.MY_LISTS_CURRENT_TERM,
      items: [],
      fetch: () => this._userListFilterService.getMyListsCurrentTerm(),
      lazyLoad: true,
      openByDefault: true,
    },
    {
      value: UserListCategory.MY_LISTS,
      label: UserListFilterMessages.MY_LISTS,
      items: [],
      fetch: () => this._userListFilterService.getMyLists(),
      lazyLoad: true,
    },
    {
      value: UserListCategory.ALL_CURRENT_TERM,
      label: UserListFilterMessages.ALL_LISTS_CURRENT_TERM,
      items: [],
      fetch: () => this._userListFilterService.getAllListsCurrentTerm(),
      lazyLoad: true,
    },
    {
      value: UserListCategory.ALL,
      label: UserListFilterMessages.ALL_LISTS,
      items: [],
      fetch: () => this._userListFilterService.getAllLists(),
      usedForUserSearch: true,
      lazyLoad: true,
    },
  ];

  constructor(
    private _userListFilterService: UserListFilterService,
    private _snackBar: SystemAlertSnackBarService,
  ) {}

  ngOnInit(): void {
    this._prefetchData();
  }

  public selectChangeHandler(
    value?:
      | OptionItem<number, UserListMinimal>
      | OptionItem<number, UserListMinimal>[],
  ): void {
    if (!value) {
      this.selectChanged.emit(null);
      return;
    }

    const data = Array.isArray(value)
      ? value.map(v => v.data?.id)
      : value.data.id;

    this.selectChanged.emit(data);
  }

  public clear(): void {
    this.value = [];
    this.selectChanged.emit(null);
  }

  private async _prefetchData() {
    if (!this.prefetchData) {
      this._dataLoadedSubj.next(true);
      return;
    }

    try {
      const fetchers = this.options
        .filter(cat => this.prefetchData.includes(cat.value as any))
        .map(async cat => {
          const options = await cat.fetch();
          cat.items = options;
        });

      await Promise.all(fetchers);
    } catch (error) {
      console.error('Error preloading data', error);
      this._snackBar.error('There was an issue loading user lists');
    } finally {
      this._dataLoadedSubj.next(true);
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { IMembershipList } from 'minga/domain/membershipList';

import { StickerBannersService } from '@modules/id/components/id-stickers/services';

import { MODAL_OVERLAY_DATA } from '@shared/components/modal-overlay';
import { MediaService } from '@shared/services/media';

import { ToolsStickersModalData } from '../../types/shared-tools.types';

@Component({
  selector: 'mg-tools-stickers-view-modal',
  templateUrl: './tools-stickers-view-modal.component.html',
  styleUrls: ['./tools-stickers-view-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsStickersViewModalComponent implements OnInit {
  /** Observables */
  private _stickersSubject = new BehaviorSubject<IMembershipList[]>([]);
  public readonly stickers$ = this._stickersSubject.asObservable();

  /** Constructor */
  constructor(
    public media: MediaService,
    @Inject(MODAL_OVERLAY_DATA) private _data: ToolsStickersModalData,
    private _stickerBannerService: StickerBannersService,
  ) {}

  public ngOnInit(): void {
    this._stickersSubject.next(this._data.stickers);
  }

  public getStickerPath(item: IMembershipList) {
    return this._stickerBannerService.getStickerPath(item);
  }
}

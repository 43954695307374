<mg-manager-overlay-reports-subpage
  [loading]="false"
  [noBottomPadding]="reportType.active === REPORT_TYPE.HALL_PASS_SUMMARY"
  [columnSettingsButton]="reportType.active !== REPORT_TYPE.HALL_PASS_SUMMARY"
  [scheduleReportsButton]="
    (SUPPPORTED_SCHEDULE_TYPES | includes: $any(reportType).active) &&
    (canScheduleReport$ | async)
  "
  (scheduleReportsPressed)="onScheduleReport()"
  [dataService]="dataService$ | async"
  (exportListPressed)="hpmReports.exportReport(this.reportType.active)">
  <!-- Header -->
  <ng-template #header>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start stretch"
      fxLayoutGap="12px"
      fxLayoutGap.lt-md="8px">
      <!-- Report type filter -->
      <div fxFlex="nogrow">
        <ng-container *ngTemplateOutlet="reportTypeFilterTemplate">
        </ng-container>
      </div>
      <!-- Date picker filter -->
      <div fxFlex="nogrow">
        <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
      </div>
      <div fxFlex="nogrow">
        <!-- Filters form -->
        <mg-filters-form
          appearance="primary"
          title="Filters"
          [data]="filtersFormStructure$ | async"
          (state)="updateFilters($event)">
        </mg-filters-form>
      </div>
    </div>

    <!-- <div class="filters-group-row">
          <ng-container
            *ngIf="(media$ | async) !== 'xs'"
            [ngTemplateOutlet]="hallPassTypesTemplate"></ng-container>
          <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active === REPORT_TYPE.HALL_PASS_HISTORY"
            [ngTemplateOutlet]="statusTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active === REPORT_TYPE.HALL_PASS_HISTORY &&
              ('HALL_PASS_TYPE_MANAGE' | hasPermission)
            "
            [ngTemplateOutlet]="deniedByTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active === REPORT_TYPE.HALL_PASS_HISTORY &&
              ('HALL_PASS_TYPE_MANAGE' | hasPermission)
            "
            [ngTemplateOutlet]="denialReasonTemplate"></ng-container>
        </div> -->
    <!-- <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="createdByTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
        </div> -->
    <!-- </div> -->
    <!-- </ng-container> -->
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="305px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="grey"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="reportType.active"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="reportType.options"
      [floatingLabel]="false"
      (selectionChanged)="onChangeReportType($any($event))">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- People Search Template -->
<!-- <ng-template #peopleSearchTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="260px">
    <mg-multi-person-search
      [id]="'report-report-view-click-filter-search'"
      (selectionChanged)="onPersonSelected($any($event))">
    </mg-multi-person-search>
  </div>
</ng-template> -->

<!-- Hall Pass Types Template -->
<ng-template #hallPassTypesTemplate>
  <div class="half-width">
    <mg-form-select
      #typeFilterSelect
      [id]="'report-report-view-click-filter-hp-type'"
      [placeholder]="MESSAGES.SELECT_LABEL_TYPE"
      [value]="hallpassType$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [isFullWidth]="true"
      [searchable]="true"
      [options]="hallpassTypesSelectOptions$ | async"
      (selectionChanged)="onChangeHallpassType($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #userListTemplate>
  <div class="half-width">
    <mg-user-list-filter
      [id]="'report-report-view-click-filter-userlist'"
      [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
      [value]="(hpmReports.filter$ | async).userList"
      (selectChanged)="onChangeUserList($any($event))">
    </mg-user-list-filter>
  </div>
</ng-template>

<!-- <ng-template #statusTemplate>
  <div class="half-width">
    <mg-form-select
      [id]="'report-report-view-click-filter-status'"
      [placeholder]="MESSAGES.SELECT_LABEL_STATUS"
      [value]="status$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [options]="STATUS_OPTIONS"
      [isFullWidth]="true"
      (selectionChanged)="onChangeStatus($any($event))">
    </mg-form-select>
  </div>
</ng-template> -->

<ng-template #deniedByTemplate>
  <div class="half-width">
    <mg-form-select
      #deniedByFilterSelect
      [id]="'report-report-view-click-filter-deny-by'"
      [placeholder]="MESSAGES.SELECT_LABEL_DENIED_BY"
      [value]="deniedBy$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [options]="DENIED_BY_OPTIONS"
      [disabled]="disableDeniedBy$ | async"
      [isFullWidth]="true"
      (selectionChanged)="onChangeDeniedBy($any($event))">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #denialReasonTemplate>
  <div class="half-width">
    <mg-form-select
      #denialReasonFilterSelect
      [id]="'report-report-view-click-filter-deny-reason'"
      [placeholder]="MESSAGES.SELECT_LABEL_DENIAL_REASON"
      [value]="denialReason$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [options]="denialReasonOptions$ | async"
      [disabled]="!(denialReasonOptions$ | async)?.length"
      [isFullWidth]="true"
      (selectionChanged)="onChangeDenialReason($any($event))">
    </mg-form-select>
  </div>
</ng-template>

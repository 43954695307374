import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Banner } from 'minga/proto/gateway/banner_pb';

import { CarouselConfig } from '@shared/components/carousel/carousel/carousel';

@Component({
  selector: 'mg-form-image-select',
  templateUrl: './form-image-select.component.html',
  styleUrls: ['./form-image-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormImageSelectComponent implements OnInit, OnDestroy {
  private _debouncedOnEndReached = new Subject<number>();
  private _subscription: Subscription;

  public customAssetPath = '';
  public carouselConfig: CarouselConfig = {
    grid: { xs: 3, sm: 6, md: 6, lg: 6, xl: 6, all: 0 },
    load: 2,
    touch: true,
    showPagination: false,
  };
  private _loading = new BehaviorSubject(true);
  public loading$ = this._loading.asObservable();

  @Input() control: FormControl | AbstractControl;
  @Input() set imageList(banners: Banner.AsObject[]) {
    const bannerHash = this.control?.value?.bannerHash || '';
    this.sortedImageList = (banners || []).sort((a, b) => {
      return a.bannerHash === bannerHash ? -1 : 0;
    });

    if (this.allowCustom) {
      this.sortedImageList = [
        { allowCustom: true } as any,
        ...this.sortedImageList,
      ];
    }

    this._loading.next(!Array.isArray(banners));
  }
  public sortedImageList: Banner.AsObject[] = [];

  @Input() allowCustom = true;
  @Input() customUploadLabel = 'Upload Photo';
  @Input() label: string;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  @Output() endReached = new EventEmitter<number>();
  @Output() statusChange = new EventEmitter<'PENDING' | 'VALID'>();

  ngOnInit() {
    this._subscription = this._debouncedOnEndReached
      .pipe(debounceTime(300))
      .subscribe(output => {
        this.endReached.next(output);
      });

    const assetPath = this.control?.value?.assetPath || '';

    if (assetPath) {
      this.customAssetPath = assetPath;
    }
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  public selectThumb(banner: Banner.AsObject) {
    this.customAssetPath = '';
    this.control.setValue({
      assetPath: null,
      bannerHash: banner.bannerHash,
    });
  }

  public carouselLoad(event) {
    this._debouncedOnEndReached.next(event);
  }

  public trackBy(_, item) {
    return item?.hash || _;
  }

  public saveUploadedAsset(event) {
    this.control.setValue({
      assetPath: this.customAssetPath,
      bannerHash: null,
    });
  }

  public onStatusChange(event) {
    this.statusChange.next(event);
  }
}

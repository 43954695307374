<form
  [formGroup]="formGroup"
  [class.rounded]="radius">
  <div
    class="file-input-upload"
    [class.enable-hover]="_enableHover"
    [style.paddingBottom]="_paddingBottom"
    [style.width]="_width"
    [style.height]="_height"
    [class.show-change]="formGroup.controls.fileUpload.status !== 'PENDING'">
    <mg-button-icon
      *ngIf="hasFallback() && !isFallback()"
      class="cancel-button"
      icon="close"
      (click)="resetToFallback()">
    </mg-button-icon>
    <div
      class="change-overlay"
      *ngIf="shouldShowChangeOverlay()">
      <div class="mg-content">
        <mg-icon *ngIf="icon">{{ icon }}</mg-icon>
        <mg-text variant="label-sm-secondary">{{ changeText }}</mg-text>
      </div>
    </div>
    <mg-file-input
      formControlName="fileInput"
      [id]="id"
      [icon]="icon"
      [customTitleText]="customTitleText"
      [customBottomText]="customBottomText"
      [friendlyName]="friendlyName"
      [multiple]="multiple"
      [showFileDetails]="showFileDetails"
      [accept]="accept"
      [(ngModel)]="files">
    </mg-file-input>
    <mg-file-upload
      *ngIf="_value || files[0]"
      [accept]="accept"
      [file]="files[0] || null"
      [correctPreviewAspectRatio]="correctPreviewAspectRatio"
      [previewAspectRatio]="previewAspectRatio"
      (errorAcknowledged)="onErrorAcknowledged()"
      [previewSize]="previewSize"
      formControlName="fileUpload">
    </mg-file-upload>
  </div>
</form>

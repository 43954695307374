<mg-bottom-sheet
  title="Date Range Picker"
  [fullHeight]="true"
  (onEsc)="onEsc()"
  (onClose)="onClose()">
  <div class="calendar">
    <mg-form-date-range-calendar
      pickerStyle="mobile"
      [selectedRange]="selectedRange"
      [minDate]="minDateSetting"
      [maxDate]="maxDateSetting"
      (dateSelected)="
        dateSelected($event.start, $event.end)
      "></mg-form-date-range-calendar>
  </div>
  <div
    *ngIf="data.hidePresets === false"
    class="presets">
    <mg-form-date-range-presets
      (presetClicked)="selectPreset($event)"
      [customPresets]="data?.customPresets"
      [activePreset]="activePreset$ | async"></mg-form-date-range-presets>
  </div>
  <ng-template #actions>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-btn
        variant="text"
        (pressed)="clear()"
        >{{ MESSAGES.RESET_BTN }}</mg-btn
      >
      <mg-btn
        variant="filled"
        [disabled]="!range.valid"
        (pressed)="done()"
        >{{ MESSAGES.DONE_BTN }}</mg-btn
      >
    </div>
  </ng-template>
</mg-bottom-sheet>

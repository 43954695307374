import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { FlexTimeManagerRoutes } from '@modules/flex-time-manager';
import { KioskModeModalComponent } from '@modules/kiosk/kiosk-ui/components/kiosk-mode-modal/kiosk-mode-modal.component';
import { PointsManagerRoutes } from '@modules/points-manager';
import { SelectionAssignerRoutes } from '@modules/selection-assigner';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { RouterOutletOption } from '@shared/constants/routerOutlet';

@Injectable()
export class TeacherToolsService {
  constructor(
    private _router: Router,
    private _systemAlertModal: SystemAlertModalService,
    private _mingaSettings: MingaSettingsService,
    private _modalOverlay: ModalOverlayService,
  ) {}

  public async openHallpassAssignmentForm(isStudent = false) {
    const isHallPassEnabled = await this._mingaSettings.getSettingValue(
      mingaSettingTypes.PASS_HALLPASS_ENABLE,
    );

    if (!isHallPassEnabled) {
      return this._systemAlertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading: 'Hall passes are currently disabled',
        message:
          'To assign a new pass, hall passes need to be enabled first in the Hall Pass settings',
      });
    }

    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.HALL_PASS,
            ],
          },
        },
      ],
      {
        state: {
          type: 'hall-pass',
          selfAssign: isStudent,
        },
      },
    );
  }

  public openPbisTrackingForm(type: string) {
    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.BEHAVIOR,
            ],
          },
        },
      ],
      {
        state: {
          type,
        },
      },
    );
  }

  public openCheckinForm() {
    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.CHECK_IN,
            ],
          },
        },
      ],
      {
        state: {
          type: 'checkin',
        },
      },
    );
  }

  public openAssignActivityForm() {
    this._router.navigate([
      `/${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.ACTIVITIES}`,
      { outlets: { o: null } },
    ]);
  }

  public openManagePointsForm() {
    this._router.navigate(['', { outlets: { o: [PointsManagerRoutes.ROOT] } }]);
  }

  public showKioskModal() {
    this._modalOverlay.open(KioskModeModalComponent, {
      disposeOnNavigation: true,
    });
  }
}

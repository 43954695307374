<div
  class="checkbox-wrapper"
  [attr.data-analytics]="id"
  [attr.data-test]="id">
  <mat-checkbox
    [indeterminate]="indeterminateValue"
    [disabled]="disabled"
    [checked]="checkedValue">
  </mat-checkbox>
  <ng-content></ng-content>
</div>

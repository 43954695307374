import { NgModule } from '@angular/core';

import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { SelectElementComponent } from './select-element.component';

@NgModule({
  imports: [
    // Minga dependencies

    // Common dependencies
    CommonUIModules,
    CommonFormModules,

    // External dependencies
  ],
  declarations: [SelectElementComponent],
  exports: [SelectElementComponent],
})
export class SelectElementModule {}

import { SelectionModel } from '@angular/cdk/collections';

import { FormSheetSelectOption } from '../types';

export interface FormSheetSelectSelectionData {
  multiple: boolean;
  initialSelection: FormSheetSelectOption[];
}

export class FormSheetSelectSelection {
  // State

  private readonly _model = new SelectionModel<FormSheetSelectOption>(
    this._data.multiple,
    this._data.initialSelection ?? [],
  );

  // Computed getters

  public get size() {
    return this._model.selected.length;
  }

  public get selected() {
    return this._model?.selected;
  }

  /** Utility constructor */
  constructor(private readonly _data: FormSheetSelectSelectionData) {}

  public isSelected(option: FormSheetSelectOption) {
    return this._model?.isSelected(option);
  }

  public toggle(option: FormSheetSelectOption) {
    this._model.toggle(option);
  }

  public clear() {
    this._model.clear();
  }
}

import { Params } from '@angular/router';

import * as day from 'dayjs';

export enum QueryParamKey {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  STAT_RANGE_PRESET = 'stat_range_preset',
}

type Range = {
  startDate: day.Dayjs;
  endDate: day.Dayjs;
};

const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Takes a date range and returns an object formatted for query params
 */
export const getQueryParamsFromDateRange = (
  range: Range,
  fallback?: Range,
): {
  start_date: string;
  end_date: string;
} => {
  const start = range?.startDate || fallback?.startDate || day();
  const end = range?.endDate || fallback?.endDate || day();
  return {
    [QueryParamKey.START_DATE]: start.format(DATE_FORMAT),
    [QueryParamKey.END_DATE]: end.format(DATE_FORMAT),
  };
};

/**
 * Takes query params and returns a date range object
 */
export const getDateRangeFromQueryParams = (
  queryParams: Params,
  fallback: Range,
): Range => {
  const startDate = queryParams?.[QueryParamKey.START_DATE]
    ? day(queryParams.start_date)
    : fallback.startDate;
  const endDate = queryParams?.[QueryParamKey.END_DATE]
    ? day(queryParams.end_date)
    : fallback.endDate;
  return {
    startDate,
    endDate,
  };
};

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { PromotionalModalConfig } from '@modules/minga-manager/components/mm-subscription';
import { MmSubscriptionFeatureModalComponent } from '@modules/minga-manager/components/mm-subscription/components/mm-subscription-feature-modal/mm-subscription-feature-modal.component';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import {
  METRIC_CARD_CONFIG,
  ToolsMetricsCardMessage,
} from './tools-metrics-card.constants';
import { MetricData, MetricType } from './tools-metrics-card.types';

@Component({
  selector: 'mg-tools-metrics-card',
  templateUrl: './tools-metrics-card.component.html',
  styleUrls: ['./tools-metrics-card.component.scss'],
})
export class ToolsMetricsCardComponent {
  private _metric: MetricType;
  public metricData: MetricData;

  /** Constants */
  public readonly MESSAGES = ToolsMetricsCardMessage;

  /** Inputs */
  @Input() disabled: boolean;
  @Input() moduleEnabled = true;
  @Input() value: number;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input()
  set metric(value: MetricType) {
    this._metric = value;
    this.metricData = METRIC_CARD_CONFIG[this._metric];
  }

  get metric(): MetricType {
    return this._metric;
  }

  @Output() pressed = new EventEmitter<void>();

  /** Constructor */
  constructor(
    private _router: Router,
    private _modalOverlay: ModalOverlayService,
  ) {}

  public navigate() {
    if (!this.moduleEnabled || this.disabled) return;

    this.pressed.emit();
  }

  public openSubscriptionModal(moduleConfig: PromotionalModalConfig) {
    if (!moduleConfig) return;

    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: moduleConfig,
      },
    );
  }
}

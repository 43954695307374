import { Injectable, Optional } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import * as SemVer from 'semver';
import { filter, takeWhile } from 'rxjs/operators';

import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';

import { HomeRoute } from '@modules/home/constants';

/**
 * Service to handle smoothly updating the app when updates are available.
 */
@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  constructor(
    @Optional() private swUpdate: SwUpdate,
    private router: Router,
    private appConfig: AppConfigService,
  ) {}

  init() {
    // only do this if there is a service worker.
    if (this.swUpdate) {
      this.swUpdate.available.subscribe(event => {
        this.updateToLatest();
      });
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
    }

    // min app version value is fetched from firebase remote config
    // value can be set in firebase remote config console
    const minimumVersion = this.appConfig.getValue('minimumVersion');
    if (minimumVersion) {
      this.checkAppVersion(minimumVersion);
    }
  }

  updateToLatest() {
    // wait until the user goes to the home page to launch notifier
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeWhile(this.isNotOnHomepage, true),
      )
      .subscribe((event: Event) => {
        if (!this.isNotOnHomepage(event)) {
          this.router.navigate([
            '',
            { outlets: { o: ['oldVersionNotifier'] } },
          ]);
        }
      });
  }

  private isNotOnHomepage(event: Event) {
    if (event instanceof NavigationEnd) {
      if (event.urlAfterRedirects === `/${HomeRoute.ROOT}`) {
        return false;
      }
    }

    return true;
  }

  /**
   * Check if the app version meets the passed in version, and if not,
   * redirect to the old version notifier.
   * @param minimumVersion
   * @param router
   */
  async checkAppVersion(minimumVersion: string) {
    const isMinimum = await this.isAppMinimumVersion(minimumVersion);
    if (!isMinimum) {
      setTimeout(() => {
        this.updateToLatest();
      }, 2000);
    }
  }

  /**
   * Check if the app version meets the passed in version.
   * @param minimumVersion
   */
  async isAppMinimumVersion(minimumVersion: string): Promise<boolean> {
    if (!minimumVersion) {
      // if there was no minimum version passed, assume we are at the minimum
      // version.
      return true;
    }
    const version = this.appConfig.getAppVersion();
    return SemVer.gte(version, minimumVersion);
  }
}
